import { useDispatch, useSelector } from "react-redux";
import { ExternalWalletInfo } from "../redux/external/externalTypes";
import { Network, Provider, Types } from "aptos";
import {
  WalletName,
  useWallet,
  NetworkName,
} from "@aptos-labs/wallet-adapter-react";
import { useCallback, useEffect, useState } from "react";
import {
  setExternalAddress,
  setExternalChain,
  setExternalConnected,
  setExternalInnerChainInfo,
  setExternalSigner,
} from "../redux/external/externalAction";
import { ethers } from "ethers";
import Moralis from "moralis";
import { ExternalWalletNftRequest } from "UserResquestType";
import NFTApi from "../apis/NftApi";
import { AptosWalletNftMetadata } from "NftType";
import axios, { AxiosResponse } from "axios";
import metamaskLogo from "../asset/wallets/metamask.svg";
import defaultImage from "../asset/image/default.png";
import { ExternalChainInfo, chainType } from "ChainType";
import { INNER_CHAINS } from "../redux/external/externalReducer";
import { setShowAlertInfo } from "../utils/function/showAlert";
import { useTranslation } from "react-i18next";
import { OpenSeaNft } from "WalletType";

export const useExternalWallet = () => {
  const nftApi = new NFTApi();
  const { t } = useTranslation();

  /**--------지갑 관련 정보 관리하는 전역 상태-------**/
  const dispatch = useDispatch();
  const externalWalletInfo: ExternalWalletInfo = useSelector(
    (state: any) => state.externalWallet
  );
  const innerWallets = useSelector((state: any) => state.wallet);

  const [nfts, setNfts] = useState<ExternalWalletNftRequest[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  /**--------aptos provider-------**/
  const {
    connect,
    account,
    connected,
    disconnect,
    wallet,
    signAndSubmitTransaction,
    network,
  } = useWallet();

  const connectAptosWallet = useCallback(async (walletName: WalletName) => {
    try {
      localStorage.setItem("connected", "aptos");
      await connect(walletName);
      dispatch(setExternalChain("aptos"));
      dispatch(setExternalConnected(connected));
      dispatch(setExternalAddress(account?.address!));
      dispatch(setExternalInnerChainInfo(INNER_CHAINS.aptos.aptos));
    } catch (err) {
      console.log(err);
    }
  }, []);

  const getNftInDetail = async (tokenIds: string[]) => {
    let arr = [];
    try {
      const response = await Moralis.AptosApi.nfts.getNFTsByIds({
        network: "mainnet",
        tokenIds,
      });
      arr = response;
      return arr;
    } catch (e) {
      console.error(e);
    }
  };

  const IPFS_GATEWAY = "https://ipfs.io/ipfs/";

  const fetchIPFSData = async (
    uri: string
  ): Promise<AptosWalletNftMetadata | null> => {
    const parts = uri.split("ipfs://")[1].split("/");
    const cid = parts[0];
    const path = parts.slice(1).join("/");

    const url = `${IPFS_GATEWAY}${cid}/${path}`;

    try {
      const response = await axios.get(url);
      return response.data;
    } catch (error) {
      console.error("Error fetching data from IPFS:", error);
      return null;
    }
  };

  const fetchInfuraData = async (
    uri: string
  ): Promise<AptosWalletNftMetadata | null> => {
    try {
      const response = await axios.get(uri);
      return response.data;
    } catch (error) {
      console.error(error);
      return null;
    }
  };

  const getNftInAptos = async (addr: string) => {
    setIsLoading(true);
    try {
      const response = await Moralis.AptosApi.wallets.getNFTByOwners({
        limit: 5,
        network: "mainnet",
        ownerAddresses: [addr],
      });
      const hash = response.result.map((item) => item.tokenDataIdHash);
      const arr = await getNftInDetail(hash);

      let data: AptosWalletNftMetadata[] = [];
      for (let item of arr!) {
        // const res = await fetchIPFSData(item.metadataUri);
        const res = await fetchInfuraData(item.metadataUri);
        if (res) data.push(res);
      }

      setNfts(
        data.map((item, idx) => {
          return {
            nftName: item.name || "NFT",
            image: item.image || defaultImage,
            description: item.description || "NFT Description",
            chainType: "Aptos",
            nftAddress: arr![idx].payeeAddress.address,
            tokenId: Number(0),
            aptosAdditionalValues: {
              creatorAddress: arr![idx].creatorAddress,
              collectionName: arr![idx].collectionName,
              tokenName: arr![idx].name,
              tokenPropertyVersion: arr![idx].largestPropertyVersion,
              amount: "1",
            },
          };
        })
      );
      setIsLoading(false);
    } catch (e) {
      console.error(e);
      setNfts([]);
      setIsLoading(false);
    }
  };

  /** 트랜잭션 */
  const DEVNET_CLIENT = new Provider(Network.DEVNET);
  const TESTNET_CLIENT = new Provider(Network.TESTNET);
  const MAINNET_CLINET = new Provider(Network.MAINNET);
  const aptosClient = (network?: string) => {
    if (network === NetworkName.Devnet.toLowerCase()) {
      return DEVNET_CLIENT;
    } else if (network === NetworkName.Testnet.toLowerCase()) {
      return TESTNET_CLIENT;
    } else if (network === NetworkName.Mainnet.toLowerCase()) {
      return MAINNET_CLINET;
    } else {
      throw new Error(`Unknown network: ${network}`);
    }
  };

  const onSignAndSubmitTransactionAptos = async (
    selectedItem: ExternalWalletNftRequest
  ) => {
    const payload: Types.TransactionPayload = {
      // from: &signer, creator: address, collection_name: string::String, token_name: string::String, token_property_version: u64, to: address, amount: u64)
      type: "entry_function_payload",
      function: "0x3::token::transfer_with_opt_in",
      type_arguments: [],
      arguments: [
        //from
        //creator
        selectedItem?.aptosAdditionalValues?.creatorAddress.address,
        //collection_name
        selectedItem?.aptosAdditionalValues?.collectionName,
        //token_name
        selectedItem?.aptosAdditionalValues?.tokenName,
        //token_property_version
        selectedItem?.aptosAdditionalValues?.tokenPropertyVersion,
        //to
        innerWallets["Aptos"],
        //amount
        selectedItem?.aptosAdditionalValues?.amount,
      ],
    };
    console.log(payload);
    const response = await signAndSubmitTransaction(payload);
    try {
      await aptosClient(network?.name.toLowerCase()).waitForTransaction(
        response.hash
      );
      return { response, network };
    } catch (err) {
      throw new Error(`${err}`);
    }
  };

  /**--------ethers provider-------**/
  /**
   * 메타마스크 지갑 연결하는 함수
   */
  const connectMetamask = useCallback(async () => {
    setIsLoading(true);
    try {
      if (typeof window.ethereum !== "undefined") {
        dispatch(setExternalInnerChainInfo(INNER_CHAINS.evm.ethereum));
        await switchToDesiredNetwork(INNER_CHAINS.evm.ethereum.key);
        await getMetamaskData();
        dispatch(setExternalChain("evm"));
        dispatch(setExternalConnected(true));
        localStorage.setItem("connected", "evm");
        setIsLoading(false);
      } else {
        window.open("https://metamask.io/");
        dispatch(setExternalConnected(false));
        setIsLoading(false);
      }
    } catch (error) {
      window.open("https://metamask.io/");
      dispatch(setExternalConnected(false));
      console.log(error);
      setIsLoading(false);
    }
  }, []);

  /**
   * 메타마스크 지갑 정보 받아오는 함수
   */
  const getMetamaskData = async () => {
    const _provider = await getProvider();
    const _signer = await getSigner(_provider);
    await getWalletData(_signer);
  };

  const getProvider = async () => {
    const provider = await new ethers.providers.Web3Provider(window.ethereum);
    return provider;
  };

  const getSigner = async (provider: any) => {
    await provider.send("eth_requestAccounts", []);
    const signer = provider.getSigner();
    dispatch(setExternalSigner(signer));
    return signer;
  };

  const getWalletData = async (signer: any) => {
    const result = await Promise.all([signer.getAddress()]);
    dispatch(setExternalAddress(result[0]));
  };

  /** 네트워크 변경하는 함수 (메마) */
  const switchToDesiredNetwork = async (chainId: string) => {
    try {
      await window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: chainId }],
      });
    } catch (switchError) {
      //@ts-ignore
      if (switchError.code === 4902) {
        setShowAlertInfo(t("external:toast:failed"), false);
      } else {
        console.error(switchError);
      }
      throw new Error("not empty network");
    }
  };

  /**
   * Ethereum(Sepolia), Polygon(mainnet) NFT 리스트 받아오는 함수
   */
  const getNftInEvmByMoralis = async (address: string, chainId?: string) => {
    const networkId = chainId || externalWalletInfo.innerChainInfo.key;
    setIsLoading(true);

    try {
      const response = await Moralis.EvmApi.nft.getWalletNFTs({
        chain: networkId,
        format: "decimal",
        mediaItems: false,
        address,
      });
      const newNfts = response.raw.result.map((item) => {
        const metadata = JSON.parse(item.metadata!);
        return {
          nftName: item?.name || "Unknown NFT",
          image:
            !metadata?.image || metadata?.image === ""
              ? defaultImage
              : metadata.image,
          description: metadata?.description || "Unknown NFT",
          chainType: "Ethereum" as "Ethereum",
          nftAddress: item?.token_address || "",
          tokenId: Number(item?.token_id) || 0,
        };
      });

      setNfts(newNfts);
      setIsLoading(false);
    } catch (e) {
      console.error(e);
      setNfts([]);
      setIsLoading(false);
    }
  };

  /**
   * Klaytn(baobab + mainnet) NFT 리스트 받아오는 함수
   */
  const getNftInEvmByOpensea = async (address: string) => {
    setIsLoading(true);
    const chain = "baobab";

    try {
      const response: AxiosResponse<OpenSeaNft> = await axios.get(
        `https://api.opensea.io/api/v2/chain/${chain}/account/${address}/nfts`,
        {
          headers: {
            accept: "application/json",
            "x-api-key": process.env.REACT_APP_OPENSEA_API_KEY,
          },
        }
      );
      const newNfts = response.data.nfts.map((item) => {
        return {
          nftName: item.name || "Unknown NFT",
          image: item.image_url || defaultImage,
          description: item.description || "Unknown NFT",
          chainType: "Ethereum" as "Ethereum",
          nftAddress: item.token_standard || "",
          tokenId: 0,
        };
      });
      setNfts(newNfts);
      setIsLoading(false);
    } catch (e) {
      console.error(e);
      setNfts([]);
      setIsLoading(false);
    }
  };

  const getNftInEvm = async (address: string, chainId?: string) => {
    switch (chainId) {
      case "0x3e9": //baobab
        getNftInEvmByOpensea(address);
        break;
      default:
        await getNftInEvmByMoralis(address, chainId);
        break;
    }
  };

  const transferNFT = async (
    nftAddr: string,
    toAddress: string,
    tokenId: string
  ) => {
    const signer = externalWalletInfo.signer;
    // MetaMask 제공자 초기화
    const NFT_ABI = [
      `function safeTransferFrom(address from, address to, uint256 tokenId);`,
    ];
    const contract = new ethers.Contract(nftAddr, NFT_ABI, signer!);

    try {
      const fromAddress = await signer!.getAddress();
      const gas = await contract.estimateGas.safeTransferFrom(
        fromAddress,
        toAddress,
        tokenId
      );
      const tx = await contract.safeTransferFrom(
        fromAddress,
        toAddress,
        tokenId,
        { gasLimit: gas }
      );
      await tx.wait();
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleNftToYours = async (nft: ExternalWalletNftRequest) => {
    try {
      const res = await nftApi.wrappingExternalNft(
        nft,
        externalWalletInfo.innerChainInfo.full
      );
      await transferNFT(nft.nftAddress, res.address, `${nft.tokenId}`);
      await nftApi.transferingExternalNft(
        nft,
        nft.nftAddress,
        res.isExternal,
        externalWalletInfo.innerChainInfo.full
      );
      await nftApi.getUserOwnNftIdList();
      console.log("전송 완료");
    } catch (err) {
      throw new Error(`${err}`);
    }
  };

  /** 지갑 연결하는 함수 */
  const connectWallet = (chain: "evm" | "aptos", wallet?: WalletName) => {
    switch (chain) {
      case "evm":
        connectMetamask();
        break;
      case "aptos":
        connectAptosWallet(wallet!);
        break;
      default:
        break;
    }
  };

  const getNftList = (chainId?: string) => {
    switch (externalWalletInfo.chain) {
      case "evm":
        getNftInEvm(externalWalletInfo.address, chainId);
        break;
      case "aptos":
        getNftInAptos(externalWalletInfo.address);
        break;
      default:
        break;
    }
  };

  /** 외부 지갑 연동 해제하는 함수 */
  const disconnectWallet = (chain: "evm" | "aptos", wallet?: WalletName) => {
    localStorage.removeItem("connected");
    switch (chain) {
      case "evm":
        dispatch(setExternalAddress(""));
        dispatch(setExternalSigner(null));
        dispatch(setExternalConnected(false));
        break;
      case "aptos":
        disconnect();
        dispatch(setExternalAddress(""));
        dispatch(setExternalConnected(false));
        break;
      default:
        break;
    }
  };

  /** 지갑 정보 불러오는 함수 */
  /** 현재 연결된 체인 불러오는 함수 */
  /** 지갑 주소를 통해 NFT 리스트업하는 함수 & 새로 불러오기 */

  /** 메타마스크 자동 연결 _ 앱토스 지갑들은 자동으로 설정됨 */
  const autoConnectWithExternalWallet = () => {
    const isConnected = localStorage.getItem("connected");
    if (isConnected) {
      switch (isConnected) {
        case "evm":
          connectMetamask();
          break;
        case "aptos":
          dispatch(setExternalChain("aptos"));
          dispatch(setExternalConnected(connected));
          dispatch(setExternalAddress(account?.address!));
      }
    }
  };

  /** 지갑 대표 이미지 가져오기 */
  const getWalletImage = (): string => {
    const isConnected = localStorage.getItem("connected");
    if (isConnected) {
      switch (isConnected) {
        case "evm":
          return metamaskLogo;
        case "aptos":
          if (wallet?.icon) return wallet.icon;
          return "";
      }
    }
    return "";
  };

  /** 외부 지갑 -> evm 혹은 aptos 내부 체인 선택 */
  const handleSelectInnerChain = (chain: ExternalChainInfo) => {
    dispatch(setExternalInnerChainInfo(chain));
  };

  /** 외부에서 가져올 수 없는 체인여부 확인 */
  const isDisabled = (name: chainType) => {
    switch (name) {
      case "Ethereum":
      case "Polygon":
        return false;
      default:
        return true;
    }
  };

  useEffect(() => {
    const isConnected = localStorage.getItem("connected");
    if (isConnected === "aptos" && connected && account && account.address) {
      dispatch(setExternalChain("aptos"));
      dispatch(setExternalConnected(connected));
      dispatch(setExternalAddress(account?.address!));
      dispatch(setExternalInnerChainInfo(INNER_CHAINS.aptos.aptos));
    }
  }, [connected, account]);

  return {
    connectWallet,
    getNftList,
    nfts,
    externalWalletInfo,
    isLoading,
    autoConnectWithExternalWallet,
    disconnectWallet,
    getWalletImage,
    handleNftToYours,
    transferNFT,
    onSignAndSubmitTransactionAptos,
    handleSelectInnerChain,
    switchToDesiredNetwork,
    isDisabled,
  };
};
