import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { setShowAlertInfo } from "../utils/function/showAlert";
import NFTApi from "../apis/NftApi";
import { NftInfo } from "NftType";
import { Profile } from "UserType";
import { useTranslation } from "react-i18next";

type useNftProp = {
  nftId: number;
};

export const useNft = ({ nftId }: useNftProp) => {
  const nftApi = new NFTApi();
  const userOwnNftIdList = useSelector((state: any) => state.nft.ownNftIdList);
  const userCreateNftIdList = useSelector(
    (state: any) => state.nft.createNftIdList
  );
  const userTranferNftIdList = useSelector(
    (state: any) => state.nft.transferNftIdList
  );
  const [nftInfo, setNftInfo] = useState<NftInfo>({
    id: 0,
    nftName: "",
    image: "",
    numberOfOwners: 0,
    description: "",
    numberOfRewards: 0,
    rewards: [],
  });
  const [nftOwnerList, setNftOwnerList] = useState<Profile[]>([]);
  const [userOwnThisNft, setUserOwnThisNft] = useState(false);
  const [userCreateThisNft, setUserCreateThisNft] = useState(false);
  const [userTransferThisNft, setUserTransferThisNft] = useState(false);
  const [userPhotoPending, setUserPhotoPending] = useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    // 유저가 해당 nft를 가지고 있는 지에 대한 정보 업데이트
    setUserOwnThisNft(userOwnNftIdList.includes(nftId));
  }, [nftId, userOwnNftIdList]);

  useEffect(() => {
    // 유저가 해당 nft를 만들었는 지에 대한 정보 업데이트
    setUserCreateThisNft(userCreateNftIdList.includes(nftId));
  }, [nftId, userCreateNftIdList]);

  useEffect(() => {
    // 유저가 해당 nft를 transfer 했는 지에 대한 정보 업데이트
    setUserTransferThisNft(userTranferNftIdList.includes(nftId));
  }, [nftId, userTranferNftIdList]);

  useEffect(() => {
    const userPhotoVerificationPending = async () => {
      const res = await nftApi.nftPhotoVerificationPending(nftId);
      setUserPhotoPending(res);
    };
    userPhotoVerificationPending();
  }, []);

  useEffect(() => {
    const getNftInfo = async () => {
      try {
        const data = await nftApi.getNftDetail(nftId);
        setNftInfo(data);

        const owners = await nftApi.getNftOwnerList(nftId);
        setNftOwnerList(owners);
      } catch (err) {
        setShowAlertInfo(t("landing:popup:failRead"), false);
        console.log(err);
      }
    };
    getNftInfo();
  }, [nftId]);

  return {
    nftInfo,
    nftOwnerList,
    userOwnThisNft,
    userCreateThisNft,
    userTransferThisNft,
    userPhotoPending,
  };
};
