import { get, patch, post } from "./AxiosCreate";
import store from "../redux/store";
import {
  setAuth,
  setEmail,
  setId,
  setName,
  setPhoneNumber,
  setProfileImage,
  setSecret,
  setLanguage,
} from "../redux/userData/userDataAction";
import {
  setAptosWallet,
  setAuroraWallet,
  setEthereumWallet,
  setKlaytnWallet,
  setPolygonWallet,
  setSolanaWallet,
} from "../redux/wallet/walletAction";
import _ from "lodash";
import {
  clearRedirectUrl,
  getRedirectUrl,
} from "../utils/function/redirectUrl";
import { socialType } from "SocialType";
import { User } from "UserType";
import {
  CheckSocialUserType,
  GetTokenResponse,
  GetWalletResponse,
  SendPhoneVerificationResponse,
  SignupResponse,
  UserCompletedQuest,
  UserProfileResponse,
} from "UserResponseType";
import { SignupRequest } from "UserResquestType";

class UserApi {
  loginHandler = async (social: socialType, socialToken: string) => {
    const { signUp, accessToken, refreshToken } = await this.checkSocialUser(
      social,
      socialToken
    );
    if (!signUp) {
      window.location.href = `/signup?${social}=${socialToken}`;
    } else {
      this.setToken(accessToken, refreshToken);
      this.getUserInfo();
      let redirectUrl = getRedirectUrl();
      window.location.href = redirectUrl ? redirectUrl : `/mypage`;
      clearRedirectUrl();
    }
  };

  /**
   * get access token from refresh token
   */
  getToken = async () => {
    const refreshToken = localStorage.getItem("refreshToken");
    const { accessToken } = await get<GetTokenResponse>("/auth/token", {
      headers: {
        refreshToken: refreshToken,
      },
    });
    localStorage.setItem("accessToken", accessToken);
  };

  /**
   * set access token and refresh token to localStorage
   *
   * @param {string} accessToken - access token
   * @param {string} refreshToken - refresh token
   */
  setToken = (accessToken: string, refreshToken: string) => {
    localStorage.setItem("accessToken", accessToken);
    localStorage.setItem("refreshToken", refreshToken);
  };

  /**
   * get user info and set user info to redux
   */
  getSetUserInfo = async () => {
    this.setUserInfo(await this.getUserInfo());
  };

  getUserInfo = async () => {
    const res = await get<User>("/user/profile");
    return res;
  };

  /**
   * set user info to redux
   * @param {User} user - user info
   */
  setUserInfo = (user: User) => {
    store.dispatch(setName(user.name));
    store.dispatch(setProfileImage(user.image));
    store.dispatch(setId(user.id));
    store.dispatch(setEmail(user.email));
    store.dispatch(setPhoneNumber(user.phoneNumber));
    store.dispatch(setSecret(user.secret));
    store.dispatch(setAuth(true));
    store.dispatch(setLanguage(user.language));
  };

  /**
   * signup, set token to localStorage, and set user info to redux
   *
   * @param {object} newUserData - new user data
   */
  signup = async (newUserData: SignupRequest) => {
    const { accessToken, refreshToken } = await post<SignupResponse>(
      "auth/signup",
      newUserData
    );
    this.setToken(accessToken, refreshToken);
    await this.getSetUserInfo();
    await this.getWallets();
  };

  /**
   * whether user is signed up or not
   *
   * @param {socialType} social - social type
   * @param {string} socialToken - social token
   * @returns {CheckSocialUserType} whether user is signed up or not
   */
  checkSocialUser = async (
    social: socialType,
    socialToken: string
  ): Promise<CheckSocialUserType> => {
    const data = await post<CheckSocialUserType>(`auth`, {
      token: socialToken,
      social: social,
    });
    return data;
  };

  /**
   * send verification number to phone number
   *
   * @param {string} phoneNumber - phone number (only numbers)
   */
  sendPhoneVerificationNumber = async (
    phoneNumber: string,
    nationalCode: string
  ) => {
    const data = await post<SendPhoneVerificationResponse>(`sms/send`, {
      countryCode: nationalCode,
      phoneNumber: phoneNumber,
    });
    return data;
  };

  /**
   * send verification number to email
   *
   * @param {string} email - email
   */
  sendEmailVerificationNumber = async (email: string) => {
    await post(`user/email/send`, {
      email: email,
    });
  };

  /**
   * check verification code matching
   * @param {string} authText - phone number or email
   * @param {string} authCode - verification code
   * @returns whether verification code is correct or not
   */
  checkVerificationNumber = async (
    authText: string,
    authCode: string
  ): Promise<boolean> => {
    const data = await post<boolean>(`auth/verification`, {
      authText: authText,
      authCode: authCode,
    });
    return data;
  };

  // edit user info
  editUserName = async (name: string) => {
    await patch(`user/profile/nickname`, {
      nickname: name,
    });
    store.dispatch(setName(name));
  };

  editUserPhoneNumber = async (phoneNumber: string) => {
    await patch(`user/profile/phone`, {
      phoneNumber: phoneNumber,
    });
    store.dispatch(setPhoneNumber(phoneNumber));
  };

  editUserEmail = async (email: string) => {
    await patch(`user/profile/email`, {
      email: email,
    });
    store.dispatch(setEmail(email));
  };

  editUserProfileImage = async (profileImgFormdata: any) => {
    const profileImagePath = await patch<string>(
      `user/profile/photo`,
      profileImgFormdata
    );
    store.dispatch(setProfileImage(profileImagePath));
  };

  editSecret = async (secret: string) => {
    await patch(`user/secret`, {
      secret: secret,
    });
    store.dispatch(setSecret(secret));
  };

  getWallets = async () => {
    const wallets = await get<GetWalletResponse>(`user/wallet`);
    store.dispatch(
      setEthereumWallet(
        wallets.find((wallet: any) => wallet.chainType === "Ethereum")
          ?.walletAddress as string
      )
    );
    store.dispatch(
      setPolygonWallet(
        wallets.find((wallet: any) => wallet.chainType === "Polygon")
          ?.walletAddress as string
      )
    );
    store.dispatch(
      setKlaytnWallet(
        wallets.find((wallet: any) => wallet.chainType === "Klaytn")
          ?.walletAddress as string
      )
    );
    store.dispatch(
      setAuroraWallet(
        wallets.find((wallet: any) => wallet.chainType === "Aurora")
          ?.walletAddress as string
      )
    );
    store.dispatch(
      setSolanaWallet(
        wallets.find((wallet: any) => wallet.chainType === "Solana")
          ?.walletAddress as string
      )
    );
    store.dispatch(
      setAptosWallet(
        wallets.find((wallet: any) => wallet.chainType === "Aptos")
          ?.walletAddress as string
      )
    );
    return wallets;
  };

  /**
   * get user completed wallet quest or not
   * @returns {boolean} whether user completed wallet quest or not
   */
  getUserCompletedQuest = async (): Promise<boolean> => {
    const { isQuest } = await get<UserCompletedQuest>(`user/quest`);
    return isQuest;
  };

  /**
   * complete wallet quest
   */
  completeQuest = async () => {
    await patch(`user/quest`);
  };

  /**
   * get whether wallet address is from yours wallet or not
   *
   * @param {string} walletAddress - wallet address
   * @returns {boolean} whether wallet address is from yours wallet or not
   */
  isYoursWalletAddress = async (walletAddress: string): Promise<boolean> => {
    const data = await get<boolean>(`user/yours?address=${walletAddress}`);
    console.log("Yours Wallet", data);
    return data;
  };

  changeLanguage = async (lang: string): Promise<boolean> => {
    const data = await patch<boolean>(`user/profile/language`, {
      language: lang,
    });
    return data;
  };
}
export default UserApi;
