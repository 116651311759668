import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { usePassCode } from "../../hook/usePassCodeInput";
import { decryptMnemonic, encryptMnemonic } from "../../utils/function/crypto";
import { setShowAlertInfo } from "../../utils/function/showAlert";
import UserApi from "../../apis/UserApi";
import MiniHeader from "../../components/miniHeader/MiniHeader";
import PassCodeConfirm from "../../components/passCode/PassCodeConfirm";
import PassCodeInput from "../../components/passCode/PassCodeInput";
import EnterOldPassCode from "./EnterOldPassCode";
import "./index.scss";
import { useTranslation } from "react-i18next";

function WalletPrivacyChangePassCode() {
  const { t } = useTranslation();

  const navigation = useNavigate();
  const userApi = new UserApi();
  const secret = useSelector((state: any) => state.userData.secret);
  const [pageMode, setPageMode] = useState("ENTER_OLD_PASSCODE");
  const [mnemonic, setMnemonic] = useState("");

  const { passCode, setPassCode, isValid } = usePassCode();

  const confirmOldPassCode = (passCode: string) => {
    try {
      const _mnemonic = decryptMnemonic(secret, passCode);
      setMnemonic(_mnemonic);
      return true;
    } catch (err) {
      return false;
    }
  };

  const changePassCode = async () => {
    const newSecret = encryptMnemonic(mnemonic, passCode);
    await userApi.editSecret(newSecret);
    setShowAlertInfo(t("wallet:yours:toasts:passcode"), true);
    navigation("/wallet");
  };

  const pageRenderer = () => {
    switch (pageMode) {
      case "ENTER_OLD_PASSCODE":
        return (
          <EnterOldPassCode
            confirmOldPassCode={confirmOldPassCode}
            confirmAction={() => setPageMode("ENTER_NEW_PASSCODE")}
          />
        );
      case "ENTER_NEW_PASSCODE":
        return (
          <PassCodeInput
            title={t("wallet:yours:privacy:passcode:title")}
            description={t("wallet:yours:privacy:passcode:reset:desc")}
            passCode={passCode}
            setPassCode={setPassCode}
            isValid={isValid}
            buttonText={t("wallet:yours:privacy:passcode:buttons:next")}
            buttonAction={() => setPageMode("CONFIRM_NEW_PASSCODE")}
          />
        );
      case "CONFIRM_NEW_PASSCODE":
        return (
          <PassCodeConfirm
            title={t("wallet:yours:privacy:passcode:confirm:title")}
            description={t("wallet:yours:privacy:passcode:confirm:subtitle")}
            passCode={passCode}
            confirmText={t("wallet:yours:privacy:passcode:buttons:passcode")}
            confirmAction={changePassCode}
          />
        );
      default:
    }
  };

  return (
    <div className="wallet-privacy-change-passcode">
      <MiniHeader title={t("wallet:yours:privacy:passcode:header")} />
      {pageRenderer()}
    </div>
  );
}
export default WalletPrivacyChangePassCode;
