import { useTranslation } from "react-i18next";
import "./historyItem.scss";
import { useLanguage } from "../../../hook/useLanguage";

export const Empty = () => {
  const { t } = useTranslation();
  const { classLang } = useLanguage();

  return (
    <div className="history-empty-wrapper">
      <h3 className={classLang}>{t("yrp:history:empty")}</h3>
    </div>
  );
};

export default Empty;
