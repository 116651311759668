import { ChargeYrpInterface, YrpHistoryInterface } from "YrpResponseType";
import { get, post } from "./AxiosCreate";

class YrpApi {
  postChargeYrp = async (config: ChargeYrpInterface): Promise<any> => {
    const data = await post<any>("/user/yrp/charge", config);
    return data;
  };

  getYrpHistory = async (): Promise<YrpHistoryInterface[]> => {
    const data = await get<YrpHistoryInterface[]>("/user/yrp/ledger/detail");
    return data;
  };

  getTotalAmount = async (): Promise<string> => {
    const data = await get<string>("/user/yrp/ledger");
    return data;
  };
}
export default YrpApi;
