import yrp from "../../../asset/image/chain/YRP.png";
import { useYrp } from "../../../hook/useYrp";
import { ableChargeChain } from "../../../redux/yrp/yrpTypes";
import { ReactComponent as Caret } from "../../../asset/svg/caret-down.svg";
import { useTranslation } from "react-i18next";
import { useRef } from "react";
import { useTransition } from "../../../hook/useTransition";
import { useOutsideClose } from "../../../hook/useOutsideClose";
import "./input.scss";

export const YrpOption = () => {
  return (
    <div className="charge-input-option">
      <img src={yrp} alt="yrp" />
      <h3 className="eng">YRP</h3>
    </div>
  );
};

export const ChainOption = () => {
  const { selectedChain } = useYrp();
  const { isOpen, handleOpen, rotate, startTransition, transition } =
    useTransition(300);

  return (
    <>
      <div
        onClick={() => (isOpen ? startTransition() : handleOpen())}
        className="charge-input-option"
      >
        <img
          src={
            ableChargeChain[selectedChain as keyof typeof ableChargeChain].logo
          }
          alt="chain"
        />
        <h3 style={{ marginRight: 3 }} className="eng">
          {ableChargeChain[selectedChain as keyof typeof ableChargeChain].name}
        </h3>
        <Caret
          style={{ transform: rotate ? `rotate(-180deg)` : `rotate(0deg)` }}
        />
      </div>
      {isOpen && (
        <ChainSelector
          startTransition={startTransition}
          transition={transition}
        />
      )}
    </>
  );
};

interface Props {
  startTransition: () => void;
  transition: boolean;
}

const CHAINS = ["USDC_T", "USDC", "APT", "USDT"];

export const ChainSelector = ({ startTransition, transition }: Props) => {
  const { t } = useTranslation();
  const ref = useRef<HTMLElement>(null);
  const { selectChain } = useYrp();

  useOutsideClose(ref, startTransition);

  const setChain = (chain: string) => {
    selectChain(chain);
    startTransition();
  };

  const Line = ({ chain }: { chain: keyof typeof ableChargeChain }) => {
    return (
      <div
        className="line"
        onClick={() => setChain(ableChargeChain[chain].ticker)}
      >
        <img src={ableChargeChain[chain].logo} alt="chain" />
        <div>
          <h3 className="eng name">{ableChargeChain[chain].name}</h3>
          <h4 className="eng full">{ableChargeChain[chain].full}</h4>
        </div>
      </div>
    );
  };

  return (
    <section
      ref={ref}
      className={`charge-chain-modal ${transition && "close"}`}
    >
      <div className="caption">
        <h4>{t("yrp:charge:select")}</h4>
      </div>
      {CHAINS.map((item: any) => (
        <Line chain={item} />
      ))}
    </section>
  );
};
