import { useTranslation } from "react-i18next";
import { Accordian, PayMethod, PayResult } from "../../../components/yrp";
import "./charge.scss";
import { useYrp } from "../../../hook/useYrp";

const Confirm = () => {
  const { t } = useTranslation();
  const { inputAmount } = useYrp();

  return (
    <div className="yrp-confirm-info-wrapper">
      <div className="yrp-confirm-info-box">
        <h2>{t("yrp:charge:accordian:title")}</h2>
        <h1 className="eng">{inputAmount.toLocaleString()} YRP</h1>
      </div>
      <Accordian title={t("yrp:charge:accordian:chain")}>
        <PayMethod />
      </Accordian>
      <Accordian title={t("yrp:charge:accordian:pay")}>
        <PayResult />
      </Accordian>
    </div>
  );
};

export default Confirm;
