import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

export const useLanguage = () => {
  const { i18n } = useTranslation();
  const [classLang, setClassLang] = useState("");

  const settingLang = () => {
    if (i18n.language === "en") setClassLang("eng");
    else setClassLang("");
  };

  useEffect(() => {
    settingLang();
  }, [i18n.language]);

  return { classLang };
};
