import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import NFTApi from "../../../apis/NftApi";
import MiniHeader from "../../../components/miniHeader/MiniHeader";
import { Popup } from "../../../components/popup";
import { setShowAlertInfo } from "../../../utils/function/showAlert";
import RewardInfoForm from "./RewardInfoForm";
import { useTranslation } from "react-i18next";
import { useReward } from "../../../hook/useReward";

//TODO 혜택 관리
function AddReward() {
  const { t } = useTranslation();

  const navigation = useNavigate();
  const nftApi = new NFTApi();
  const { nftId } = useParams();
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const { rewardInfoInput, handleRewardInfo, selectCategory } = useReward();

  const addReward = async () => {
    try {
      await nftApi.createNftReward(
        Number(nftId),
        rewardInfoInput.name,
        rewardInfoInput.desc,
        rewardInfoInput.category,
        rewardInfoInput.link
      );
      navigation(-1);
      setShowAlertInfo(t("nft:benefits:add:toast"), true);
    } catch (err) {
      setShowAlertInfo(t("nft:benefits:add:fail"), true);
    }
  };

  return (
    <>
      {showConfirmModal && (
        <Popup
          title={t("nft:benefits:add:modal")}
          closeModal={() => {
            setShowConfirmModal(false);
          }}
          approve={() => {
            addReward();
          }}
          deny={() => {
            setShowConfirmModal(false);
          }}
        />
      )}
      <MiniHeader title={t("nft:benefits:add:header")} />
      <RewardInfoForm
        name={rewardInfoInput.name}
        description={rewardInfoInput.desc}
        link={rewardInfoInput.link || ""}
        category={rewardInfoInput.category}
        handleInfoInput={handleRewardInfo}
        selectCategory={selectCategory}
        submitAction={() => {
          setShowConfirmModal(true);
        }}
        buttonText={t("nft:benefits:buttons:add")}
      />
    </>
  );
}
export default AddReward;
