import { ChainElem, chainType } from "ChainType";
import { useEffect, useState } from "react";
import { useCheckAddressValidation } from "../../hook/useCheckAddressValidation";
import ChainTextIcon from "../../components/chain/ChainTextIcon";
import Button from "../../components/button/Button";
import chainList from "../../utils/data/chainList";
import { useTranslation } from "react-i18next";
import { useLanguage } from "../../hook/useLanguage";

interface TrasferInputType {
  nftImage: string;
  nftName: string;
  nftChainType: chainType;
  transferNft: Function;
}

function TransferInput({
  nftImage,
  nftName,
  nftChainType,
  transferNft,
}: TrasferInputType) {
  const { t, i18n } = useTranslation();
  const { classLang } = useLanguage();

  const { address, setAddress, isValid, isYoursWallet } =
    useCheckAddressValidation(nftChainType as chainType);
  const [currChainText, setCurrChainText] = useState<ChainElem>();

  const subtitle = () => {
    switch (i18n.language) {
      case "ko":
        return `${currChainText?.wallet.nameKor} 지갑을 가지고 있을 경우`;
      case "en":
        return `If you have ${currChainText?.wallet.nameEng} wallet,`;
    }
  };

  const placeholder = () => {
    switch (i18n.language) {
      case "ko":
        return currChainText?.wallet.placeholder;
      case "en":
        return currChainText?.wallet.placeholderEng;
    }
  };

  useEffect(() => {
    setCurrChainText(
      chainList.find((el) => el.chainType === nftChainType) as ChainElem
    );
  }, [nftChainType]);

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        transferNft(address);
      }}
    >
      <div className="title-wrapper">
        <h2 className={`title ${classLang}`}>{t("nft:transfer:title")}</h2>
        {/* <h5 className={`subtitle ${classLang}`}>
          <span className="white">{subtitle()}</span>
          <br />
          {t("nft:transfer:caption")}
        </h5> */}
      </div>

      <img className="nft-image" src={nftImage} />
      <div className="nft-chain-info">
        <ChainTextIcon chainType={nftChainType} />
      </div>
      <div className="input-box-wrapper">
        <label className={`input-label input-label-${classLang}`}>
          {currChainText?.wallet.nameEng} {t("nft:transfer:wallet")}
        </label>
        <input
          className="input-textbox"
          value={address}
          placeholder={placeholder()}
          onChange={(e) => {
            setAddress(e.currentTarget.value);
          }}
        />
        {!!address.length &&
          (isYoursWallet ? (
            <div className="input-status" id="warning">
              {t("nft:transfer:warning:yours")}
            </div>
          ) : (
            !isValid && (
              <div className="input-status" id="warning">
                {t("nft:transfer:warning:invalid")}
              </div>
            )
          ))}
      </div>

      <div className={`transfer-preview transfer-preview-${classLang}`}>
        <b>{nftName}</b>{" "}
        <span className="transfer-preview-caption">
          {t("nft:transfer:confirm:content1")}
        </span>
        <br />
        {address.length ? (
          <span className="transfer-wallet-address">{address}</span>
        ) : (
          <span className="transfer-wallet-address">...</span>
        )}
        <br />
        <span className="transfer-wallet-address">
          {t("nft:transfer:confirm:content2")}
        </span>
      </div>
      <div className={`transfer-warning transfer-warning-${classLang}`}>
        {t("nft:transfer:confirm:warning")}
      </div>

      <Button
        theme="purple"
        sticky={true}
        disabled={!isValid}
        text={t("nft:transfer:buttons:send")}
        onClick={() => {
          transferNft(address);
        }}
      />
    </form>
  );
}
export default TransferInput;
