import { useEffect, useState } from "react";
import { useCreateIntegratedNft } from "../../../hook/useCreateIntegratedNft";
import chainList from "../../../utils/data/chainList";
import ActionBox from "../../../components/contentsBox/ContentsBox";
import MiniHeader from "../../../components/miniHeader/MiniHeader";
import Select from "../../../components/select/Select";
import AvailableNft from "../../../components/integratedNft/AvailableNft";
import "./index.scss";
import Button from "../../../components/button/Button";
import { useTranslation } from "react-i18next";
import { useLanguage } from "../../../hook/useLanguage";

type inputFormProps = {
  createIntegratedNft: Function;
};

function InputForm({ createIntegratedNft }: inputFormProps) {
  const { t } = useTranslation();
  const { classLang } = useLanguage();

  const [chain, setChain] = useState<any>(null);
  const { availableNftList, userHasNftChinList } = useCreateIntegratedNft({
    chain: chain,
  });
  const [selectedNftIdList, setSelectedNftIdList] = useState<number[]>([]);

  useEffect(() => {
    // chain이 변경될 때마다 선택한 nft 초기화
    setSelectedNftIdList([]);
  }, [chain]);

  return (
    <div>
      <MiniHeader title="Integrated NFT" />
      <div className="create-integrated-nft-input-form">
        <ActionBox
          title={t("intergrated:create:menu:1")}
          disabled={false}
          zIndex={1}
        >
          <Select
            placeholder="Select Chain"
            value={chain}
            setValue={setChain}
            optionList={chainList}
            disabledOptionList={userHasNftChinList}
            optionNameKey="chainType"
            optionValueKey="chainType"
            optionIconKey="logo"
          />
        </ActionBox>
        <ActionBox
          title={t("intergrated:create:menu:2")}
          disabled={!chain}
          initialOpened={chain}
        >
          <>
            <h5 className={`nft-description ${classLang}`}>
              {t("intergrated:create:caption")}
            </h5>
            <div className="nft-list-wrapper">
              {availableNftList?.map((nftElem, idx) => (
                <AvailableNft
                  key={idx}
                  nftInfo={nftElem}
                  checked={selectedNftIdList.includes(nftElem.id)}
                  checkAction={() =>
                    setSelectedNftIdList([...selectedNftIdList, nftElem.id])
                  }
                  uncheckAction={() =>
                    setSelectedNftIdList(
                      selectedNftIdList.filter((id) => id !== nftElem.id)
                    )
                  }
                />
              ))}
            </div>
            <Button
              theme="purple"
              disabled={!(selectedNftIdList.length > 1)}
              onClick={() => {
                createIntegratedNft(chain, selectedNftIdList);
              }}
              text={
                selectedNftIdList.length < 2
                  ? t("intergrated:create:button")
                  : "Intergrate"
              }
            />
          </>
        </ActionBox>
        <ActionBox
          title={t("intergrated:create:menu:3")}
          disabled={true}
          initialOpened={false}
        />
        <ActionBox
          title={t("intergrated:create:menu:4")}
          disabled={true}
          initialOpened={false}
        />
      </div>
    </div>
  );
}
export default InputForm;
