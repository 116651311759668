import { Icon } from "@iconify/react";
import Button from "../../../components/button/Button";
import { useTranslation } from "react-i18next";
import { useLanguage } from "../../../hook/useLanguage";
import "../external.scss";

interface Props {
  nftName: string;
  handleNextStage: () => void;
}

const ResultSection = ({ nftName, handleNextStage }: Props) => {
  const { t } = useTranslation();
  const { classLang } = useLanguage();

  const name =
    classLang === "ko" ? (
      <h2>{nftName}</h2>
    ) : (
      <h3 className={`transfer-ttile ${classLang}`}>
        The <h2 className={classLang}>{nftName}</h2> NFT
      </h3>
    );

  return (
    <div className="transfer-status-container">
      <div className="transfer-confirm-top-wrapper">
        <Icon icon="line-md:chevron-down-circle" color="#ed5f8a" width="60" />
        <div className="transfer-status-wrapper">
          {name}
          <h3 className={classLang} style={{ fontWeight: 400 }}>
            {t("external:transfer:complete:confirm")}
          </h3>
        </div>
      </div>
      <div className="transfer-confirm-wrapper">
        <Button
          theme="purple"
          text={t("nft:transfer:buttons:confirm")}
          sticky={true}
          onClick={handleNextStage}
        />
        <h5 className={`transfer-confirm-text ${classLang}`}>
          {t("nft:transfer:complete:fee")}
        </h5>
      </div>
    </div>
  );
};

export default ResultSection;
