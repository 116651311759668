import { useEffect, useLayoutEffect } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { Buffer } from "buffer";
import Moralis from "moralis";
import Footer from "./components/footer";
import Navbar from "./components/navbar";
import Oauth from "./pages/oauth/Oauth";
import Landing from "./pages/landing";
import Signup from "./pages/signup";
import Mypage from "./pages/mypage";
import { checkAuth } from "./utils/function/checkAuth";
import NftDetail from "./pages/nftDetail";
import PrivateOutlet from "./router/PrivateOutlet";
import CreateNft from "./pages/nftCreate";
import ScrollToTop from "./utils/ScrollToTop";
import { useSelector } from "react-redux";
import NotFound from "./pages/error/NotFound";
import GetNft from "./pages/getNft";
import GetNftSuccess from "./pages/getNftSuccess";
import NftEmailVerify from "./pages/nftEmailVerify";
import NftAdmin from "./pages/nftAdmin";
import NftTransfer from "./pages/nftTransfer";
import NftSetting from "./pages/nftSetting";
import NftRewardSetting from "./pages/nftSetting/reward";
import AddReward from "./pages/nftSetting/reward/AddReward";
import RewardDetail from "./pages/nftSetting/reward/RewardDetail";
import RewardEdit from "./pages/nftSetting/reward/RewardEdit";
import NftOwnerOutlet from "./router/NftOwnerOutlet";
import EditProfile from "./pages/editProfile";
import CreateIntegratedNft from "./pages/createIntegratedNft";
import Success from "./pages/createIntegratedNft/success";
import IntegratedNftDetail from "./pages/integratedNftDetail";
import IntegratedNftAdmin from "./pages/integratedNftAdmin";
import WalletTutorial from "./pages/walletTutorial";
import WalletTutorialSecret from "./pages/walletTutorialSecret";
import NftDeploy from "./pages/nftSetting/deploy";
import NftDeployReward from "./pages/nftSetting/deployReward";
import YoursWallet from "./pages/wallet";
import WalletPrivacy from "./pages/walletPrivacy";
import WalletPrivacyCheckMnemonic from "./pages/walletPrivacyCheckMnemonic";
import WalletPrivacyChangePassCode from "./pages/walletPrivacyChangePasscode";
import WalletPrivacyResetPassCode from "./pages/walletPrivacyResetPassCode";
import WalletPrivateKey from "./pages/walletPrivateKey";
import AlertHandler from "./components/alert/AlertHandler";
import WelcomeDeploy from "./pages/nftSetting/welcome";
import { YrpHistory, YrpCharge } from "./pages/yrp";
import ExternalNft from "./pages/externalNft";
import { useExternalWallet } from "./hook/useExternalWallet";
import i18n from "./locales";
import GAProvider from "./components/ga/GAProvider";

function App() {
  const auth = useSelector((state: any) => state.userData.auth);
  const profileImage = useSelector((state: any) => state.userData.profileImage);
  const { autoConnectWithExternalWallet } = useExternalWallet();

  useLayoutEffect(() => {
    Buffer.from("anything", "base64");
    window.Buffer = window.Buffer || require("buffer").Buffer;

    console.log(
      `%c
> make your own asset 👾 \n%c
    ██╗   ██╗ ██████╗ ██╗   ██╗██████╗ ███████╗   %c
    ╚██╗ ██╔╝██╔═══██╗██║   ██║██╔══██╗██╔════╝   %c
     ╚████╔╝ ██║   ██║██║   ██║██████╔╝███████╗   %c
      ╚██╔╝  ██║   ██║██║   ██║██╔══██╗╚════██║   %c
       ██║   ╚██████╔╝╚██████╔╝██║  ██║███████║██╗%c
       ╚═╝    ╚═════╝  ╚═════╝ ╚═╝  ╚═╝╚══════╝╚═╝
                                                  `,
      `color: white; font-size: 10px; padding:2px 0; font-family: monospace`,
      `color: #5c36ee;`,
      `color: #0078ff;`,
      `color: #00a0ff;`,
      `color: #00bfff;`,
      `color: #00d8e1;`,
      `color: #36eec5;`
    );
    if (process.env.NODE_ENV === "production") {
      console = window.console || {};
      console.log = function no_console() {};
      console.warn = function no_console() {};
      console.error = function () {};
    }
    checkAuth();

    const start = async () => {
      await Moralis.start({
        apiKey: process.env.REACT_APP_MORALIS_APTOS_API_KEY,
      });
    };

    start();

    autoConnectWithExternalWallet();
  }, []);

  useEffect(() => {
    const handleLanguageChange = (lng: string) => {
      document.documentElement.lang = lng;
    };
    i18n.on("languageChanged", handleLanguageChange);
    document.documentElement.lang = i18n.language;

    return () => {
      i18n.off("languageChanged", handleLanguageChange);
    };
  }, []);

  return (
    <div className="App" id="app">
      <BrowserRouter>
        <GAProvider />
        <Navbar auth={auth} profileImage={profileImage} />
        <AlertHandler />
        <ScrollToTop />
        <div className="app-content" id="app-content">
          <Routes>
            {/* Public Outlet */}
            <Route path="/landing" element={<Landing />} />
            <Route path="/oauth" element={<Oauth />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/nft/:nftId/detail" element={<NftDetail />} />
            <Route path="/nft/email" element={<NftEmailVerify />} />
            <Route
              path="/integrated-nft/:nftId"
              element={<IntegratedNftDetail />}
            />

            {/* Private Outlet */}
            <Route element={<PrivateOutlet />}>
              <Route path="/mypage" element={<Mypage />} />
              <Route path="/profile/edit" element={<EditProfile />} />

              {/* nft 관련 */}
              <Route path="/nft">
                <Route path="create" element={<CreateNft />} />
                <Route path=":nftId">
                  <Route path="get" element={<GetNft />} />
                  <Route path="get/success" element={<GetNftSuccess />} />
                  <Route path="transfer" element={<NftTransfer />} />
                  <Route path="welcome" element={<WelcomeDeploy />} />
                </Route>
              </Route>

              {/* 통합 nft 관련 */}
              <Route path="/integrated-nft">
                <Route path="create">
                  <Route index element={<CreateIntegratedNft />} />
                  <Route path=":integratedNftInfo" element={<Success />} />
                </Route>
                <Route path=":nftId">
                  <Route path="admin" element={<IntegratedNftAdmin />} />
                </Route>
              </Route>
            </Route>

            {/* wallet 관련 */}
            <Route path="/wallet">
              <Route index element={<YoursWallet />} />
              <Route path="privacy">
                <Route index element={<WalletPrivacy />} />
                <Route
                  path="mnemonic"
                  element={<WalletPrivacyCheckMnemonic />}
                />
                <Route
                  path="passcode"
                  element={<WalletPrivacyChangePassCode />}
                />
                <Route
                  path="passcode/reset"
                  element={<WalletPrivacyResetPassCode />}
                />
              </Route>
              <Route
                path="privatekey/:chainType"
                element={<WalletPrivateKey />}
              />
              <Route path="tutorial">
                <Route index element={<WalletTutorial />} />
                <Route path="secret" element={<WalletTutorialSecret />} />
              </Route>
            </Route>

            {/* Nft Owner만 접근할 수 있는 페이지 */}
            <Route path="/nft/:nftId/setting" element={<NftOwnerOutlet />}>
              <Route index element={<NftSetting />} />
              <Route path="admin" element={<NftAdmin />} />
              <Route path="deploy" element={<NftDeploy />} />
              <Route path="deploy/reward" element={<NftDeployReward />} />
              <Route path="reward">
                <Route index element={<NftRewardSetting />} />
                <Route path="add" element={<AddReward />} />
                <Route path=":rewardId" element={<RewardDetail />} />
                <Route path=":rewardId/edit" element={<RewardEdit />} />
              </Route>
            </Route>

            {/* YRP 관련 페이지 */}
            <Route path="/yrp" element={<YrpHistory />} />
            <Route path="/yrp/charge" element={<YrpCharge />} />

            {/* 기본 페이지 */}
            <Route path="/" element={<Landing />} />

            {/* 외부 지갑 관련 페이지 */}
            <Route path="/external" element={<ExternalNft />} />

            {/* 404 페이지 처리 */}
            <Route path="/error" element={<NotFound />} />
            <Route path="*" element={<Navigate to="/error" />} />
          </Routes>
        </div>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
