import { chainType } from "ChainType";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import NFTApi from "../../apis/NftApi";
import LoadingWithSpinningImg from "../../components/loading/LoadingWithSpinningImg";
import { encryptToURI } from "../../utils/function/crypto";
import InputForm from "./inputForm/index";
import Intro from "./Intro";
import { useTranslation } from "react-i18next";

function CreateIntegratedNft() {
  const { i18n } = useTranslation();

  const navigation = useNavigate();
  const nftApi = new NFTApi();
  const [status, setStatus] = useState("INTRO");
  const [chain, setChain] = useState("");

  const createIntegratedNft = async (
    _chain: chainType,
    _nftIdList: number[]
  ) => {
    setStatus("LOADING");
    setChain(_chain);
    const res = await nftApi.createIntegratedNft(_chain, _nftIdList);
    console.log(res);

    setTimeout(() => {
      // 3초 뒤에 success page로 넘어가기
      navigation(encryptToURI(res));
    }, 3000);
  };

  useEffect(() => {
    let app = document.getElementById("app");
    app?.classList.add("none");

    return () => {
      app?.classList.remove("none");
    };
  }, []);

  const renderingPage = () => {
    const loading = () => {
      switch (i18n.language) {
        case "ko":
          return (
            <div>
              통합 NFT를
              <br />
              <b>{chain}</b> 체인에서
              <br />
              발행하는 중이에요
            </div>
          );
        case "en":
          return (
            <div className="eng">
              Intergrated NFT is creating
              <br />
              in <b>{chain}</b> chain
            </div>
          );
      }
    };

    switch (status) {
      case "INTRO":
        return (
          <Intro
            goToInputPage={() => {
              setStatus("INPUT_FORM");
            }}
          />
        );
      case "INPUT_FORM":
        return <InputForm createIntegratedNft={createIntegratedNft} />;
      case "LOADING":
        return <LoadingWithSpinningImg title={loading()} />;
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [status]);

  return <>{renderingPage()}</>;
}
export default CreateIntegratedNft;
