import { Icon } from "@iconify/react";
import Button from "../../components/button/Button";
import { useTranslation } from "react-i18next";
import { useLanguage } from "../../hook/useLanguage";
import { useNavigate } from "react-router-dom";

type transferLoadingProp = {
  nftName: string;
  transactionExplorerUrl: string | undefined;
  transactionId: string | undefined;
};

function TransferComplete({
  nftName,
  transactionExplorerUrl,
  transactionId,
}: transferLoadingProp) {
  const { t } = useTranslation();
  const { classLang } = useLanguage();
  const navigation = useNavigate();

  // const name = i18n.language === "ko" ? nftName : `The ${nftName} NFT`;
  const name =
    classLang === "ko" ? (
      <h2>{nftName}</h2>
    ) : (
      <h3 className={`transfer-ttile ${classLang}`}>
        The <h2 className={classLang}>{nftName}</h2> NFT
      </h3>
    );

  return (
    <div className="trasfer-status-container">
      <Icon icon="line-md:chevron-down-circle" color="#ed5f8a" width="60" />
      <div className="transfer-status-wrapper">
        {/* <h2 className="eng">{name}</h2> */}
        {name}
        <h3 className={classLang} style={{ fontWeight: 400 }}>
          {t("nft:transfer:complete:title")}
        </h3>
      </div>

      <div
        className="trasfer-transaction-wrapper"
        onClick={() => {
          window.open(`${transactionExplorerUrl}/${transactionId}`);
        }}
      >
        <h4 className="transfer-transaction-title eng">Transaction</h4>
        <div className="transfer-transaction-id">{transactionId}</div>
        <h6 className="transfer0transaction-text">(view on block explorer)</h6>
      </div>

      <div className="transfer-confirm-wrapper">
        <Button
          theme="purple"
          text={t("nft:transfer:buttons:confirm")}
          onClick={() => navigation("/mypage", { replace: true })}
          sticky={true}
        />
        <h5 className="transfer-confirm-text">
          {t("nft:transfer:complete:fee")}
        </h5>
      </div>
    </div>
  );
}
export default TransferComplete;
