import { useState, useEffect, useLayoutEffect } from "react";
import NFTApi from "../apis/NftApi";
import { setShowAlertInfo } from "../utils/function/showAlert";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

type useIntegratedNftProp = {
  integratedNftId: number;
};

export const useIntegratedNft = ({ integratedNftId }: useIntegratedNftProp) => {
  const nftApi = new NFTApi();
  const [integratedNftInfo, setIntegratedNftInfo] = useState<any>(null);
  const [userOwnThisNft, setUserOwnThisNft] = useState(false);
  const integratedNftList = useSelector(
    (state: any) => state.nft.integratedNftList
  );
  const { t } = useTranslation();

  useEffect(() => {
    const getIntegratedNftInfo = async () => {
      try {
        const res = await nftApi.getIntegratedNftDetail(integratedNftId);
        setIntegratedNftInfo(res);
      } catch (err) {
        setShowAlertInfo(t("landing:popup:failIntegrate"), false);
      }
    };

    getIntegratedNftInfo();
  }, [integratedNftId]);

  useEffect(() => {
    // 유저가 해당 nft를 가지고 있는 지에 대한 정보 업데이트
    const checked = integratedNftList.find(
      (nft: any) => nft.integratedNftId === integratedNftId
    );
    setUserOwnThisNft(!!checked);
  }, [integratedNftId]);

  return { integratedNftInfo, userOwnThisNft };
};
