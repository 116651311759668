import { ChangeEvent, useState } from "react";
import { useSelector } from "react-redux";
import { decryptMnemonic } from "../utils/function/crypto";

export const usePasscodeCheck = () => {
  const [inputPasscode, setInputPasscode] = useState("");
  const [isValid, setIsValid] = useState<boolean>();

  const secret = useSelector((state: any) => state.userData.secret);

  const handleInputPasscode = (e: ChangeEvent<HTMLInputElement>) => {
    setInputPasscode(e.target.value);
  };

  const confirmOldPassCode = (passCode: string) => {
    try {
      const key = decryptMnemonic(secret, passCode);
      return key;
    } catch (err) {
      return null;
    }
  };

  const confirmPassCodeHandler = (callback: () => void) => {
    const key = confirmOldPassCode(inputPasscode);
    if (key) {
      setIsValid(true);
      callback();
    } else setIsValid(false);
  };

  return {
    inputPasscode,
    handleInputPasscode,
    isValid,
    confirmPassCodeHandler,
  };
};
