import { Trans, useTranslation } from "react-i18next";
import { ReactComponent as NotificationIcon } from "../../asset/svg/triangle-right.svg";
import Button from "../../components/button/Button";
import { useLanguage } from "../../hook/useLanguage";

type checkBadgeInfoProps = {
  badgeInfo: any;
  chainInfo: any;
  prev: () => void;
  next: () => void;
};

function CheckBadgeInfo({
  badgeInfo,
  chainInfo,
  prev,
  next,
}: checkBadgeInfoProps) {
  const { t } = useTranslation();
  const { classLang } = useLanguage();

  return (
    <div className="check-badge-info">
      <div className="title-wrapper">
        <h2 className={`title ${classLang}`}>{t("making:5:question")}</h2>
        {/* <h4 className={`subtitle ${classLang}`}>
          <Trans
            i18nKey="making:5:questionDesc"
            components={{ br: <br />, span: <span /> }}
          />
        </h4> */}
      </div>
      <div className="nft-create-info-form">
        <div className="input-box-wrapper">
          <div className={`input-label input-label-${classLang}`}>
            {t("making:5:content2")}
          </div>
          <div className="input-text" id="check">
            {badgeInfo.name}
          </div>
        </div>
        <div className="input-box-wrapper">
          <div className={`input-label input-label-${classLang}`}>
            {t("making:5:content1")}
          </div>
          <img className="input-image" src={badgeInfo.image} />
        </div>
        <div className="input-box-wrapper">
          <div className={`input-label input-label-${classLang}`}>
            {t("making:5:content3")}
          </div>
          <div className="input-text" id="check">
            {badgeInfo.description}
          </div>
        </div>
        <div className="input-box-wrapper">
          <div className={`input-label input-label-${classLang}`}>
            {t("making:5:content4")}
          </div>
          <h3 className="chain-info">
            <img src={chainInfo.logo} />
            <h3>{chainInfo.chainType}</h3>
          </h3>
          <div className="check-notice" id="check">
            <NotificationIcon />
            <div className="check-notice-text">
              <Trans i18nKey="making:5:desc" components={{ a: <a /> }} />
            </div>
          </div>
        </div>
      </div>
      <div className="button-wrapper">
        <Button
          theme="black"
          text={t("making:5:bottom-button1")}
          onClick={prev}
        />
        <Button
          theme="purple"
          text={t("making:5:bottom-button2")}
          onClick={next}
        />
      </div>
    </div>
  );
}
export default CheckBadgeInfo;
