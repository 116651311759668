import { ComponentProps } from "react";
import { useLanguage } from "../../../hook/useLanguage";
import "./minibutton.scss";

interface Props extends ComponentProps<"button"> {
  content: string;
  able: boolean;
}

const MiniButton = ({ content, able, ...props }: Props) => {
  const { classLang } = useLanguage();

  return (
    <button disabled={!able} className={`mini-button ${classLang}`} {...props}>
      {content}
    </button>
  );
};

export default MiniButton;
