import { useState, useLayoutEffect } from "react";
import { useSelector } from "react-redux";
import { Transition } from "react-transition-group";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useMypageMenu, nftGetType } from "./useMypageMenu";
import NftElem from "../../components/nft/NftElem";
import RewardElem from "../../components/reward";
import IntegratedNftContainer from "./integratedNft";
import WelcomeModal from "./welcomeModal";
import CompleteQuestModal from "./completeQuestModal";
import { ReactComponent as SearchIcon } from "../../asset/svg/search.svg";
import "./index.scss";
import { chainType } from "ChainType";
import WelcomeNftPreview from "../../components/nft/WelcomeNftPreview";
import { useTranslation } from "react-i18next";
import { useLanguage } from "../../hook/useLanguage";

const WELCOME_ID = 0;

function Mypage() {
  const { i18n, t } = useTranslation();
  const { classLang } = useLanguage();

  const { state } = useLocation();
  const navigation = useNavigate();
  const name = useSelector((state: any) => state.userData.name);
  const [searchParams, setSearchParams] = useSearchParams();
  const [pastMenu, setPastMenu] = useState("");
  const {
    menuDataList,
    currMenu,
    setCurrMenu,
    searchWord,
    setSearchWord,
    currMenuData,
    emptyWord,
    gettingWelcome,
  } = useMypageMenu();
  const [showWelcomeModal, setShowWelcomeModal] = useState(false);
  const [showCompleteQuestModal, setShowCompleteQuestModal] = useState(false);
  const welcome = gettingWelcome(WELCOME_ID); //TODO 웰컴 NFT id
  const [lang, setLang] = useState("en");

  const settingLang = () => {
    setLang(i18n.language);
  };

  useLayoutEffect(() => {
    if (searchParams) {
      let menu = searchParams.get("menu") || "own";
      setCurrMenu(menu as nftGetType);
    }
  }, [searchParams]);

  useLayoutEffect(() => {
    setPastMenu(currMenu);

    const selectedMenuX = document
      .getElementById("selected-menu")
      ?.getBoundingClientRect().x;
    const menuShowBar = document.getElementById("show-selected-menu");
    const initialX = document
      ?.getElementsByClassName("mypage-menu")[0]
      ?.getBoundingClientRect().x;
    if (menuShowBar && selectedMenuX && initialX)
      menuShowBar.style.left = `${selectedMenuX - initialX}px`;
  }, [currMenu]);

  useLayoutEffect(() => {
    // new user 감지
    if (localStorage.getItem("newUser")) {
      setShowWelcomeModal(true);
      localStorage.removeItem("newUser");
    }
    if (state?.completeQuest) {
      setShowCompleteQuestModal(true);
    }
    settingLang();
  }, [state]);

  return (
    <div className="mypage">
      {showWelcomeModal && (
        <WelcomeModal
          closeModal={() => {
            setShowWelcomeModal(false);
          }}
        />
      )}
      {showCompleteQuestModal && (
        <CompleteQuestModal
          closeModal={() => {
            setShowCompleteQuestModal(false);
          }}
        />
      )}
      <div className="my-profile-wrapper">
        <span className="my-profile-yours">Sincerely Yours</span>
        <div className="my-profile-name">{name}</div>
        <button
          className="my-profile-edit"
          onClick={() => {
            navigation("/profile/edit");
          }}
        >
          edit
        </button>
        <IntegratedNftContainer />
      </div>

      <div className="mypage-menu-wrapper">
        <div className={`mypage-menu-header-${lang}`}>
          {menuDataList?.map((menuData, idx) => (
            <div
              className={`mypage-menu`}
              key={idx}
              id={currMenu === menuData.type ? "selected-menu" : ""}
              onClick={() =>
                setSearchParams({ menu: menuData.type as chainType })
              }
            >
              {menuData.title}
              <span className="mypage-menu-content-length">
                {menuData.contentLength}
              </span>
            </div>
          ))}
          <div
            className={`show-selected-menu bar-${currMenu}`}
            id="show-selected-menu"
          />
        </div>

        <div className="mypage-menu-content-searchbar">
          <SearchIcon />
          <input
            type="text"
            value={searchWord}
            placeholder="Search by name"
            onChange={(e) => {
              setSearchWord(e.target.value);
            }}
          />
        </div>

        <Transition in={pastMenu === currMenu} timeout={300}>
          {(state) => (
            <div className={`mypage-menu-nft-container ${state}`}>
              {!welcome && !searchWord.length && currMenu === "own" ? (
                <WelcomeNftPreview welcomeId={WELCOME_ID} />
              ) : (
                <></>
              )}
              {currMenuData?.length ? (
                currMenuData.map((content: any, idx: number) =>
                  currMenu !== "reward" ? (
                    <NftElem nftInfo={content} currMenu={currMenu} />
                  ) : (
                    <RewardElem
                      nftId={content.nftId}
                      nftName={content.nftName}
                      reward={content}
                      isMine={true}
                    />
                  )
                )
              ) : (
                <div className="no-content">
                  <div className={`empty-text ${classLang}`}>
                    {searchWord.length ? (
                      <>
                        <h2 className="eng">'{searchWord}'</h2>
                        <span className="empty-text-caption">
                          {t("mypage:empty:search")}
                        </span>
                      </>
                    ) : (
                      emptyWord
                    )}
                  </div>
                </div>
              )}
            </div>
          )}
        </Transition>
      </div>
    </div>
  );
}
export default Mypage;
