import { Icon } from "@iconify/react";
import { useTranslation } from "react-i18next";
import { useLanguage } from "../../hook/useLanguage";

type transferLoadingProp = {
  nftName: string;
};

function TransferLoading({ nftName }: transferLoadingProp) {
  const { t } = useTranslation();
  const { classLang } = useLanguage();

  return (
    <div className="trasfer-status-container">
      <Icon icon="line-md:loading-alt-loop" width="60" color="#ed5f8a" />
      <div className="transfer-status-wrapper">
        <h2 className="eng">{nftName}</h2>
        <h3 className={classLang} style={{ fontWeight: 400 }}>
          {t("nft:transfer:loading:title")}
        </h3>
      </div>

      <h5 className={classLang}>{t("nft:transfer:loading:subtitle")}</h5>
      <h6 className={`transfer-warning ${classLang}`}>
        {t("nft:transfer:loading:warning")}
      </h6>
    </div>
  );
}
export default TransferLoading;
