import { useTranslation } from "react-i18next";
import Button from "../button/Button";
import Modal from "../modal/Modal";
import "./index.scss";
import { useLanguage } from "../../hook/useLanguage";

export type popupProps = {
  /**
   * Function to close popup
   */
  closeModal: () => void;
  /**
   * Popup title
   */
  title: string;
  /**
   * Text for approve button
   */
  approveText?: string;
  /**
   * Text for deny button
   */
  denyText?: string;
  /**
   * Function for approve button
   */
  approve: Function;
  /**
   * Function for deny button
   */
  deny?: Function | null;
};

/**
 * Popup UI component
 */
export const Popup = ({
  closeModal,
  title,
  approveText,
  denyText,
  approve,
  deny = null,
}: popupProps) => {
  const { t } = useTranslation();
  const { classLang } = useLanguage();

  const tDeny = t("making:popup:select1") || denyText;
  const tApprove = t("making:popup:select2") || approveText;

  return (
    <Modal closeModal={closeModal}>
      <div className="modal-wrapper popup">
        <div className={`popup-title popup-title-${classLang}`}>{title}</div>
        <div className="popup-button-wrapper">
          {deny && (
            <Button
              className="popup-button"
              theme="black"
              text={tDeny!}
              onClick={() => deny()}
            />
          )}
          <Button
            className="popup-button"
            theme="purple"
            text={tApprove!}
            onClick={() => approve()}
          />
        </div>
      </div>
    </Modal>
  );
};
