import { useTranslation } from "react-i18next";
import { useYrp } from "../../../hook/useYrp";
import { ableChargeChain } from "../../../redux/yrp/yrpTypes";
import { useSelector } from "react-redux";
import { useLanguage } from "../../../hook/useLanguage";
import "./accordian.scss";
import { ellipsisMiddle } from "../../../utils/function/ellipsisMiddle";

/** 지불 수단 */
const PayMethod = () => {
  const { selectedChain } = useYrp();
  const { t } = useTranslation();
  const wallets = useSelector((state: any) => state.wallet);
  const { classLang } = useLanguage();

  return (
    <div className="pay-method-wrapper">
      <div className="chain-wrapper">
        <img
          src={
            ableChargeChain[selectedChain as keyof typeof ableChargeChain].logo
          }
          alt="chain"
        />
        <div className="chain-info-wrapper">
          <h2 className="eng">
            {
              ableChargeChain[selectedChain as keyof typeof ableChargeChain]
                .name
            }
          </h2>
          <h4 className="eng">
            {
              ableChargeChain[selectedChain as keyof typeof ableChargeChain]
                .full
            }
          </h4>
        </div>
      </div>
      <div className="content-line">
        <h4 className={classLang}>{t("yrp:charge:accordian:address")}</h4>
        <h4>{ellipsisMiddle(wallets.Ethereum)}</h4>
      </div>
    </div>
  );
};

export default PayMethod;
