import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { NftInfo } from "NftType";
import { copyToClipboard } from "../../utils/function/linkShare";
import { ReactComponent as MoreView } from "../../asset/svg/three-dots.svg";
import { ReactComponent as SendIcon } from "../../asset/svg/send.svg";
import { ReactComponent as CopyIcon } from "../../asset/svg/copy.svg";
import "./NftElem.scss";
import { useEffect, useState } from "react";
import NftElemLoading from "./NftElemLoading";
import { useTranslation } from "react-i18next";
import { useLanguage } from "../../hook/useLanguage";
import Locked from "./Locked";

type nftElemProps = {
  /**
   * Corresponding NFT information
   */
  nftInfo: NftInfo;
  /**
   * 현재 메뉴 -> own일 때에만 locked 표시
   */
  currMenu: string;
};

/**
 * NFT UI Component
 */
function NftElem({ nftInfo, currMenu }: nftElemProps) {
  const { t } = useTranslation();
  const { classLang } = useLanguage();

  const [loading, setLoading] = useState(true);
  const ownNftIdList = useSelector((state: any) => state.nft.ownNftIdList);
  const navigation = useNavigate();

  const MoreTransferLine = () => {
    if (nftInfo.isLocked) return <></>;
    if (!!ownNftIdList.includes(nftInfo.id))
      return (
        <div
          className="nft-more-view-elem"
          onClick={() => {
            navigation(`/nft/${nftInfo.id}/transfer`);
          }}
          onTouchStart={() => {
            navigation(`/nft/${nftInfo.id}/transfer`);
          }}
        >
          <SendIcon /> Transfer
        </div>
      );
    return <></>;
  };

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1500);
  }, []);

  return (
    <>
      {loading ? (
        <NftElemLoading />
      ) : (
        <div className="nft-elem-wrapper">
          <div
            onClick={() => {
              navigation(`/nft/${nftInfo.id}/detail`);
            }}
          >
            <img className="nft-image" src={nftInfo.image} />
            <div className="nft-elem-body">
              <h4 className="nft-name eng">{nftInfo.nftName}</h4>
              <div className={`nft-reward-length ${classLang}`}>
                {t("mypage:card:benefit")} : {nftInfo.numberOfRewards}
              </div>
            </div>
          </div>
          <div className="nft-elem-footer">
            <button
              className="nft-more-view"
              onTouchStart={(e) => {
                e.currentTarget.focus();
              }}
            >
              {nftInfo.id !== 0 && <MoreView className="more-view-icon" />}
              <div className="nft-more-view-wrapper">
                <MoreTransferLine />
                <div
                  className="nft-more-view-elem"
                  onClick={() => {
                    copyToClipboard(
                      `${window.location.origin}/nft/${nftInfo.id}`,
                      nftInfo.nftName,
                      t("nft:detail:link"),
                      t("nft:detail:linkFail")
                    );
                  }}
                  onTouchStart={() => {
                    copyToClipboard(
                      `${window.location.origin}/nft/${nftInfo.id}`,
                      nftInfo.nftName,
                      t("nft:detail:link"),
                      t("nft:detail:linkFail")
                    );
                  }}
                >
                  <CopyIcon /> Copy link
                </div>
              </div>
            </button>
            {nftInfo.isLocked && <Locked />}
          </div>
        </div>
      )}
    </>
  );
}
export default NftElem;
