import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../components/button/Button";
import { useTranslation } from "react-i18next";

type enterOldPassCode = {
  confirmOldPassCode: Function;
  confirmAction: Function;
};

function EnterOldPassCode({
  confirmOldPassCode,
  confirmAction,
}: enterOldPassCode) {
  const { t } = useTranslation();

  const navigation = useNavigate();
  const [passCode, setPassCode] = useState("");
  const [valid, setValid] = useState(true);

  const confirmPassCodeHandler = () => {
    const isValid = confirmOldPassCode(passCode);

    if (isValid) {
      confirmAction();
    } else {
      setValid(false);
    }
  };

  useEffect(() => {
    setValid(true);
  }, [passCode]);

  return (
    <form
      className="enter-old-passcode"
      onSubmit={(e) => {
        e.preventDefault();
        confirmPassCodeHandler();
      }}
    >
      <div className="input-box-wrapper">
        <input
          className="input-textbox"
          type="password"
          value={passCode}
          placeholder={t("wallet:yours:privacy:passcode:placeholder")}
          onChange={(e) => {
            setPassCode(e.target.value);
          }}
        />
        {!valid && (
          <div className="input-status" id="warning">
            {t("wallet:yours:privacy:passcode:invalid")}
          </div>
        )}
      </div>

      <div className="button-wrapper">
        <button
          type="button"
          className="lose-passcode"
          onClick={() => navigation("/wallet/privacy/passcode/reset")}
        >
          {t("wallet:yours:privacy:passcode:forget")}
        </button>
        <Button
          theme="purple"
          text={t("wallet:yours:privacy:passcode:buttons:check")}
          disabled={!passCode.length}
          type="submit"
        />
      </div>
    </form>
  );
}
export default EnterOldPassCode;
