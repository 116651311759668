import { useTranslation } from "react-i18next";
import Lottie from "lottie-react";
import pig from "../../../asset/lotties/pig.json";
import "./charge.scss";
import { ReactComponent as Noti } from "../../../asset/svg/noti.svg";
import { ableChargeChain } from "../../../redux/yrp/yrpTypes";
import { RATION_COIN_PER_YRP, useYrp } from "../../../hook/useYrp";
import { ellipsisMiddle } from "../../../utils/function/ellipsisMiddle";
import { useLanguage } from "../../../hook/useLanguage";
import { useMemo } from "react";

interface Props {
  success: boolean;
}

const Result = ({ success }: Props) => {
  const { t } = useTranslation();
  const { classLang } = useLanguage();
  const {
    selectedChain,
    EVM_WALLET_ADDR,
    APTOS_WALLET_ADDR,
    inputAmount,
    txFee,
  } = useYrp();
  const walletAddr = useMemo(() => {
    if (selectedChain === "APT") return APTOS_WALLET_ADDR;
    else return EVM_WALLET_ADDR;
  }, [selectedChain]);

  const listing = [
    <div className="content-line">
      <h5 className={classLang}>{t("yrp:history:modal:res")}</h5>
      <h5 className={classLang}>{ellipsisMiddle(walletAddr)}</h5>
    </div>,
    <div className="content-line">
      <h5>{t("yrp:history:modal:chain")}</h5>
      <div className="chain-wrapper">
        <img
          src={
            ableChargeChain[selectedChain as keyof typeof ableChargeChain].logo
          }
          alt="chain"
        />
        <div>
          <h4>
            {
              ableChargeChain[selectedChain as keyof typeof ableChargeChain]
                .name
            }
          </h4>
          <h4>
            {
              ableChargeChain[selectedChain as keyof typeof ableChargeChain]
                .full
            }
          </h4>
        </div>
      </div>
    </div>,
    <div className="content-line">
      <h5>{t("yrp:history:modal:input")}</h5>
      <h5>
        {inputAmount.toLocaleString()}{" "}
        {ableChargeChain[selectedChain as keyof typeof ableChargeChain].name}
      </h5>
    </div>,
    <div className="content-line">
      <div className="noti-line">
        <h5>{t("yrp:charge:accordian:fee")}</h5>
        <Noti />
      </div>
      <h5>{`${txFee.toFixed(6)} ETH`}</h5>
    </div>,
    <div className="content-line">
      <h5>{t("yrp:history:modal:yrp")}</h5>
      <h5>
        {(
          inputAmount *
          RATION_COIN_PER_YRP[selectedChain as keyof typeof ableChargeChain]
        ).toLocaleString()}{" "}
        YRP
      </h5>
    </div>,
  ];

  return (
    <div className="yrp-result-wrapper">
      <div className="title-wrapper">
        <Lottie
          animationData={pig}
          loop
          autoplay
          style={{ width: 200, height: "auto" }}
        />
        <h2 className={classLang}>{t("yrp:charge:result:title")}</h2>
        <h6 className={classLang}>{t("yrp:charge:result:desc")}</h6>
      </div>
      <div className="content-wrapper">
        <div className="content-line">
          <h3>{t("yrp:history:modal:caption")}</h3>
        </div>
        {listing.map((List) => List)}
      </div>
    </div>
  );
};

export default Result;
