import { useTranslation } from "react-i18next";
import MiniHeader from "../../../components/miniHeader/MiniHeader";
import "./history.scss";
import { useLanguage } from "../../../hook/useLanguage";
import { useYrp } from "../../../hook/useYrp";
import { EmptyHistory, HistoryItem, Minibutton } from "../../../components/yrp";
import { YrpHistoryInterface } from "YrpResponseType";
import { useEffect } from "react";

const YrpHistoryPage = () => {
  const { t } = useTranslation();
  const { classLang } = useLanguage();
  const {
    totalAmount,
    dollarCoverter,
    histories,
    goToCharge,
    getHistory,
    getTotalAmount,
  } = useYrp();

  useEffect(() => {
    getHistory();
    getTotalAmount();
  }, []);

  return (
    <div style={{ minHeight: "70vh" }} className="yrp-history-page">
      <MiniHeader title={t("yrp:header:history")} />
      <div className="yrp-amount-box">
        <div className="inner-wrapper">
          <h6 className={classLang}>{t("yrp:history:amount")}</h6>
          <h1 className="eng">{totalAmount.toLocaleString()} YRP</h1>
          <h2 className="eng">{`= $ ${dollarCoverter(
            totalAmount
          ).toLocaleString()}`}</h2>
        </div>
        <div className="buttons-wrapper">
          <Minibutton
            content={t("yrp:history:buttons:charge")}
            able
            onClick={goToCharge}
          />
          <Minibutton
            content={t("yrp:history:buttons:exchange")}
            // able={totalAmount > 0}
            able={false}
          />
        </div>
      </div>
      <div className="history-wrapper">
        <h3 className={`caption ${classLang}`}>{t("yrp:history:caption")}</h3>
        {histories.length > 0 ? (
          histories
            .reverse()
            .map((item: YrpHistoryInterface) => (
              <HistoryItem item={item} key={item.id} />
            ))
        ) : (
          <EmptyHistory />
        )}
      </div>
    </div>
  );
};

export default YrpHistoryPage;
