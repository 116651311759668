import Button from "../button/Button";
import { ReactComponent as KakaoLogo } from "../../asset/svg/kakao-logo.svg";
import { useTranslation } from "react-i18next";

function KakaoLoginButton() {
  const { t } = useTranslation();

  const kakaoLogin = () => {
    let redirectUrl = `${window.location.origin}/oauth`;
    window.location.href = `https://kauth.kakao.com/oauth/authorize?response_type=code&client_id=${process.env.REACT_APP_KAKAO_API_KEY}&redirect_uri=${redirectUrl}`;
  };

  return (
    <Button
      text={t("landing:button:kakao")}
      onClick={() => {
        kakaoLogin();
      }}
      bgColor="#FAE64C"
      textColor="black"
      icon={<KakaoLogo />}
    />
  );
}
export default KakaoLoginButton;
