import { Category, NftReward } from "NftType";
import { useState, useEffect } from "react";
import AdminApi from "../apis/AdminApi";
import NFTApi from "../apis/NftApi";

type useNftAdminRewardProp = {
  rewardId: number;
  nftId: number;
};

export const useNftAdminReward = ({
  rewardId,
  nftId,
}: useNftAdminRewardProp) => {
  const adminApi = new AdminApi();
  const nftApi = new NFTApi();
  const [rewardInfo, setRewardInfo] = useState<NftReward>();

  const editReward = async (
    rewardName: string,
    description: string,
    category: Category,
    option: string | null
  ) => {
    const res = await nftApi.editNftReward(
      nftId,
      rewardId,
      rewardName,
      description,
      category,
      option
    );
    setRewardInfo(res);
  };

  const deleteReward = async () => {
    await nftApi.deleteNftReward(nftId, rewardId);
  };

  useEffect(() => {
    const getNftRewardDetail = async () => {
      const res = await adminApi.getNftAdminRewardDetail(rewardId);
      setRewardInfo(res);
    };

    if (rewardId) {
      getNftRewardDetail();
    }
  }, [rewardId]);

  return { rewardInfo, editReward, deleteReward };
};
