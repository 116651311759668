import { useNavigate } from "react-router-dom";
import "./NftElem.scss";
import WelcomeImage from "../../asset/image/welcome.png";
import { ReactComponent as MoreView } from "../../asset/svg/three-dots.svg";
import { useEffect, useState } from "react";
import NftElemLoading from "./NftElemLoading";
import { useTranslation } from "react-i18next";
import { useLanguage } from "../../hook/useLanguage";

interface Props {
  welcomeId: number;
}

/** 웰컴 카드 내부 */
const PreivewInner = () => {
  const { t } = useTranslation();
  const { classLang } = useLanguage();

  return (
    <div className="nft-elem-wrapper">
      <div>
        <img className="nft-image" src={WelcomeImage} />
        <div className="nft-elem-body">
          <h4 className="nft-name eng">WELCOME TO YOURS</h4>
          <div className={`nft-reward-length ${classLang}`}>
            {t("mypage:card:benefit")} : 1
          </div>
        </div>
      </div>
      <div className="nft-elem-footer">
        <button className="nft-more-view">
          <MoreView className="more-view-icon" />
        </button>
      </div>
      <div className="nft-elem-welcome-blur" />
    </div>
  );
};

const WelcomeNftPreview = ({ welcomeId }: Props) => {
  const { t } = useTranslation();
  const { classLang } = useLanguage();

  const [loading, setLoading] = useState(true);

  const navigation = useNavigate();
  const title = "WELCOME\nTO\nYOURS";
  const content = t("mypage:welcome:preview");

  const moveToWelcomeGet = () => {
    navigation(`/nft/${welcomeId}/detail`);
  };

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1500);
  }, []);

  return loading ? (
    <NftElemLoading />
  ) : (
    <div className="nft-elem-welcome" onClick={moveToWelcomeGet}>
      <PreivewInner />
      <div className="nft-welcome-wrapper">
        <h5 className={classLang}>{title}</h5>
        <h6 className={classLang}>{content}</h6>
      </div>
    </div>
  );
};

export default WelcomeNftPreview;
