import {
  addCreateNft,
  addTransferNft,
  setCreateNftIdList,
  setIntegratedNftList,
  setOwnNftIdList,
  setTransferNftIdList,
  addIntegratedNft,
} from "../redux/nft/nftAction";
import { destroy, get, patch, post } from "./AxiosCreate";
import store from "../redux/store";
import { nftGetType } from "../pages/mypage/useMypageMenu";
import {
  CreateIntegratedNftResponse,
  CreateNftResponse,
  CreateOwnNftResponse,
  ExternalNftResponse,
  RewardNftResponse,
  TransferNftResponse,
  UserIntegratedNft,
  VerifyNftByEmailResponse,
  WelcomeMftMinting,
} from "NftResponse";
import { Category, IntegratedNftInfo, NftInfo, NftReward } from "NftType";
import { Transaction } from "TransactionType";
import { chainType } from "ChainType";
import { Profile } from "UserType";
import { ExternalWalletNftRequest } from "UserResquestType";

class NFTApi {
  getMypageNftList = async (type: nftGetType) => {
    const data = await get<CreateOwnNftResponse[] | RewardNftResponse[]>(
      `/nft?type=${type}`
    );
    return data;
  };

  getMypageNftSearch = async (type: String, searchWord: String) => {
    const data = await get<CreateOwnNftResponse[] | RewardNftResponse[]>(
      `search?type=${type}&keyword=${searchWord}`
    );
    return data;
  };

  getUserOwnNftIdList = async () => {
    const data = await get<number[]>(`nft/own`);
    store.dispatch(setOwnNftIdList(data));
    return data;
  };

  getUserCreateNftIdList = async () => {
    const data = await get<number[]>(`nft/creation`);
    store.dispatch(setCreateNftIdList(data));
    return data;
  };

  getUserTransferNftIdList = async () => {
    const data = await get<number[]>(`nft/send`);
    store.dispatch(setTransferNftIdList(data));
    return data;
  };

  /**
   * get nft detail information
   *
   * @param {number} nftId - NFT ID to get detail
   * @returns {NftInfo} - NFT detail information
   */
  getNftDetail = async (nftId: number): Promise<NftInfo> => {
    const data = await get<NftInfo>(`nft/${nftId}/detail`);
    return data;
  };

  /**
   * get nft owner list
   *
   * @param {number} nftId - NFT ID to get owner list
   * @returns {Profile[]} - NFT owner list
   */
  getNftOwnerList = async (nftId: number): Promise<Profile[]> => {
    const data = await get<Profile[]>(`nft/${nftId}/owners`);
    return data;
  };

  /* NFT 생성 */
  /**
   * create NFT and set add created nft id info to redux store
   * @param badge information of NFT to create
   * @returns created NFT ID
   */
  createNft = async (badge: object): Promise<number> => {
    const { id } = await post<CreateNftResponse>("nft", badge);
    store.dispatch(addCreateNft(id));
    return id;
  };

  /**
   * send email to authenticate NFT
   *
   * @param {number} nftId - NFT ID to authenticate
   * @param {string} email - email to get email authentication
   */
  sendVerifyEmail = async (nftId: number, email: string) => {
    const data = await post("nft/email", {
      nftId: nftId,
      email: email,
    });
    return data;
  };

  /**
   * verify NFT by email authentication code
   *
   * @param {string|null} code - email authentication code
   * @returns {VerifyNftByEmailResponse} - NFT verification information
   */
  verifyNftByEmail = async (
    code: string | null
  ): Promise<VerifyNftByEmailResponse> => {
    const data = await post<VerifyNftByEmailResponse>(
      "/nft/email/verification",
      {
        code: code,
      }
    );
    return data;
  };

  /**
   * send user certification photo
   *
   * @param {FormData} formData - form data to send user certification photo
   */
  sendUserVerifyPhoto = async (formData: HTMLFormElement) => {
    await post("nft/verification/photo", formData);
  };

  /**
   * get whether user certification photo is verified
   * @param {number} nftId - NFT ID to get user certification photo verification
   * @returns {boolean} - whether user certification photo is verified
   */
  nftPhotoVerificationPending = async (nftId: number): Promise<boolean> => {
    const data = await get<boolean>(`nft/${nftId}/photo`);
    return data;
  };

  /**
   * transfer NFT to other wallet address
   *
   * @param {number} nftId - NFT ID to transfer
   * @param {string} address - address to transfer NFT
   * @returns {TransferNftResponse} - transfer NFT response
   */
  transferNft = async (
    nftId: number,
    address: string
  ): Promise<TransferNftResponse> => {
    const data = await post<TransferNftResponse>(`nft/${nftId}/transfer`, {
      walletAddress: address,
    });
    store.dispatch(addTransferNft(nftId));
    return data;
  };

  /**
   * deploy NFT
   *
   * @param {number} nftId - NFT ID to deploy
   * @returns {Transaction} = transaction information
   */
  deployNft = async (nftId: number): Promise<Transaction> => {
    const data = await post<Transaction>(`nft/publish`, { nftId: nftId });
    return data;
  };

  /**
   * edit NFT
   *
   * @param {number} nftId - NFT ID to edit
   * @returns {Transaction} - transaction information
   */
  editDeployNft = async (nftId: number): Promise<Transaction> => {
    const data = await patch<Transaction>(`nft/publish`, { nftId: nftId });
    return data;
  };

  // reward 관련
  /**
   * create reward for NFT
   *
   * @param {number} nftId - NFT ID to create reward
   * @param {string} rewardName - reward name
   * @param {string} description - reward description
   * @param {Category} category - NFT 혜택 카테고리
   * @param {string} option - NFT 혜택 링크 등
   */
  createNftReward = async (
    nftId: number,
    rewardName: string,
    description: string,
    category: Category,
    option: string | null
  ) => {
    await post(`nft/${nftId}/reward`, {
      rewardName: rewardName,
      description: description,
      category,
      option: option || "",
    });
  };

  /**
   * get reward detail information
   *
   * @param {number} rewardId - reward ID to get detail
   * @returns reward detail information
   */
  getNftRewardDetail = async (rewardId: number) => {
    const data = await get<NftReward>(`nft/${rewardId}/reward/detail`);
    return data;
  };

  /**
   * edit reward information
   *
   * @param {number} nftId - NFT ID to edit reward
   * @param {number} rewardId - reward ID to edit
   * @param {string} rewardName - new reward name
   * @param {string} description - new reward description
   * @param {Category} category - NFT 혜택 카테고리
   * @param {string} option - NFT 혜택 링크 등
   * @returns {NftReward} - edited reward information
   */
  editNftReward = async (
    nftId: number,
    rewardId: number,
    rewardName: string,
    description: string,
    category: Category,
    option: string | null
  ): Promise<NftReward> => {
    const data = await patch<NftReward>(`nft/${nftId}/reward`, {
      rewardId: rewardId,
      rewardName: rewardName,
      description: description,
      category,
      option: option || "",
    });
    return data;
  };

  /**
   * delete nft reward
   *
   * @param nftId
   * @param rewardId
   */
  deleteNftReward = async (nftId: number, rewardId: number) => {
    await destroy(`nft/${nftId}/${rewardId}`);
  };

  // integrated nft 관련
  /**
   * get nft list that can be integrated
   * @param {chainType} chain - chain type to get nft list
   * @returns {NftInfo[]} - nft list that can be integrated
   */
  getIntegratedAvailableNftList = async (chain: chainType) => {
    const data = await get<NftInfo[]>(
      `nft/integrated/check?chainType=${chain}`
    );
    return data;
  };

  /**
   * create integrated nft
   *
   * @param {chainType} chainType - chain type to create integrated nft
   * @param {number[]} nftIdArray - nft ids included in integrated nft
   * @returns
   */
  createIntegratedNft = async (
    chainType: chainType,
    nftIdArray: number[]
  ): Promise<CreateIntegratedNftResponse> => {
    //TODD _ 통합 NFT API
    const nftInfo = await post<CreateIntegratedNftResponse>(`nft/integrated`, {
      nftIdArray: nftIdArray,
      chainType: chainType,
    });
    store.dispatch(
      addIntegratedNft({
        integratedNftId: nftInfo.id,
        chainType: nftInfo.chainType,
        hash: nftInfo.transactionHash,
      })
    );
    return nftInfo;
  };

  /**
   * update integrated nft - add nft
   *
   * @param {number} integratedNftId - integrated nft id to update
   * @param {number[]} nftIdArray - nft ids to add
   */
  updateIntegratedNft = async (
    integratedNftId: number,
    nftIdArray: number[],
    contractId: number,
    chainType: string
  ) => {
    await patch(`nft/integrated`, {
      integratedId: integratedNftId,
      contractId,
      nftIdArray: nftIdArray,
      chainType,
    });
  };

  getIntegratedNftDetail = async (nftId: number) => {
    const data = await get<IntegratedNftInfo>(
      `nft/integrated/detail?id=${nftId}`
    );
    return data;
  };

  /**
   * get user has integrated nft list
   *
   * @returns {UserIntegratedNft[]} - user integrated nft list
   */
  getUserIntegratedNftList = async (): Promise<UserIntegratedNft[]> => {
    const data = await get<UserIntegratedNft[]>(`nft/integrated`);
    store.dispatch(setIntegratedNftList(data));
    return data;
  };

  /**
   * 웰컴 NFT 민팅
   */
  mintingWelcomeNft = async (): Promise<WelcomeMftMinting> => {
    const data = await post<WelcomeMftMinting>(`nft/welcome`, {});
    return data;
  };

  /**
   * delete integrated nft
   * @param {number} id - nft id
   */
  deleteIntegratedAvailableNft = async (id: number, contractId: number) => {
    const data = await destroy<any>(
      `nft/integrated?id=${id}&contractId=${contractId}`
    );
    return data;
  };

  /**
   * 외부 NFT 래핑 (외부 nft인지 확인 작업 진행)
   */
  wrappingExternalNft = async (
    nftInfo: ExternalWalletNftRequest,
    chainType: chainType
  ): Promise<ExternalNftResponse> => {
    const data = await post<ExternalNftResponse>(`/nft/external`, {
      nftName: nftInfo.nftName,
      image: nftInfo.image,
      chainType,
      nftAddress: nftInfo.nftAddress,
    });

    return data;
  };

  /**
   * 체크 이후, wrappedNft 주소로 트랜스퍼 요청
   */
  transferingExternalNft = async (
    nftInfo: ExternalWalletNftRequest,
    wrappedAddress: string,
    isExternal: boolean,
    chainType: chainType
  ) => {
    const res = await patch("/nft/external", {
      chainType,
      nftAddress: wrappedAddress,
      isExternal,
      tokenId: nftInfo.tokenId,
    });
    return res;
  };
}
export default NFTApi;
