import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import NFTApi from "../../../apis/NftApi";
import { useNft } from "../../../hook/useNft";
import Loading from "../../../components/loading/Loading";
import "./index.scss";
import WelcomeSuccess from "./WelcomeSuccess";
import { useSelector } from "react-redux";
import { setShowAlertInfo } from "../../../utils/function/showAlert";
import { useTranslation } from "react-i18next";

/**
 * welcome nft 민팅
 */
function WelcomeDeploy() {
  const { t, i18n } = useTranslation();

  const nftApi = new NFTApi();
  const name = useSelector((state: any) => state.userData.name);
  const navigation = useNavigate();
  const { nftId } = useParams();
  const { nftInfo } = useNft({ nftId: Number(nftId) });
  const [pageMode, setPageMode] = useState("LOADING");

  const loading = () => {
    switch (i18n.language) {
      case "ko":
        return (
          <>
            <b>{nftInfo.nftName}</b> NFT를
            <br /> <b>{name}</b>님의 지갑으로
            <br /> 민팅하는 중이에요
          </>
        );
      case "en":
        return (
          <>
            The <b>{nftInfo.nftName}</b> NFT
            <br />
            is being minted.
          </>
        );
    }
  };

  const deployNft = async () => {
    try {
      await nftApi.mintingWelcomeNft();
      setPageMode("SUCCESS");
    } catch (err: any) {
      console.log(err);
      navigation(-1);
      setShowAlertInfo(t("minting:welcome:fail"), false);
    }
  };

  const pageRenderer = () => {
    switch (pageMode) {
      case "LOADING":
        return <Loading loadingText={loading()} />;
      case "SUCCESS":
        return <WelcomeSuccess />;
      default:
    }
  };

  const preventToClose = (e: any) => {
    e.preventDefault();
    e.returnValue = "";
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    if (pageMode == "LOADING") {
      window.addEventListener("beforeunload", preventToClose);
    } else {
      window.removeEventListener("beforeunload", preventToClose);
    }
    return () => window.removeEventListener("beforeunload", preventToClose);
  }, [pageMode]);

  useEffect(() => {
    deployNft();
  }, []);

  return <div className="nft-setting-deploy">{pageRenderer()}</div>;
}
export default WelcomeDeploy;
