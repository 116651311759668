import { ReactComponent as Logo } from "../../asset/svg/logo.svg";
import kakaoImg from "../../asset/image/kakao_link.png";
import emailImg from "../../asset/image/email-link.png";
import "./index.scss";
import { Trans, useTranslation } from "react-i18next";
import { useLanguage } from "../../hook/useLanguage";
import useDevice from "../../hook/useDevice";
import { useLocation } from "react-router-dom";
import Floating from "../../pages/mypage/Floating";

/**
 * Page Footer Component
 */
function Footer() {
  const { t } = useTranslation();
  const { classLang } = useLanguage();
  const device = useDevice();
  const { pathname } = useLocation();

  const contactLinkList = [
    { img: kakaoImg, link: "http://pf.kakao.com/_xgxkExbxj" },
    { img: emailImg, link: "mailto:contact@blockwavelabs.io" },
  ];

  const policyLinkList = [
    {
      name: t("setting:footer:terms"),
      link: "https://www.notion.so/propwave/Yours-14cdd37b08e6496184d0d463f1af6746",
    },
    {
      name: t("setting:footer:policy"),
      link: "https://www.notion.so/propwave/Yours-a10e4ec9568c4e19b178306158370623",
    },
  ];

  return (
    <div className="page-footer">
      <Logo className="logo" />
      <div className={`page-footer-description ${classLang}`}>
        <Trans i18nKey="setting:footer:content" components={{ br: <br /> }} />
      </div>
      <a
        className={`service-guide-link ${classLang}`}
        target="_blank"
        href="https://yours.framer.website/"
      >
        {t("setting:footer:button")}
      </a>
      <div className="contact-link-wrapper">
        {contactLinkList.map((item, index) => (
          <a key={index} href={item.link} target="_blank" rel="noreferrer">
            <img src={item.img} />
          </a>
        ))}
      </div>
      <div>ⓒ 2022-2023. Yours all rights reserved.</div>
      <div className="policy-link-wrapper">
        {policyLinkList.map((item, index) => (
          <a key={index} href={item.link} target="_blank">
            {item.name}
          </a>
        ))}
      </div>
      {((device === "MOBILE" && pathname === "/mypage") ||
        ((device === "PC" || device === "TABLET") &&
          pathname !== "/landing")) && <Floating />}
    </div>
  );
}
export default Footer;
