import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import NFTApi from "../../apis/NftApi";
import Loading from "../../components/loading/Loading";
import { useIntegratedNft } from "../../hook/useIntegratedNft";
import AdminMenu from "./AdminMenu";
import UpdateSuccess from "./UpdateSuccess";
import "./index.scss";
import { useTranslation } from "react-i18next";
import { useLanguage } from "../../hook/useLanguage";
import DeleteSuccess from "./DeleteSuccess";

function IntegratedNftAdmin() {
  const { t } = useTranslation();
  const { classLang } = useLanguage();

  const { nftId } = useParams();
  const nftApi = new NFTApi();
  const { integratedNftInfo } = useIntegratedNft({
    integratedNftId: Number(nftId),
  });
  const [pageMode, setPageMode] = useState("MENU");

  const updateIntegratedNft = async (checkedNftList: number[]) => {
    setPageMode("LOADING");
    await nftApi.updateIntegratedNft(
      Number(nftId),
      checkedNftList,
      integratedNftInfo.contractId || 1,
      integratedNftInfo.chainType
    );
    setTimeout(() => {
      setPageMode("UPDATE_SUCCESS");
    }, 3000);
  };

  const deleteIntegratedNft = async () => {
    setPageMode("DELETE_LOADING");
    await nftApi.deleteIntegratedAvailableNft(
      integratedNftInfo.id,
      integratedNftInfo.contractId
    );
    await nftApi.getUserIntegratedNftList();
    setTimeout(() => {
      setPageMode("DELETE_SUCCESS");
    }, 3000);
  };

  const pageRenderer = () => {
    switch (pageMode) {
      case "MENU":
        return (
          <AdminMenu
            integratedNftInfo={integratedNftInfo}
            updateIntegratedNft={updateIntegratedNft}
            deleteIntegratedNft={deleteIntegratedNft}
          />
        );
      case "LOADING":
        return (
          <Loading
            loadingText={
              <h3 className={classLang}>{t("intergrated:update:loading")}</h3>
            }
          />
        );
      case "DELETE_LOADING":
        return (
          <Loading
            loadingText={
              <h3 className={classLang}>{t("intergrated:delete:loading")}</h3>
            }
          />
        );
      case "UPDATE_SUCCESS":
        return <UpdateSuccess nftId={Number(nftId)} />;
      case "DELETE_SUCCESS":
        return <DeleteSuccess nftId={Number(nftId)} />;
      default:
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pageMode]);

  return (
    <div
      className="integratednft-admin"
      style={{ width: "-webkit-fill-available" }}
    >
      {pageRenderer()}
    </div>
  );
}
export default IntegratedNftAdmin;
