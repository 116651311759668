import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useNftAdminReward } from "../../../hook/useNftAdminReward";
import { setShowAlertInfo } from "../../../utils/function/showAlert";
import { Popup } from "../../../components/popup";
import MiniHeader from "../../../components/miniHeader/MiniHeader";
import "./RewardDetail.scss";
import Button from "../../../components/button/Button";
import { useTranslation } from "react-i18next";
import { useLanguage } from "../../../hook/useLanguage";
import { useReward } from "../../../hook/useReward";
import { truncateString } from "../../../utils/function/ellipsisMiddle";

function RewardDetail() {
  const { t } = useTranslation();
  const { classLang } = useLanguage();

  const navigation = useNavigate();
  const { nftId, rewardId } = useParams();
  const { rewardInfo, deleteReward } = useNftAdminReward({
    nftId: Number(nftId),
    rewardId: Number(rewardId),
  });
  const { getBenefitInfo } = useReward();
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  console.log(rewardInfo);

  const deleteNftReward = async () => {
    try {
      await deleteReward();
      setShowAlertInfo(t("nft:benefits:remove:toast"), true);
      navigation(-1);
    } catch (err) {
      setShowAlertInfo(t("nft:benefits:remove:fail"), false);
    }
  };

  const benefitType = () => {
    if (rewardInfo?.category) {
      const targetType = getBenefitInfo(rewardInfo.category)?.title;
      if (targetType) return targetType;
      else return t("nft:benefits:add:gating:types:empty");
    } else return t("nft:benefits:add:gating:types:empty");
  };

  const handleBenefitClick = (link: string) => {
    window.open(link);
  };

  return (
    <>
      {showConfirmModal && (
        <Popup
          title={t("nft:benefits:remove:modal")}
          approve={() => {
            deleteNftReward();
            setShowConfirmModal(false);
          }}
          deny={() => {
            setShowConfirmModal(false);
          }}
          closeModal={() => {
            setShowConfirmModal(false);
          }}
        />
      )}
      <div className="reward-detail-page">
        <MiniHeader title={t("nft:benefits:header")} />
        <div className="show-content-smoothly">
          <div className="content-wrapper">
            <h2 className={`title ${classLang}`}>{rewardInfo?.rewardName}</h2>
            <div className={`description ${classLang}`}>
              {rewardInfo?.description}
            </div>
          </div>
          <div className="content-wrapper bottom">
            <div className="benefit-type-header">
              <h3 className={classLang}>{t("nft:benefits:add:gating:type")}</h3>
              <h3 className={`${classLang} benefit-name`}>{benefitType()}</h3>
            </div>
            {rewardInfo?.category && rewardInfo.option && (
              <div
                className="benefit-type-content"
                onClick={() => handleBenefitClick(rewardInfo.option!)}
              >
                <h3 className={classLang}>
                  {t("nft:benefits:add:gating:linkType")}
                </h3>
                <div className="benefit-link-wrapper">
                  <h4>{truncateString(rewardInfo.option)}</h4>
                </div>
              </div>
            )}
          </div>

          <div className="button-wrapper">
            <Button
              theme="black"
              onClick={() => {
                setShowConfirmModal(true);
              }}
              text={t("nft:benefits:buttons:remove")}
            />
            <Button
              theme="purple"
              onClick={() => {
                navigation("edit");
              }}
              text={t("nft:benefits:buttons:edit")}
            />
          </div>
        </div>
      </div>
    </>
  );
}
export default RewardDetail;
