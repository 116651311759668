import { ComponentProps, useRef } from "react";
import { ableChargeChain } from "../../../redux/yrp/yrpTypes";
import ReactPortal from "../../portal";
import { useTranslation } from "react-i18next";
import { ReactComponent as CloseButton } from "../../../asset/svg/x-mark.svg";
import { ReactComponent as Noti } from "../../../asset/svg/noti.svg";
import check from "../../../asset/image/yrp/success-check.png";
import fail from "../../../asset/svg/fail-plane.svg";
import "./historyModal.scss";
import { useOutsideClose } from "../../../hook/useOutsideClose";
import { useLanguage } from "../../../hook/useLanguage";
import { YrpHistoryInterface, YrpType } from "YrpResponseType";
import { ellipsisMiddle } from "../../../utils/function/ellipsisMiddle";
import { formatDateToUST } from "../../../utils/function/time";

interface Props extends ComponentProps<"div"> {
  item: YrpHistoryInterface;
  isOpen: boolean;
  transition: boolean;
  startTransition: () => void;
}

const HistoryModal = ({
  item,
  isOpen,
  transition,
  startTransition,
  ...props
}: Props) => {
  const { t } = useTranslation();
  const ref = useRef<HTMLElement>(null);
  const { classLang } = useLanguage();

  useOutsideClose(ref, startTransition);

  const openTxHashExplorer = (type: YrpType) => {
    const georliExplorerUrl = "https://goerli.etherscan.io/tx";
    const sepoliaExplorerUrl = "https://sepolia.etherscan.io/tx";
    switch (type) {
      case "INPUT":
      case "OUTPUT":
        window.open(`${georliExplorerUrl}/${item.transactionHash}`);
        break;
      case "BUY":
      case "SELL":
        window.open(`${sepoliaExplorerUrl}/${item.transactionHash}`);
        break;
    }
  };

  const listing = [
    <div>
      {/* 거래번호 */}
      <h4>{t("yrp:history:modal:idx")}</h4>
      <h4>{item.id}</h4>
    </div>,
    <div>
      {/* 거래일시 */}
      <h4>{t("yrp:history:modal:timestamp")}</h4>
      <h4>{new Date(item.chargedAt).toLocaleString()}</h4>
    </div>,
    <div>
      {/* 거래상태 */}
      <h4>{t("yrp:history:modal:status")}</h4>
      <h4>
        <span style={{ color: item.isCompleted ? "#D2F586" : "#FF4848" }}>
          {item.isCompleted ? "Confirmed" : "Failed"}
        </span>
        <img src={item.isCompleted ? check : fail} alt="icon" />
      </h4>
    </div>,
    item.walletAddress && (
      <div>
        {/* 지불출처 */}
        <h4>{t("yrp:history:modal:res")}</h4>
        <h4>{ellipsisMiddle(item.walletAddress)}</h4>
      </div>
    ),
    item.stableChain && (
      <div>
        {/* 지불수단 */}
        <h4>{t("yrp:history:modal:chain")}</h4>
        <div className="chain-wrapper">
          <img
            src={
              ableChargeChain[item.stableChain as keyof typeof ableChargeChain]
                .logo
            }
            alt="chain"
          />
          <div>
            <h4>
              {
                ableChargeChain[
                  item.stableChain as keyof typeof ableChargeChain
                ].name
              }
            </h4>
            <h4>
              {
                ableChargeChain[
                  item.stableChain as keyof typeof ableChargeChain
                ].full
              }
            </h4>
          </div>
        </div>
      </div>
    ),
    item.type !== "SELL" && (
      <div>
        {/* 지불금액 */}
        <h4>{t("yrp:history:modal:input")}</h4>
        <h4>
          {item.stableChain
            ? `${item.stableAmount} ${item.stableChain}`
            : `${item.yrpAmount} YRP`}
        </h4>
      </div>
    ),
    item.stableChain && (
      <div>
        {/* 네트워크 수수료 */}
        <h4 className="noti-caption">
          <h4>{t("yrp:history:modal:fee")}</h4>
          <Noti />
        </h4>
        <h4>{item.feeAmount} ETH</h4>
      </div>
    ),
    item.type !== "BUY" && (
      <div>
        <h4>{t("yrp:history:modal:yrp")}</h4>
        <h4>
          {item.stableChain
            ? `${item.stableAmount} ${item.stableChain}`
            : `${item.yrpAmount} YRP`}
        </h4>
      </div>
    ),
  ];

  return (
    <ReactPortal wrapperId="history-item">
      <div className={`history-modal-background ${transition && "close"}`}>
        <section ref={ref} className={`history-modal ${transition && "close"}`}>
          <div className="modal-header">
            <span className={`caption ${classLang}`}>
              {t("yrp:history:modal:caption")}
            </span>
            <span onClick={startTransition}>
              <CloseButton className="close-button" />
            </span>
          </div>
          <div className="content-wrapper">
            <span
              className="tx-hash-wrapper"
              onClick={() => openTxHashExplorer(item.type)}
            >
              <span>View on explorer</span>
              <span>⎋</span>
            </span>
            {listing.map((line) => line)}
          </div>
        </section>
      </div>
    </ReactPortal>
  );
};

export default HistoryModal;
