import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import UserApi from "../../apis/UserApi";
import MiniHeader from "../../components/miniHeader/MiniHeader";
import TutorialStep from "./TutorialStep";
import magnifyingGlassImg from "../../asset/image/magnifying-glass.png";
import TutorialMoreInfo from "./TutorialMoreInfo";
import "./index.scss";
import Button from "../../components/button/Button";
import { useTranslation, Trans } from "react-i18next";
import { useLanguage } from "../../hook/useLanguage";

function WalletTutorial() {
  const { t } = useTranslation();
  const { classLang } = useLanguage();

  const navigation = useNavigate();
  const userApi = new UserApi();
  const [completedQuest, setCompletedQuest] = useState(false);

  const walletMoreInfoList = [
    {
      title: t("wallet:questions:1q"),
      description: t("wallet:questions:1a"),
      important: false,
    },
    {
      title: t("wallet:questions:2q"),
      description: t("wallet:questions:2a"),
      important: false,
    },
    {
      title: t("wallet:questions:3q"),
      description: t("wallet:questions:3a"),
      important: true,
    },
    {
      title: t("wallet:questions:4q"),
      description: t("wallet:questions:4a"),
      important: false,
    },
    {
      title: t("wallet:questions:5q"),
      description: t("wallet:questions:5a"),
      important: false,
    },
  ];

  useEffect(() => {
    const getUserCompletedQuest = async () => {
      const _completed = await userApi.getUserCompletedQuest();
      setCompletedQuest(_completed);
    };
    getUserCompletedQuest();
  }, []);

  return (
    <div className="yours-wallet-tutorial">
      <MiniHeader title={t("wallet:headers:main")} />
      <div className="show-content-smoothly">
        <pre className={`tutorial-text tutorial-text-${classLang}`}>
          {t("wallet:desc1")}
          <br />
          <br />
          <Trans i18nKey="wallet:desc2" components={{ br: <br />, b: <b /> }} />
          <br />
          <br />
          {t("wallet:desc3")}
          <br />
          <br />
          {t("wallet:desc4")}
        </pre>
        <div className="tutorial-steps-wrapper flex-column-20">
          <TutorialStep
            title="Quest.2"
            description={t("wallet:quest2:subtitle")}
            name={t("wallet:quest2:title")}
            finished={completedQuest}
            onClick={() => {
              navigation("secret");
            }}
          />
          <TutorialStep
            title="Quest.1"
            description={t("wallet:quest:subtitle")}
            name={t("wallet:quest:title")}
            finished={true}
          />
        </div>

        <h3 className={`tutorial-more-info flex-row-4 ${classLang}`}>
          {t("wallet:questions:title")}
          <img src={magnifyingGlassImg} />
        </h3>

        <div className="tutorial-more-info-container">
          {walletMoreInfoList.map((walletInfo, idx) => (
            <TutorialMoreInfo
              title={walletInfo.title}
              description={walletInfo.description}
              key={idx}
              important={walletInfo.important}
            />
          ))}
        </div>
      </div>
      {!completedQuest && (
        <Button
          theme="purple"
          sticky={true}
          onClick={() => navigation("secret")}
          text={t("wallet:buttons:question1")}
        />
      )}
    </div>
  );
}
export default WalletTutorial;
