import { useState } from "react";
import { SECTIONS } from "./Sections";
import { ExternalWalletNftRequest } from "UserResquestType";
import { useNavigate } from "react-router-dom";

const ExternalNft = () => {
  const navigation = useNavigate();
  const [stage, setStage] = useState(0);
  const [selectedItem, setSelectedItem] =
    useState<null | ExternalWalletNftRequest>(null);

  const CurStage = SECTIONS[stage];

  const handleNextStage = () => {
    if (stage === SECTIONS.length - 1) navigation("/mypage", { replace: true });
    else setStage((prev) => prev + 1);
  };

  const resetStage = () => {
    setStage(0);
  };

  return (
    <CurStage
      selectedItem={selectedItem}
      setSelectedItem={setSelectedItem}
      handleNextStage={handleNextStage}
      resetStage={resetStage}
      nftName={selectedItem?.nftName || ""}
    />
  );
};

export default ExternalNft;
