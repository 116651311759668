import { ComponentProps } from "react";
import "./buttons.scss";
import { ReactComponent as UndoIcon } from "../../asset/svg/undo.svg";

interface Props extends ComponentProps<"div"> {
  /**
   * 0 : 취소 버튼 텍스트
   * 1 : 클릭이 가능한 상태에서의 텍스트
   * 2 : 클릭이 불가능한 상태에서의 텍스트
   */
  texts: string[];
  handleClose: () => void;
  handleConfirm: () => void;
  disabled?: boolean;
}

const Buttons = ({
  texts,
  handleClose,
  handleConfirm,
  disabled,
  ...props
}: Props) => {
  return (
    <div className="buttons-component-container" {...props}>
      <button onClick={handleClose}>
        <UndoIcon />
        <h5>{texts[0]}</h5>
      </button>
      <button onClick={handleConfirm} disabled={disabled}>
        <h5>{disabled ? texts[2] : texts[1]}</h5>
      </button>
    </div>
  );
};

export default Buttons;
