import { useTranslation } from "react-i18next";
import Button from "../../../components/button/Button";
import { useLanguage } from "../../../hook/useLanguage";

type photoSettingsType = {
  option: string;
  setOption: Function;
  handlePopup: Function;
};

function PhotoSettings({ option, setOption, handlePopup }: photoSettingsType) {
  const { t } = useTranslation();
  const { classLang } = useLanguage();

  const photoMaxLength = 100;

  return (
    <>
      <div className="title-wrapper">
        <h2 className={`title ${classLang}`}>{t("making:8:request")} (4/4)</h2>
        <h4 className={`subtitle ${classLang}`}>{t("making:8:description")}</h4>
      </div>
      <div className="nft-create-info-form">
        <div className="input-box-wrapper" style={{ marginTop: -10 }}>
          {/* <label className={`input-label input-label-${classLang}`}>
            {t("making:8:caption")}
          </label> */}
          <div className="input-textarea">
            <textarea
              rows={4}
              placeholder={t("making:8:innerBox")}
              value={option}
              maxLength={photoMaxLength}
              onChange={(e) => {
                setOption(e.currentTarget.value);
              }}
            />
          </div>
          <div className="input-content-length">
            <span
              id={
                option.length >= photoMaxLength
                  ? "max"
                  : option.length
                  ? "active"
                  : ""
              }
            >
              {option.length}
            </span>
            /{photoMaxLength}
          </div>
        </div>

        <div className="input-box-wrapper">
          <div className={`input-label input-label-${classLang}`}>
            {t("making:8:content1")}
          </div>
          <div className={`photo-description-preview-wrapper`}>
            <div className={`photo-description photo-description-${classLang}`}>
              {!!option.length ? option : t("making:8:content2")}
            </div>
            <div
              className={`photo-description-warning photo-description-warning-${classLang}`}
            >
              {t("making:8:content3")}
            </div>
          </div>
        </div>
        <div className="button-wrapper" style={{ marginTop: "auto" }}>
          <Button
            theme="purple"
            onClick={() => handlePopup(t("making:popup:question"))}
            disabled={!option.length}
            text={t("making:8:bottom-button")}
          />
        </div>
      </div>
    </>
  );
}
export default PhotoSettings;
