import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import MiniHeader from "../../../components/miniHeader/MiniHeader";
import { Popup } from "../../../components/popup";
import { useNftAdminReward } from "../../../hook/useNftAdminReward";
import { setShowAlertInfo } from "../../../utils/function/showAlert";
import RewardInfoForm from "./RewardInfoForm";
import { useTranslation } from "react-i18next";
import { useReward } from "../../../hook/useReward";

function RewardEdit() {
  const { t } = useTranslation();

  const navigation = useNavigate();
  const { nftId, rewardId } = useParams();
  const { rewardInfo, editReward } = useNftAdminReward({
    nftId: Number(nftId),
    rewardId: Number(rewardId),
  });
  const {
    rewardInfoInput,
    initializeInfoInput,
    handleRewardInfo,
    selectCategory,
  } = useReward();

  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const editNftReward = async () => {
    try {
      await editReward(
        rewardInfoInput.name,
        rewardInfoInput.desc,
        rewardInfoInput.category,
        rewardInfoInput.link
      );
      setShowConfirmModal(false);
      navigation(-1);
      setShowAlertInfo(t("nft:benefits:edit:toast"), true);
    } catch (err) {
      setShowAlertInfo(t("nft:benefits:edit:fail"), false);
    }
  };

  useEffect(() => {
    initializeInfoInput("name", rewardInfo?.rewardName || "");
    initializeInfoInput("desc", rewardInfo?.description || "");
  }, [rewardInfo]);

  return (
    <>
      {showConfirmModal && (
        <Popup
          title={t("nft:benefits:edit:modal")}
          approve={() => {
            editNftReward();
          }}
          deny={() => {
            setShowConfirmModal(false);
          }}
          closeModal={() => {
            setShowConfirmModal(false);
          }}
        />
      )}
      <div>
        <MiniHeader title={t("nft:benefits:edit:header")} />
        <RewardInfoForm
          name={rewardInfoInput.name}
          description={rewardInfoInput.desc}
          link={rewardInfoInput.link || ""}
          category={rewardInfoInput.category}
          handleInfoInput={handleRewardInfo}
          selectCategory={selectCategory}
          buttonText={t("nft:benefits:buttons:editSave")}
          submitAction={() => {
            setShowConfirmModal(true);
          }}
        />
      </div>
    </>
  );
}
export default RewardEdit;
