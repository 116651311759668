import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../../components/button/Button";
import Success from "../../../components/success/Success";
import { useTranslation } from "react-i18next";
import { useLanguage } from "../../../hook/useLanguage";

type applicationSuccessProp = {
  nftInfo: any;
};

function ApplicationSuccess({ nftInfo }: applicationSuccessProp) {
  const { t } = useTranslation();
  const { classLang } = useLanguage();

  const navigation = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="success-page">
      <div className="success-page-content-wrapper">
        <h2 className={`success-page-first-title ${classLang}`}>
          {classLang === "ko" ? "" : "The "}
          <b>{nftInfo.nftName}</b>
          {classLang === "ko" ? "" : " NFT"}
          <br />
          {t("nft:auth:image:complete")}
        </h2>
        <img className="success-page-image" src={nftInfo.image} />
        <div className={`success-page-text ${classLang}`}>
          {t("nft:auth:image:waiting")}
        </div>
        <div className={`success-page-title ${classLang}`}>
          {t("nft:auth:image:waitWarn")}
        </div>
      </div>

      <Button
        theme="purple"
        text={t("nft:auth:buttons:mypage")}
        onClick={() => {
          navigation("/mypage");
        }}
        sticky={true}
      />
    </div>
  );
}
export default ApplicationSuccess;
