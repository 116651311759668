import { useEffect, useState } from "react";
import NFTApi from "../../apis/NftApi";
import { CreateOwnNftResponse } from "NftResponse";
import { useTranslation } from "react-i18next";

export type nftGetType = "create" | "own" | "reward";

type menuDataListType = {
  type: nftGetType;
  title: string;
  data: any[];
  contentLength: number;
};

type menuInfoListType = {
  type: nftGetType;
  title: string;
  empty: string;
};

export const useMypageMenu = () => {
  const { t } = useTranslation();

  const nftApi = new NFTApi();
  const menuInfoList: menuInfoListType[] = [
    {
      type: "own",
      title: t("mypage:segmented:own"),
      empty: "",
    },
    {
      type: "reward",
      title: t("mypage:segmented:benefit"),
      empty: t("mypage:empty:benefit"),
    },
    {
      type: "create",
      title: t("mypage:segmented:create"),
      empty: t("mypage:empty:create"),
    },
  ];
  const [currMenu, setCurrMenu] = useState<nftGetType>("own" as nftGetType);
  const [menuDataList, setMenuDataList] = useState<menuDataListType[]>();
  const [currMenuData, setCurrMenuData] = useState<any>();
  const [searchWord, setSearchWord] = useState<string>("");
  const [searchData, setSearchData] = useState<any[]>([]);
  const [emptyWord, setEmptyWord] = useState<string>("");

  /** 웰컴 NFT관련 세팅 함수 */
  const gettingWelcome = (welcomeId: number): CreateOwnNftResponse | null => {
    if (!menuDataList) return null;

    const own = menuDataList![0].data;

    // Search in 'own' array
    for (let i = 0; i < own.length; i++) {
      if (own[i].id === welcomeId) {
        return own[i];
      }
    }

    // If not found in 'own', return null
    return null;
  };

  useEffect(() => {
    //  현재 보여주는 메뉴 데이터 설정
    if (searchWord) {
      setCurrMenuData(searchData);
    } else {
      setCurrMenuData(
        menuDataList?.find((menu) => menu.type === currMenu)?.data
      );
      setEmptyWord(
        menuInfoList.find((menu) => menu.type === currMenu)?.empty as string
      );
    }
  }, [searchWord, searchData, currMenu, menuDataList]);

  useEffect(() => {
    // 메뉴 바뀔 때마다 검색어 초기화
    setSearchWord("");
  }, [currMenu]);

  useEffect(() => {
    const getMenuInfoList = async () => {
      let tempMenuInfo = [];
      for (let menu of menuInfoList) {
        const res = await nftApi.getMypageNftList(menu.type);

        let newMenuInfo = {
          type: menu.type as nftGetType,
          title: menu.title,
          data: res,
          contentLength: res.length,
        };
        tempMenuInfo.push(newMenuInfo);
      }
      setMenuDataList(tempMenuInfo);
    };
    getMenuInfoList();
  }, []);

  useEffect(() => {
    if (searchWord.length) {
      const getSearchResult = async () => {
        const data = await nftApi.getMypageNftSearch(
          currMenu as String,
          searchWord
        );
        setSearchData(data);
      };
      getSearchResult();
    }
  }, [searchWord]);

  return {
    menuDataList,
    currMenu,
    setCurrMenu,
    searchWord,
    setSearchWord,
    currMenuData,
    emptyWord,
    gettingWelcome,
  };
};
