import lock from "../../asset/image/lock.png";

const Locked = () => {
  return (
    <div className="lock-wrapper">
      <img src={lock} alt="locked up" />
      <span>Locked up</span>
    </div>
  );
};

export default Locked;
