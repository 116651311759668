import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeFormData } from "../../utils/function/imgInputHandler";
import { setShowAlertInfo } from "../../utils/function/showAlert";
import { useNavigate } from "react-router-dom";
import { setAuth } from "../../redux/userData/userDataAction";
import { autoHyphen } from "../../utils/function/stringToPhoneNumber";
import { Popup } from "../../components/popup";
import MiniHeader from "../../components/miniHeader/MiniHeader";
import PhoneEditModal from "./modal/PhoneEditModal";
import { ReactComponent as Camera } from "../../asset/svg/camera.svg";
import "./modal/ProfileEditModal.scss";
import "./index.scss";
import UserApi from "../../apis/UserApi";
import EmailEditModal from "./modal/EmailEditModal";
import { useTranslation } from "react-i18next";
import LanguageSelector from "./LanguageSelector";
import { useLanguage } from "../../hook/useLanguage";

function EditProfile() {
  const { t, i18n } = useTranslation();
  const { classLang } = useLanguage();

  const userApi = new UserApi();
  const navigation = useNavigate();
  const dispatch = useDispatch();
  const userData = useSelector((state: any) => state.userData);
  const [profileImgUrl, setProfileImgUrl] = useState(userData.profileImage);

  const [name, setName] = useState(userData.name);
  const [changeName, setChangeName] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState(userData.phoneNumber);
  const [email, setEmail] = useState(userData.email);

  const [showPhoneEditModal, setShowPhoneEditModal] = useState(false);
  const [showEmailEditModal, setShowEmailEditModal] = useState(false);
  const [showLogoutConfirmModal, setShowLogoutConfirmModal] = useState(false);

  useEffect(() => {
    setProfileImgUrl(userData.profileImage);
    setName(userData.name);
    setPhoneNumber(userData.phoneNumber);
    setEmail(userData.email);
  }, [userData]);

  const editName = async () => {
    // 이름 수정 api
    await userApi.editUserName(name);
    setShowAlertInfo(t("setting:profile:toast:success:nickname"), true);
    setChangeName(false);
  };

  const editProfileImage = async (e: any) => {
    try {
      // 프로필 이미지 수정 api
      const profileImgFormData = makeFormData(e, "image");
      await userApi.editUserProfileImage(profileImgFormData);
      setShowAlertInfo(t("setting:profile:toast:success:image"), true);
    } catch (err) {
      setShowAlertInfo(t("setting:profile:toast:fail:image"), false);
    }
  };

  const focusToNameInput = () => {
    setChangeName(true);
    setTimeout(() => {
      document.getElementById("nickname-input")?.focus();
    }, 100);
  };

  const logout = () => {
    dispatch(setAuth(false));
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("accessToken");
    navigation("/landing");
    setShowAlertInfo(t("setting:profile:toast:success:logout"), true);
  };

  return (
    <>
      {showLogoutConfirmModal && (
        <Popup
          title={t("setting:profile:popup:logout:content")}
          closeModal={() => {
            setShowLogoutConfirmModal(false);
          }}
          approve={() => {
            logout();
          }}
          deny={() => {
            setShowLogoutConfirmModal(false);
          }}
          approveText={t("setting:profile:popup:logout:button")}
        />
      )}
      {showPhoneEditModal && (
        <PhoneEditModal
          closeModal={() => {
            setShowPhoneEditModal(false);
          }}
        />
      )}
      {showEmailEditModal && (
        <EmailEditModal
          closeModal={() => {
            setShowEmailEditModal(false);
          }}
        />
      )}
      <div className="profile-edit-page show-content-smoothly">
        <MiniHeader title={t("setting:profile:header")} />
        <div className="profile-edit-content-wrapper">
          <div className="input-box-wrapper profile-image-wrapper">
            <input
              id="profile-image-input"
              className="input-image"
              type="file"
              accept="image/*"
              onChange={(e) => {
                editProfileImage(e);
              }}
            />
            <label htmlFor="profile-image-input" className="input-image">
              <div
                className="badge-image-input-button"
                id={profileImgUrl ? "image-uploaded" : ""}
              >
                <Camera />
              </div>
              {profileImgUrl && <img src={profileImgUrl} />}
            </label>
          </div>

          <div className="profile-input-wrapper">
            <div className={`profile-input-elem`}>
              <div
                className={`input-title ${i18n.language !== "ko" ? "en" : ""}`}
              >
                <h4 className={classLang}>
                  {t("setting:profile:caption:nickname")}
                </h4>
              </div>
              <input
                className={`input`}
                id="nickname-input"
                type="text"
                placeholder={t("setting:profile:placeholder:nickname")}
                value={name}
                disabled={!changeName}
                onChange={(e) => {
                  setName(e.target.value);
                }}
              />
              {changeName ? (
                <button
                  className="save-edit"
                  onClick={() => {
                    editName();
                  }}
                  disabled={!changeName}
                >
                  <h5 className={classLang}>
                    {t("setting:profile:buttons:save")}
                  </h5>
                </button>
              ) : (
                <button
                  onClick={() => {
                    focusToNameInput();
                  }}
                >
                  <h5 className={classLang}>
                    {t("setting:profile:buttons:change")}
                  </h5>
                </button>
              )}
            </div>

            <div className="profile-input-elem">
              <div
                className={`input-title ${i18n.language !== "ko" ? "en" : ""}`}
              >
                <h4 className={classLang}>
                  {t("setting:profile:caption:number")}
                </h4>
              </div>
              <div className={`input`}>{autoHyphen(phoneNumber)}</div>
              <button
                onClick={() => {
                  setShowPhoneEditModal(true);
                }}
              >
                <h5 className={classLang}>
                  {t("setting:profile:buttons:change")}
                </h5>
              </button>
            </div>

            <div className="profile-input-elem">
              <div
                className={`input-title ${i18n.language !== "ko" ? "en" : ""}`}
              >
                <h4 className={classLang}>
                  {t("setting:profile:caption:mail")}
                </h4>
              </div>
              <div className={`input`}>{email}</div>
              <button
                onClick={() => {
                  setShowEmailEditModal(true);
                }}
              >
                <h5 className={classLang}>
                  {t("setting:profile:buttons:change")}
                </h5>
              </button>
            </div>

            <div className="profile-input-elem">
              <div
                className={`input-title ${i18n.language !== "ko" ? "en" : ""}`}
              >
                <h4 className={classLang}>
                  {t("setting:profile:caption:lang")}
                </h4>
              </div>
              <LanguageSelector />
            </div>
          </div>

          <button
            className="logout"
            onClick={() => {
              setShowLogoutConfirmModal(true);
            }}
          >
            <h4 className={classLang}>{t("setting:profile:caption:logout")}</h4>
          </button>
        </div>
      </div>
    </>
  );
}
export default EditProfile;
