import { YrpHistoryInterface } from "YrpResponseType";
import { ACTION_TYPES, HavingToken, YrpStateType } from "./yrpTypes";
import clonedeep from "lodash/cloneDeep";

const dummyHistory: YrpHistoryInterface[] = [
  // {
  //   id: 0,
  //   userId: 0,
  //   type: "INPUT",
  //   isCompleted: true,
  //   chargedAt: "2023-08-28T12:00:00Z",
  //   transactionHash: "hash1",
  //   walletAddress: "0x1234",
  //   stableChain: "USDC",
  //   stableAmount: 1000,
  //   yrpAmount: 990,
  //   feeAmount: 10,
  // },
  // {
  //   id: 1,
  //   userId: 1,
  //   type: "INPUT",
  //   isCompleted: false,
  //   chargedAt: "2023-08-28T12:00:00Z",
  //   transactionHash: "hash1",
  //   walletAddress: "0x1234",
  //   stableChain: "USDC",
  //   stableAmount: 1000,
  //   yrpAmount: 990,
  //   feeAmount: 10,
  // },
];

const dummyHavingToken: HavingToken = {
  USDC: 0,
  USDC_T: 0,
  USDT: 0,
  APT: 0,
};

const initialState: YrpStateType = {
  totalAmount: 0,
  inputAmount: 0,
  selectedChain: "USDC",
  histories: dummyHistory,
  havingToken: dummyHavingToken,
  fee: 0,
};

export const yrpReducer = (state = initialState, action: any) => {
  let resultState: any = clonedeep(state);

  switch (action.type) {
    case ACTION_TYPES.SET_TOTAL_AMOUNT:
      resultState.totalAmount = action.data;
      break;
    case ACTION_TYPES.SET_INPUT_AMOUNT:
      resultState.inputAmount = action.data;
      break;
    case ACTION_TYPES.SET_SELECT_CHAIN:
      resultState.selectedChain = action.data;
      break;
    case ACTION_TYPES.SET_HAVING_TOKEN:
      resultState.havingToken = action.data;
      break;
    case ACTION_TYPES.SET_TX_FEE:
      resultState.fee = action.data;
      break;
    case ACTION_TYPES.SET_HISTORIES:
      resultState.histories = action.data;
      break;
    default:
  }

  return resultState;
};
