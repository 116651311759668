import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  setAmount,
  setHavingToken,
  setHistories,
  setInputAmount,
  setSelectChain,
  setTxFee,
} from "../redux/yrp/yrpAction";
import { ableChargeChain } from "../redux/yrp/yrpTypes";
import { useTranslation } from "react-i18next";
import { Contract, ethers } from "ethers";
import Web3 from "web3";
import axios from "axios";
import YrpApi from "../apis/YrpApi";
import { ChargeYrpInterface } from "YrpResponseType";
import { setShowAlertInfo } from "../utils/function/showAlert";

/** 코인별 YRP 충전 비율 (1 YRP당 몇 개의 COIN이 필요한지) */
export const RATION_COIN_PER_YRP = {
  USDC_T: 1,
  USDC: 1,
  USDT: 1,
  DAI: 1,
  BUSD: 1,
  TUSD: 1,
  APT: 1,
};

/** 입력 가능한 최소 최대 수량 */
export const MINIMUM = 0.1;
export const MAXIMUM = 10000000;

/** YOURS Wallet 관련 리터럴 */
const GWEI_NETWORK = process.env.REACT_APP_GWEI_NETWORK;
const ETH_NETWORK = process.env.REACT_APP_ETH_NETWORK;

const USDC_CONTRACT_ADDRESS = process.env.REACT_APP_USDC_CONTRACT_ADDRESS;
const USDC_CONTRACT_ADDRESS_MAIN =
  process.env.REACT_APP_USDC_CONTRACT_ADDRESS_MAIN;

const GEORLI_PROVIDER = process.env.REACT_APP_GEORLI_PROVIDER;
const SEPOLIA_PROVIDER = process.env.REACT_APP_SEPOLIA_PROVIDER;
const ETHEREUM_PROVIDER = process.env.REACT_APP_ETHEREUM_PROVIDER;

const ETHERSCAN_API_URL = process.env.REACT_APP_ETHERSCAN_API_URL;
const ETHERSCAN_API_KEY = process.env.REACT_APP_ETHERSCAN_API_KEY;

const ABI = [
  // ERC20 표준의 balanceOf 메서드
  "function balanceOf(address owner) view returns (uint256)",
  // ERC20 표준의 transfer 메서드
  "function transfer(address to, uint256 value) external returns (bool)",
];

// Ethereum 네트워크에 연결
const web3_testnet = new Web3(GWEI_NETWORK!);
const web3_mainnet = new Web3(ETH_NETWORK!);

export const useYrp = () => {
  const naviation = useNavigate();
  const yrpInfo = useSelector((state: any) => state.yrp);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const wallets = useSelector((state: any) => state.wallet);

  const yrpApi = new YrpApi();

  const EVM_WALLET_ADDR = wallets.Ethereum; //사용자 지갑 (EVM)
  const APTOS_WALLET_ADDR = wallets.Aptos; //사용자 지갑 (Aptos)

  /**---- provider ----**/
  const provider_sepolia = new ethers.providers.JsonRpcProvider(
    SEPOLIA_PROVIDER
  );
  const provider_georli = new ethers.providers.JsonRpcProvider(GEORLI_PROVIDER);
  const provider_ethereum = new ethers.providers.JsonRpcProvider(
    ETHEREUM_PROVIDER
  );

  /**---- state ----**/
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [fee, setFee] = useState("");
  const [success, setSuccess] = useState(false);

  /**---- router ----**/
  const goYrpPage = () => {
    naviation("/yrp");
  };

  const goToCharge = () => {
    naviation("/yrp/charge");
  };

  /**---- util ----**/
  const dollarCoverter = (yrp: number): number => {
    return yrp * 1;
  };

  /**---- modal ----**/
  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleFlexible = () => {
    setIsOpen((prev) => !prev);
  };

  /**---- redux ----**/
  const handleInputAmount = (e: React.ChangeEvent<HTMLInputElement>) => {
    const rawValue = e.target.value
      .replace(/,/g, "")
      .replace(/^0+([0-9]+)/, "$1");

    if (rawValue.length === 0 || rawValue === "0" || rawValue === "0.") {
      dispatch(setInputAmount(rawValue));
      return;
    }

    // 소수점 아래 최대 4자리까지만 허용하는 정규 표현식
    const regex = /^[0-9]*(\.[0-9]{0,4})?$/;

    if (regex.test(rawValue)) dispatch(setInputAmount(rawValue));
  };

  const initializeInput = () => {
    dispatch(setInputAmount(0));
  };

  const initializeValues = () => {
    dispatch(setSelectChain("USDC"));
    dispatch(setInputAmount(0));
  };

  const valueOfCoinByInputYrp = (
    inputAmount: number | string,
    chain: keyof typeof ableChargeChain
  ): number => {
    return Number(inputAmount) * RATION_COIN_PER_YRP[chain];
  };

  const restCoinByInputYrp = (
    input: number,
    chain: keyof typeof ableChargeChain
  ): string => {
    const rest =
      yrpInfo.havingToken[chain] - valueOfCoinByInputYrp(input, chain);

    if (input > MAXIMUM) return t("yrp:charge:restMax");

    return rest < 0
      ? t("yrp:charge:restWarn")
      : `${t("yrp:charge:rest")} ${Number(rest).toLocaleString()} ${
          ableChargeChain[chain].name
        }`;
  };

  const isSufficient = (input: number, chain: keyof typeof ableChargeChain) => {
    const rest =
      yrpInfo.havingToken[chain] - valueOfCoinByInputYrp(input, chain);

    return rest >= 0 && input <= MAXIMUM;
  };

  const selectChain = (chain: string) => {
    dispatch(setSelectChain(chain));
    initializeInput();
  };

  /**---- Contract ----**/

  /** USDT 잔액 가져오기 */
  const getUsdtMaximum = async () => {
    try {
      const USDT_CONTRACT_ADDRESS = process.env.REACT_APP_USDT_CONTRACT_ADDRESS;
      const usdtContract = new ethers.Contract(
        USDT_CONTRACT_ADDRESS!,
        ABI,
        provider_sepolia
      );
      const usdtBalance = await usdtContract.balanceOf(EVM_WALLET_ADDR);
      const usdtBalance2 = parseFloat(ethers.utils.formatUnits(usdtBalance, 6));
      return usdtBalance2;
    } catch (err) {
      console.log(err);
      return 0;
    }
  };

  const getUsdcMaximumTestnet = async () => {
    try {
      const USDC_CONTRACT_ADDRESS = process.env.REACT_APP_USDC_CONTRACT_ADDRESS;
      const usdcContract = new ethers.Contract(
        USDC_CONTRACT_ADDRESS!,
        ABI,
        provider_georli
      );
      const usdcBalance = await usdcContract.balanceOf(EVM_WALLET_ADDR);
      const usdcBalance2 = parseFloat(ethers.utils.formatUnits(usdcBalance, 6));
      return usdcBalance2;
    } catch (err) {
      console.log(err);
      return 0;
    }
  };

  const getUsdcMaximumMainnet = async () => {
    try {
      const USDC_CONTRACT_ADDRESS =
        process.env.REACT_APP_USDC_CONTRACT_ADDRESS_MAIN;
      const usdcContract = new ethers.Contract(
        USDC_CONTRACT_ADDRESS!,
        ABI,
        provider_ethereum
      );
      const usdcBalance = await usdcContract.balanceOf(EVM_WALLET_ADDR);
      const usdcBalance2 = parseFloat(ethers.utils.formatUnits(usdcBalance, 6));
      return usdcBalance2;
    } catch (err) {
      console.log(err);
      return 0;
    }
  };

  /** 충전할 YRP 수량, 지불할 화폐를 선택하고 지불이 가능한지 체크하는 함수 */
  const checkBalances = async () => {
    setIsLoading(true);
    const USDT = await getUsdtMaximum();
    const USDC = await getUsdcMaximumMainnet();
    const tUSDC = await getUsdcMaximumTestnet();

    setIsLoading(false);
    dispatch(
      setHavingToken({
        ...yrpInfo.havingToken,
        USDC_T: tUSDC,
        USDC: USDC,
        USDT,
      })
    );
  };

  /** 트랜잭션 비용 추정 함수 */
  const guessTransactionFee = async (
    from: string,
    to: string,
    amount: string,
    chain: keyof typeof ableChargeChain
  ) => {
    switch (chain) {
      case "USDC":
        await guessTransactionFeeMainnet(from, to, amount);
        break;
      case "USDC_T":
        await guessTransactionFeeTestnet(from, to, amount);
        break;
      default:
        break;
    }
  };

  const guessTransactionFeeTestnet = async (
    from: string,
    to: string,
    amount: string
  ) => {
    setIsLoading(true);

    const amountInWei = web3_testnet.utils.toWei(amount, "mwei");

    const transferEncoded = web3_testnet.eth.abi.encodeFunctionCall(
      {
        name: "transfer",
        type: "function",
        inputs: [
          {
            type: "address",
            name: "recipient",
          },
          {
            type: "uint256",
            name: "amount",
          },
        ],
      },
      [to, amountInWei]
    );

    const currentBlock = await web3_testnet.eth.getBlock("latest");
    const baseFeePerGas = web3_testnet.utils.toBN(currentBlock.baseFeePerGas!);
    const maxPriorityFeePerGas = web3_testnet.utils.toBN(
      web3_testnet.utils.toWei("1.5", "gwei")
    );

    try {
      const gasLimit = await web3_testnet.eth.estimateGas({
        from: from,
        to: USDC_CONTRACT_ADDRESS,
        data: transferEncoded,
      });

      const totalFeeWei = baseFeePerGas
        .add(maxPriorityFeePerGas)
        .mul(web3_testnet.utils.toBN(gasLimit));
      const totalFee = web3_testnet.utils.fromWei(totalFeeWei, "ether");

      setIsLoading(false);
      setFee(totalFee);
    } catch (err) {
      console.log(err);
    }
  };

  const guessTransactionFeeMainnet = async (
    from: string,
    to: string,
    amount: string
  ) => {
    setIsLoading(true);

    const amountInWei = web3_mainnet.utils.toWei(amount, "mwei");
    const transferEncoded = web3_mainnet.eth.abi.encodeFunctionCall(
      {
        name: "transfer",
        type: "function",
        inputs: [
          {
            type: "address",
            name: "recipient",
          },
          {
            type: "uint256",
            name: "amount",
          },
        ],
      },
      [to, amountInWei]
    );
    const currentBlock = await web3_mainnet.eth.getBlock("latest");
    const baseFeePerGas = web3_mainnet.utils.toBN(currentBlock.baseFeePerGas!);
    const currentGasPrice = await web3_mainnet.eth.getGasPrice();
    const currentGasPriceInGwei = web3_mainnet.utils.fromWei(
      currentGasPrice,
      "gwei"
    );
    const priorityFeeInGwei =
      parseFloat(currentGasPriceInGwei) -
      parseFloat(web3_mainnet.utils.fromWei(baseFeePerGas, "gwei"));
    const roundedPriorityFeeInGwei = Number(
      priorityFeeInGwei.toFixed(10)
    ).toLocaleString("fullwide", { useGrouping: false });
    const maxPriorityFeePerGas = web3_mainnet.utils.toBN(
      web3_mainnet.utils.toWei(roundedPriorityFeeInGwei.toString(), "gwei")
    );

    try {
      const gasLimit = await web3_mainnet.eth.estimateGas({
        from: from,
        to: USDC_CONTRACT_ADDRESS_MAIN,
        data: transferEncoded,
      });
      const totalFeeWei = baseFeePerGas
        .add(maxPriorityFeePerGas)
        .mul(web3_mainnet.utils.toBN(gasLimit));
      const totalFee = web3_mainnet.utils.fromWei(totalFeeWei, "ether");

      setIsLoading(false);
      setFee(totalFee);
    } catch (err) {
      console.log(err);
    }
  };

  const sendUSDC = async (
    privateKey: string,
    toAddress: string,
    amountInUSDC: string,
    chain: keyof typeof ableChargeChain
  ): Promise<string> => {
    switch (chain) {
      case "USDC":
        return await sendUSDCMainnet(privateKey, toAddress, amountInUSDC);
      case "USDC_T":
        return await sendUSDCTestnet(privateKey, toAddress, amountInUSDC);
      default:
        setShowAlertInfo("지원하지 않는 네트워크입니다.", false);
        throw new Error("Empty user info");
    }
  };

  const sendUSDCTestnet = async (
    privateKey: string,
    toAddress: string,
    amountInUSDC: string
  ): Promise<string> => {
    const wallet = new ethers.Wallet(
      privateKey.replace(/^0x/, ""),
      provider_georli
    );
    const usdcAbi = [
      "function transfer(address to, uint256 value) public returns (bool)",
    ];
    const usdcContract = new Contract(USDC_CONTRACT_ADDRESS!, usdcAbi, wallet);

    const amountInUnits = ethers.utils.parseUnits(amountInUSDC, 6);

    const txResponse = await usdcContract.transfer(toAddress, amountInUnits);
    console.log("Transaction hash:", txResponse.hash);
    return txResponse.hash;
  };

  const sendUSDCMainnet = async (
    privateKey: string,
    toAddress: string,
    amountInUSDC: string
  ): Promise<string> => {
    const wallet = new ethers.Wallet(
      privateKey.replace(/^0x/, ""),
      provider_ethereum
    );
    const usdcAbi = [
      "function transfer(address to, uint256 value) public returns (bool)",
    ];
    const usdcContract = new Contract(
      USDC_CONTRACT_ADDRESS_MAIN!,
      usdcAbi,
      wallet
    );

    const amountInUnits = ethers.utils.parseUnits(amountInUSDC, 6);

    const txResponse = await usdcContract.transfer(toAddress, amountInUnits);
    console.log("Transaction hash:", txResponse.hash);
    return txResponse.hash;
  };

  /** 트랜잭션 성공 여부 확인 (interval) */
  const checkTxSuccess = async (txHash: string): Promise<boolean> => {
    try {
      const response = await axios.get(ETHERSCAN_API_URL!, {
        params: {
          module: "transaction",
          action: "gettxreceiptstatus",
          txhash: txHash,
          apikey: ETHERSCAN_API_KEY,
        },
      });

      const data = response.data;

      // 트랜잭션 성공인지 확인하는 시점
      if (data.status === "1" && data.result) {
        return true;
      } else {
        console.error(
          "Error fetching transaction receipt from Etherscan:",
          data.message
        );
        return false;
      }
    } catch (error) {
      console.error("An error occurred:", error);
      return false;
    }
  };

  const MAX_RETRIES = 20; // 1분 동안 3초 간격으로 요청할 경우 최대 시도 횟수는 20회
  const RETRY_INTERVAL = 3000; // 3초

  /** 트랜잭션 fee 받아오기 */
  const getTransactionFeeFromEtherscan = async (
    txHash: string,
    retries: number = 0
  ): Promise<string> => {
    setIsLoading(true);
    try {
      const transactionDetails = await axios.get(ETHERSCAN_API_URL!, {
        params: {
          module: "proxy",
          action: "eth_getTransactionReceipt",
          txhash: txHash,
          apikey: ETHERSCAN_API_KEY,
        },
      });

      if (!transactionDetails.data || transactionDetails.data.result == null) {
        // 데이터가 null인 경우 다시 시도
        if (retries < MAX_RETRIES) {
          await new Promise((res) => setTimeout(res, RETRY_INTERVAL)); // 3초 대기
          return getTransactionFeeFromEtherscan(txHash, retries + 1);
        } else {
          console.error("Maximum retries reached, no valid data received");
          setIsLoading(false);
          return "";
        }
      }

      const effectiveGasPrice = ethers.BigNumber.from(
        transactionDetails.data.result.effectiveGasPrice
      );
      const gasUsed = ethers.BigNumber.from(
        transactionDetails.data.result.gasUsed
      );
      const transactionFee = gasUsed.mul(effectiveGasPrice);

      setIsLoading(false);
      dispatch(setTxFee(Number(ethers.utils.formatEther(transactionFee))));
      return ethers.utils.formatEther(transactionFee);
    } catch (error) {
      console.error("An error occurred:", error);
      setIsLoading(false);
      return "";
    }
  };

  const handleTxSuccess = (isSuccess: boolean) => {
    setSuccess(isSuccess);
  };

  /**----- API Control -----**/
  const chargeYrp = async (
    walletType: "EVM" | "APTOS",
    tx: string,
    fee: string,
    success: boolean
  ) => {
    const selectedChain = yrpInfo.selectedChain;
    const inputAmount = yrpInfo.inputAmount;

    const config: ChargeYrpInterface = {
      type: "INPUT",
      isCompleted: success,
      chargedAt: new Date(),
      walletAddress: walletType === "EVM" ? EVM_WALLET_ADDR : APTOS_WALLET_ADDR,
      stableChain: selectedChain,
      stableAmount: Number(inputAmount),
      yrpAmount:
        yrpInfo.inputAmount *
        RATION_COIN_PER_YRP[selectedChain as keyof typeof ableChargeChain],
      feeAmount: Number(fee),
      transactionHash: tx,
    };

    try {
      const data = await yrpApi.postChargeYrp(config);
      console.log(data);
    } catch (err) {
      console.log(err);
    }
  };

  const getHistory = async () => {
    setIsLoading(true);
    try {
      const data = await yrpApi.getYrpHistory();
      dispatch(setHistories(data));
      setIsLoading(false);
    } catch (err) {
      console.log(err);
      setIsLoading(false);
    }
  };

  const getTotalAmount = async () => {
    setIsLoading(true);
    try {
      const data = await yrpApi.getTotalAmount();
      dispatch(setAmount(Number(data)));
      setIsLoading(false);
    } catch (err) {
      console.log(err);
      setIsLoading(false);
    }
  };

  return {
    totalAmount: yrpInfo.totalAmount,
    histories: yrpInfo.histories,
    selectedChain: yrpInfo.selectedChain,
    inputAmount: yrpInfo.inputAmount,
    havingToken: yrpInfo.HavingToken,
    txFee: yrpInfo.fee,
    goYrpPage,
    goToCharge,
    dollarCoverter,
    isOpen,
    handleOpen,
    handleClose,
    handleFlexible,
    handleInputAmount,
    initializeInput,
    initializeValues,
    valueOfCoinByInputYrp,
    restCoinByInputYrp,
    selectChain,
    checkBalances,
    isLoading,
    fee,
    guessTransactionFee,
    sendUSDC,
    checkTxSuccess,
    getTransactionFeeFromEtherscan,
    success,
    handleTxSuccess,
    EVM_WALLET_ADDR,
    APTOS_WALLET_ADDR,
    chargeYrp,
    getHistory,
    getTotalAmount,
    isSufficient,
  };
};
