import clonedeep from "lodash/cloneDeep";
import { ACTION_TYPES, ExternalWalletInfo } from "./externalTypes";
import ethereumIcon from "../../asset/image/chain/Ethereum.png";
import polygonIcon from "../../asset/image/chain/Polygon.png";
import auroraIcon from "../../asset/image/chain/Aurora.png";
import klaytnIcon from "../../asset/image/chain/Klaytn.png";
import aptosIcon from "../../asset/image/chain/Aptos.png";
import oasysIcon from "../../asset/image/chain/Oasys.png";
import xplaIcon from "../../asset/image/chain/Xpla.png";
import { ExternalChainInfo } from "ChainType";

type ChainCategory = {
  [chainName: string]: ExternalChainInfo;
};

export type InnerChainsType = {
  evm: ChainCategory;
  aptos: ChainCategory;
};

export const INNER_CHAINS: InnerChainsType = {
  evm: {
    ethereum: {
      name: "Ethereum",
      full: "Ethereum",
      icon: ethereumIcon,
      key: "0xaa36a7",
    },
    polygon: {
      name: "Polygon",
      full: "Polygon",
      icon: polygonIcon,
      key: "0x89",
    },
    klaytn: {
      name: "Klaytn",
      full: "Klaytn",
      icon: klaytnIcon,
      key: "0x2019",
    },
    aurora: {
      name: "Aurora",
      full: "Aurora",
      icon: auroraIcon,
      key: "0x4e454152",
    },
    xpla: {
      name: "XPLA",
      full: "XPLA",
      icon: xplaIcon,
      key: "0x25",
    },
    oasys: {
      name: "Oasys",
      full: "Oasys",
      icon: oasysIcon,
      key: "0xf8",
    },
  },
  aptos: {
    aptos: {
      name: "Aptos",
      full: "Aptos",
      icon: aptosIcon,
      key: "",
    },
  },
};

const initialState: ExternalWalletInfo = {
  chain: "evm",
  address: "",
  isConnected: false,
  innerChainInfo: INNER_CHAINS.evm.ethereum,
};

export const externalWalletReducer = (state = initialState, action: any) => {
  let resultState = clonedeep(state);

  switch (action.type) {
    case ACTION_TYPES.SET_EXTENRAL_ADDRESS:
      resultState.address = action.data;
      break;
    case ACTION_TYPES.SET_EXTERNAL_CHAIN:
      resultState.chain = action.data;
      break;
    case ACTION_TYPES.SET_EXTERNAL_CONNECTED:
      resultState.isConnected = action.data;
      break;
    case ACTION_TYPES.SET_EXTERNAL_SIGNER:
      resultState.signer = action.data;
      break;
    case ACTION_TYPES.SET_EXTERNAL_INNER_CHAIN_INFO:
      resultState.innerChainInfo = action.data;
      break;
    default:
  }

  return resultState;
};
