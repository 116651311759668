import { copyText } from "../../utils/function/linkShare";
import { ReactComponent as CopyIcon } from "../../asset/svg/copy-text.svg";
import "./CopyBox.scss";
import { useLanguage } from "../../hook/useLanguage";

type copyBoxProps = {
  /**
   * Text to copy
   */
  text: string;
  /**
   * Notification text to show when text is copied
   */
  copyNotificationText: string;
};

/**
 * Box element to copy text to clipboard
 */
function CopyBox({ text, copyNotificationText }: copyBoxProps) {
  const { classLang } = useLanguage();

  return (
    <div className={`copy-box-wrapper ${classLang}`}>
      {text}
      <button
        className="copy-button"
        onClick={() => {
          copyText(text, copyNotificationText);
        }}
        onTouchEnd={() => {
          copyText(text, copyNotificationText);
        }}
      >
        <CopyIcon />
      </button>
    </div>
  );
}
export default CopyBox;
