import ReactGA from "react-ga4";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const GAProvider = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    ReactGA.initialize(`${process.env.REACT_APP_GA_ID}`);
  }, []);

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: pathname,
    });
  }, [pathname]);

  return <></>;
};

export default GAProvider;
