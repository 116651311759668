import { useState, useEffect } from "react";

/** transition이 true가 되면 닫는 애니메이션 실행 */
export const useTransition = (delay = 1000, initial = false) => {
  const [transition, setTransition] = useState(false);
  const [isOpen, setIsOpen] = useState(initial);
  const [rotate, setRotate] = useState(initial);
  let timeoutId: NodeJS.Timeout | null = null;

  const handleOpen = () => {
    setIsOpen(true);
    setRotate(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const startTransition = () => {
    setRotate(false);
    setTransition(true);
    timeoutId = setTimeout(() => {
      setTransition(false);
      handleClose();
    }, delay);
  };

  useEffect(() => {
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, []);

  return {
    handleOpen,
    isOpen,
    rotate,
    transition,
    startTransition,
  };
};
