import { useTranslation } from "react-i18next";
import MiniHeader from "../../../components/miniHeader/MiniHeader";
import "./charge.scss";
import { useYrp } from "../../../hook/useYrp";
import Button from "../../../components/button/Button";
import { useState } from "react";
import AmountInputSection from "./AmountInputSection";
import { MINIMUM } from "../../../hook/useYrp";
import Confirm from "./Confirm";
import Passcode from "./Passcode";
import { usePasscodeCheck } from "../../../hook/usePasscodeCheck";
import Loading from "./Loading";
import Result from "./Result";
import { useSelector } from "react-redux";
import { decryptMnemonic } from "../../../utils/function/crypto";
import { generateWalletsFromMnemonic } from "../../../utils/function/wallet";
import { useNavigate } from "react-router-dom";

const SECTIONS = [AmountInputSection, Confirm, Passcode, Loading, Result];

const YOURS_MAIN_WALLET = process.env.REACT_APP_YOURS_MAIN_WALLET;

const YrpCharge = () => {
  const { t } = useTranslation();
  const secret = useSelector((state: any) => state.userData.secret);
  const {
    inputAmount,
    sendUSDC,
    success,
    handleTxSuccess,
    isSufficient,
    selectedChain,
  } = useYrp();
  const {
    inputPasscode,
    handleInputPasscode,
    isValid,
    confirmPassCodeHandler,
  } = usePasscodeCheck();
  const navigation = useNavigate();

  const [tx, setTx] = useState("");
  const [stage, setStage] = useState(0);

  const CurComponent = SECTIONS[stage];

  const handleChargePoint = async () => {
    try {
      setStage((prev) => prev + 1);
      const _mnemonic = await decryptMnemonic(secret, inputPasscode);
      const wallets = generateWalletsFromMnemonic(_mnemonic);
      const prKey = wallets["Ethereum"].privateKey;
      const txData = await sendUSDC(
        prKey,
        YOURS_MAIN_WALLET!,
        inputAmount,
        selectedChain
      );
      setTx(txData);
    } catch (err) {
      console.log(err);
    }
  };

  const handleNextStage = async (success = false) => {
    switch (stage) {
      case 0: //입력 스테이지
      case 1: //Confirm 스테이지
        setStage((prev) => prev + 1);
        break;
      case 2: //Passcode 확인 스테이지
        confirmPassCodeHandler(handleChargePoint);
        break;
      case 3: //로딩 스테이지
        handleTxSuccess(success);
        setStage((prev) => prev + 1);
        break;
      case 4: //결과 스테이지
        navigation("/yrp", { replace: true });
        break;
    }
  };

  return (
    <>
      {stage !== 3 && (
        <MiniHeader
          title={
            stage === 2 ? t("yrp:header:passcode") : t("yrp:header:charge")
          }
        />
      )}
      <CurComponent
        inputPasscode={inputPasscode}
        handleInputPasscode={handleInputPasscode}
        isValid={isValid}
        handleNextStage={handleNextStage}
        tx={tx}
        success={success}
      />
      {stage !== 3 && (
        <Button
          text={
            stage === 1
              ? t("yrp:charge:buttons:complete")
              : stage === SECTIONS.length - 1
              ? t("yrp:charge:buttons:result")
              : t("yrp:charge:buttons:next")
          }
          theme="purple"
          disabled={
            stage !== 2
              ? inputAmount < MINIMUM ||
                !isSufficient(inputAmount, selectedChain)
              : inputPasscode === ""
          }
          onClick={handleNextStage}
        />
      )}
    </>
  );
};

export default YrpCharge;
