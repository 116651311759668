import { ComponentProps } from "react";
import { ReactComponent as CheckIcon } from "../../asset/svg/check-circle.svg";
import { LanguageList } from "../../utils/data/language";
import "./LangForm.scss";
import Button from "../../components/button/Button";
import { useTranslation } from "react-i18next";

interface Props extends ComponentProps<"input"> {
  check: (lang: string) => boolean;
  change: (lang: string) => void;
  goToNextPage: () => void;
  isCheck: boolean;
}

/** 언어 선택 폼 */
const LangForm = ({ goToNextPage, isCheck, ...props }: Props) => {
  const { t } = useTranslation();

  return (
    <div className="user-signup-language">
      <div className="language-title">
        <h3 className="eng">Choose the language familiar to you.</h3>
        <h2>어떤 언어를 사용할까요?</h2>
      </div>
      <div className="language-buttons">
        {LanguageList.map((lang) => (
          <LanguageButton nation={lang.langName} flag={lang.flag} {...props} />
        ))}
      </div>

      {isCheck ? (
        <Button
          className="signup-input-submit-button"
          theme="purple"
          onClick={() => {
            goToNextPage();
          }}
          text={t("login:button:next")}
          type="submit"
        />
      ) : (
        <></>
      )}
    </div>
  );
};

interface LangProps {
  nation: string;
  flag: string;
  check: (lang: string) => boolean;
  change: (lang: string) => void;
}

const LanguageButton = ({
  check,
  change,
  nation,
  flag,
  ...props
}: LangProps) => {
  const isKorean = (inputNation: string) => {
    return inputNation === "한국어";
  };

  return (
    <label
      className={`language-button-wrapper ${
        check(nation) ? "languag-button-wrapper-checked" : ""
      }`}
      htmlFor={"term-of-use-" + nation}
    >
      <span className="language-button-info">
        <img src={flag} />
        <h5 className={isKorean(nation) ? "" : "eng"}>{nation}</h5>
      </span>
      <span>
        <input
          type="checkbox"
          id={"term-of-use-" + nation}
          checked={check(nation)}
          onChange={() => change(nation)}
          {...props}
        />
        <label className="term-of-use" htmlFor={"term-of-use-" + nation}>
          <CheckIcon
            className="check-icon"
            id={check(nation) ? "checked" : ""}
          />
        </label>
      </span>
    </label>
  );
};

export default LangForm;
