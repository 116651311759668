import { useEffect, useState } from "react";
import NFTApi from "../apis/NftApi";

/**
 * NFT 디테일 페이지에서 사용되는 훅
 * 현재 보고있는 NFT가 락 된 NFT인지 확인한다.
 */
export const useLock = (id: string, userOwnThisNft: boolean) => {
  const nftApi = new NFTApi();

  const [isLocked, setIsLocked] = useState(false);

  const settingLock = async () => {
    const res = await nftApi.getMypageNftList("own");

    res.forEach((item) => {
      if ("id" in item && "isLocked" in item && item.id === Number(id)) {
        setIsLocked(item.isLocked);
      }
    });
  };

  useEffect(() => {
    if (userOwnThisNft) settingLock();
    setIsLocked(false);
  }, [userOwnThisNft]);

  return { isLocked };
};
