import { Language } from "LanguageType";
import KR from "../../asset/image/flag/KR.png";
import US from "../../asset/image/flag/US.png";

export const LanguageList: Language[] = [
  {
    langType: "en",
    nation: "EN",
    langName: "English",
    flag: US,
  },
  {
    langType: "ko",
    nation: "KOR",
    langName: "한국어",
    flag: KR,
  },
];
