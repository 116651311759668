import { NftInfo } from "NftType";
import { useNavigate } from "react-router-dom";
import chainList from "../../../utils/data/chainList";
import MiniHeader from "../../../components/miniHeader/MiniHeader";
import DeployRewardElem from "../../../components/reward/DeployRewardElem";
import Button from "../../../components/button/Button";
import { useTranslation } from "react-i18next";
import { useLanguage } from "../../../hook/useLanguage";

type confirmNftInfoProp = {
  nftInfo: NftInfo;
  deployNft: Function;
  rewardList: any;
};

function ConfirmNftInfo({
  nftInfo,
  deployNft,
  rewardList,
}: confirmNftInfoProp) {
  const { t } = useTranslation();
  const { classLang } = useLanguage();

  const navigation = useNavigate();

  return (
    <div className="confirm-nft-info">
      <MiniHeader title={t("nft:benefits:confirm:header")} />
      {nftInfo && (
        <div className="nft-info-container flex-column-20">
          <div className="nft-info-title">
            <h3 className="title">{t("nft:benefits:confirm:infoCaption")}</h3>
            <h6 className="re">{t("nft:benefits:confirm:infoWarning")}</h6>
          </div>
          <div className="nft-info-wrapper flex-row-15">
            <img className="nft-image" src={nftInfo.image} />
            <div className="nft-info-text-wrapper flex-column-left-10">
              <h2 className="eng">{nftInfo.nftName}</h2>
              <h5 className="nft-info-chain flex-row-6">
                {
                  chainList.find(
                    (el: any) => el.chainType === nftInfo.chainType
                  )?.icon
                }
                {nftInfo.chainType}
              </h5>
            </div>
          </div>
          <div className="nft-info-description">{nftInfo.description}</div>

          <div className="nft-info-title">
            <h3 className="title">
              {t("nft:benefits:confirm:benefitCaption")}
            </h3>
            <h6 className="gn">{t("nft:benefits:confirm:benefitWarning")}</h6>
          </div>
          <div className="reward-container">
            {rewardList?.length ? (
              rewardList.map((reward: any, idx: number) => (
                <DeployRewardElem
                  key={idx}
                  nftId={nftInfo.id}
                  rewardId={reward.id}
                />
              ))
            ) : (
              <div className="reward-empty">
                <h3 className={`reward-empty-text gr-4 ${classLang}`}>
                  {t("nft:benefits:confirm:empty")}
                </h3>
              </div>
            )}
          </div>
          <div className="button-wrapper flex-row-10">
            <Button
              theme="black"
              text={t("nft:benefits:buttons:prev")}
              onClick={() => {
                navigation(-1);
              }}
            />
            <Button
              theme="purple"
              text={t("nft:benefits:buttons:minting")}
              onClick={() => deployNft()}
            />
          </div>
        </div>
      )}
    </div>
  );
}
export default ConfirmNftInfo;
