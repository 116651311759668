import { useTranslation } from "react-i18next";
import Button from "../../../components/button/Button";
import { useLanguage } from "../../../hook/useLanguage";

type emailSettingsType = {
  option: string;
  setOption: Function;
  handlePopup: Function;
};

function EmailSettings({ option, setOption, handlePopup }: emailSettingsType) {
  const { t } = useTranslation();
  const { classLang } = useLanguage();

  return (
    <>
      <div className="title-wrapper">
        <h2 className={`title ${classLang}`}>{t("making:7:request")} (4/4)</h2>
        <h4 className={`subtitle ${classLang}`}>{t("making:7:description")}</h4>
      </div>
      <div className="nft-create-info-form">
        <div className="input-box-wrapper">
          <div className="input-certification-email-wrapper">
            <span className="at">@</span>
            <input
              type="text"
              className={`input-text input-text-${classLang}`}
              placeholder="ex) blockwavelabs.io"
              value={option}
              onChange={(e) => {
                setOption(e.target.value);
              }}
            />
          </div>
        </div>
        <div className="button-wrapper" style={{ marginTop: "auto" }}>
          <Button
            theme="purple"
            text={t("making:7:bottom-button")}
            onClick={() => handlePopup(t("making:7:error-msg"))}
            disabled={!option.length}
          />
        </div>
      </div>
    </>
  );
}
export default EmailSettings;
