import { useEffect } from "react";
import { useBackground } from "../../hook/useBackground";
import BlurryLoadingImage from "../../components/blurryLoadingImage/BlurryLoadingImage";
import integratedNftPreviewImage from "../../asset/image/integratedNft/preview/integrated-nft.png";
import integratedNftImage from "../../asset/image/integratedNft/integrated-nft.png";
import "./Intro.scss";
import { useTranslation } from "react-i18next";
import { useLanguage } from "../../hook/useLanguage";

type IntroProps = {
  goToInputPage: () => void;
};

function Intro({ goToInputPage }: IntroProps) {
  const { t } = useTranslation();
  const { classLang } = useLanguage();

  useBackground({ backgroundStyle: "SPACE" });

  useEffect(() => {
    const appContent = document.getElementById("app-content");
    if (appContent) {
      appContent.style.overflowX = "hidden";
    }

    return () => {
      if (appContent) {
        appContent.style.overflowX = "visible";
      }
    };
  }, []);

  return (
    <div className="create-integratednft-intro">
      <div className="title-wrapper">
        <div className={`title ${classLang}`}>INTEGRATED NFT</div>
        <div className={`description ${classLang}`}>
          {t("intergrated:landing:title")}
        </div>
      </div>

      <div className="nft-wrapper">
        <div className="nft-blank move-right" />
        <div className="nft-blank move-left" />
        <BlurryLoadingImage
          preview={integratedNftPreviewImage}
          image={integratedNftImage}
          alt="integrated nft"
          imageStyleClass="nft-integrated"
          bgColor="transparent"
        />
      </div>

      <div className={`intro-description ${classLang}`}>
        <b className="green">{t("intergrated:landing:highlight")}</b>
        <br />
        <b>{t("intergrated:landing:subtitle")}</b>
        <br />
        {t("intergrated:landing:content")}
      </div>

      <button
        className={`goto-create-button ${classLang}`}
        onClick={() => goToInputPage()}
      >
        Create Integrated NFT
      </button>
    </div>
  );
}
export default Intro;
