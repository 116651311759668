import { Trans, useTranslation } from "react-i18next";
import pushpinImg from "../../../asset/image/pushpin.png";
import Button from "../../../components/button/Button";
import { useLanguage } from "../../../hook/useLanguage";

type chooseAuthModeProp = {
  setToPhoto: () => void;
  setToEmail: () => void;
};

function ChooseAuthMode({ setToPhoto, setToEmail }: chooseAuthModeProp) {
  const { t } = useTranslation();
  const { classLang } = useLanguage();

  return (
    <>
      <div className="title-wrapper">
        <h2 className={`title ${classLang}`}>{t("making:6:request")} (5/5)</h2>
      </div>
      <div className="certification-button-wrapper">
        <Button
          theme="black"
          text={t("making:6:select1")}
          onClick={() => setToPhoto()}
          eng={true}
        />
        <Button
          theme="black"
          text={t("making:6:select2")}
          onClick={() => setToEmail()}
          eng={true}
        />
      </div>
      <div className="whitelist-wrapper">
        <button
          className="show-whitelist-info"
          onTouchStart={(e) => {
            e.currentTarget.focus();
          }}
        >
          ?⃝ {t("making:6:bottom-question")}
        </button>
        <div className="whitelist-info-wrapper">
          <h5 className={`whitelist-info-title`}>
            {t("making:6:bottom-question-content1")}
            <img className="pushpin-icon" src={pushpinImg} />
          </h5>
          <div className="whitelist-info-description">
            <Trans
              i18nKey="making:6:bottom-question-content2"
              components={{ a: <a /> }}
            />
          </div>
        </div>
      </div>
    </>
  );
}
export default ChooseAuthMode;
