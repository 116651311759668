import { copyText } from "../../utils/function/linkShare";
import { ReactComponent as CopyIcon } from "../../asset/svg/copy-text.svg";
import paperImg from "../../asset/image/paper.png";
import CopyBox from "../copyBox/CopyBox";
import Button from "../button/Button";
import { Trans, useTranslation } from "react-i18next";
import { useLanguage } from "../../hook/useLanguage";

type saveMnemonicProps = {
  mnemonic: string;
  completeButtonText?: string;
  setCompleteAction?: Function;
};

function SaveMnemonic({
  mnemonic,
  completeButtonText,
  setCompleteAction,
}: saveMnemonicProps) {
  const { t } = useTranslation();
  const { classLang } = useLanguage();

  return (
    <div className="save-mnemonic-page">
      <div className="flex-column-24">
        <CopyBox
          text={mnemonic}
          copyNotificationText={t("wallet:modal:copy")}
        />
        <div className={`mnemonic-copy-description ${classLang}`}>
          <Trans
            i18nKey="wallet:quest2:warning2"
            components={{ br: <br />, span: <span /> }}
          />
        </div>
      </div>
      <div className="mnemonic-copy-tip-wrapper flex-column-24">
        <div className="mnemonic-copy-tip flex-row-4">
          <h4>Tip!</h4>
          <img src={paperImg} />
        </div>
        <h5 className={`mnemonic-copy-tip-text bold ${classLang}`}>
          <Trans
            i18nKey="wallet:quest2:tip:content"
            components={{ br: <br />, span: <span /> }}
          />
        </h5>
        <h5 className={`mnemonic-copy-tip-text ${classLang}`}>
          · {t("wallet:quest2:tip:list1")}
          <br />· {t("wallet:quest2:tip:list2")}
          <br />· {t("wallet:quest2:tip:list3")}
          <br />
        </h5>
      </div>

      {!!(completeButtonText && setCompleteAction) && (
        <Button
          theme="purple"
          text={completeButtonText}
          onClick={() => setCompleteAction()}
          sticky={true}
        />
      )}
    </div>
  );
}
export default SaveMnemonic;
