import { useSelector } from "react-redux";
import "./welcome.scss";
import { useState } from "react";
import BottomSheet from "../bottomSheet/BottomSheet";
import Fearful from "../../asset/image/fearful.png";
import Letter from "../../asset/image/letter.png";
import { useTranslation } from "react-i18next";
import { useLanguage } from "../../hook/useLanguage";
import { Popup } from "../popup";
import ReactPortal from "../portal";
import BlurBackground from "../bottomSheet/BlurBackground";
import { useNavigate } from "react-router-dom";

interface Props {
  nftId: string;
}

/** 웰컴NFT 내부 베네핏 컴포넌트 */
const Benefit = ({ nftId }: Props) => {
  const { t } = useTranslation();
  const { classLang } = useLanguage();
  const navigation = useNavigate();

  const nftList = useSelector((state: any) => state.nft.ownNftIdList);
  const [sheetOpen, setSheetOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  const openSheet = () => {
    setSheetOpen(true);
  };

  const closeSheet = () => {
    setSheetOpen(false);
  };

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const confirmSheet = () => {
    closeSheet();
    openModal();
  };

  const confirmModal = () => {
    navigation(`/nft/0/welcome`);
  };

  const confirm = () => {
    //TODO 토큰게이팅 임시
    window.open("https://t.me/+FXBmchiZ4ShlZjU9");
  };

  const positiveConfig = {
    icon: Letter,
    positive: true,
    title: "WELCOME TO YOURS",
    content: t("mypage:welcome:positive:content"),
    confirm: t("mypage:welcome:positive:confirm"),
    closeHandler: closeSheet,
    confirmHandler: confirm,
    ratio: 0.33,
  };

  const negativeConfig = {
    icon: Fearful,
    positive: false,
    title: t("mypage:welcome:negative:title"),
    content: t("mypage:welcome:negative:content"),
    confirm: t("mypage:welcome:negative:confirm"),
    closeHandler: closeSheet,
    confirmHandler: confirmSheet,
    ratio: 0.33,
  };

  const sheetConfig = nftList.includes(Number(nftId))
    ? positiveConfig
    : negativeConfig;

  const modalConfig = {
    title: t("mypage:welcome:modal:title"),
    closeModal: closeModal,
    approve: confirmModal,
    deny: closeModal,
    approveText: t("mypage:welcome:modal:confirm"),
    denyText: t("mypage:welcome:modal:close"),
  };

  return (
    <div className="nft-reward-elem-wrapper">
      <div className="nft-reward-info-wrapper">
        <div className={`nft-name ${classLang}`}>WELCOME TO YOURS</div>
        <div className={`reward-name ${classLang}`}>
          {t("mypage:welcome:title")}
        </div>
      </div>
      <button className="nft-reward-action-button" onClick={openSheet}>
        {t("mypage:buttons:welcome")}
      </button>
      {sheetOpen ? <BottomSheet {...sheetConfig} /> : <></>}
      {modalOpen ? (
        <ReactPortal wrapperId="bottom-sheet">
          <BlurBackground>
            <Popup {...modalConfig} />
          </BlurBackground>
        </ReactPortal>
      ) : (
        <></>
      )}
    </div>
  );
};

export default Benefit;
