import { PetraWallet } from "petra-plugin-wallet-adapter";
import { PontemWallet } from "@pontem/wallet-adapter-plugin";
import { MartianWallet } from "@martianwallet/aptos-wallet-adapter";
import { AptosWalletAdapterProvider } from "@aptos-labs/wallet-adapter-react";
import { FC, ReactNode } from "react";

export const wallets = [
  new PetraWallet(),
  new PontemWallet(),
  new MartianWallet(),
];

export const AppContext: FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <AptosWalletAdapterProvider
      plugins={wallets}
      autoConnect={true}
      onError={(error) => {
        console.log("Custom error handling", error);
      }}
    >
      {children}
    </AptosWalletAdapterProvider>
  );
};
