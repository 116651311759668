import { useTranslation } from "react-i18next";
import Lottie from "lottie-react";
import coin from "../../../asset/lotties/coin.json";
import "./charge.scss";
import { useLanguage } from "../../../hook/useLanguage";
import { useYrp } from "../../../hook/useYrp";
import { useEffect, useState } from "react";

interface Props {
  handleNextStage: (isSuccess?: boolean) => void;
  tx: string;
}

const Loading = ({ handleNextStage, tx }: Props) => {
  const [elapsedTime, setElapsedTime] = useState<number>(0);

  const { t } = useTranslation();
  const { classLang } = useLanguage();
  const { checkTxSuccess, getTransactionFeeFromEtherscan, chargeYrp } =
    useYrp();

  const afterTxSuccess = async (tx: string) => {
    try {
      const fee = await getTransactionFeeFromEtherscan(tx);
      await chargeYrp("EVM", tx, fee, fee !== "");
      handleNextStage(true);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    let timerId: NodeJS.Timeout;

    if (elapsedTime >= 60000) handleNextStage();

    if (elapsedTime < 60000) {
      const checkAndHandle = async () => {
        const result = await checkTxSuccess(tx);
        if (result) {
          afterTxSuccess(tx);
        } else {
          // 3초 후에 다시 체크
          timerId = setTimeout(() => {
            setElapsedTime((prev) => prev + 3000);
          }, 3000);
        }
      };

      checkAndHandle();
    }

    return () => {
      if (timerId) {
        clearTimeout(timerId);
      }
    };
  }, [elapsedTime]);

  return (
    <div className="yrp-loading-wrapper">
      <Lottie
        animationData={coin}
        loop
        autoplay
        style={{ width: 250, height: "auto" }}
        // onClick={handleNextStage}
      />
      <h3 className={classLang}>{t("yrp:charge:loading")}</h3>
      <h3 className={classLang}>{t("yrp:charge:loadingDesc")}</h3>
    </div>
  );
};

export default Loading;
