import { ChainElem } from "ChainType";
import EthereumLogo from "../../asset/image/chain/Ethereum.png";
import KlaytnLogo from "../../asset/image/chain/Klaytn.png";
import PolygonLogo from "../../asset/image/chain/Polygon.png";
import SolanaLogo from "../../asset/image/chain/Solana.png";
import AptosLogo from "../../asset/image/chain/Aptos.png";
import AuroraLogo from "../../asset/image/chain/Aurora.png";
import OasysLogo from "../../asset/image/chain/Oasys.png";
import XplaLogo from "../../asset/image/chain/Xpla.png";

import { ReactComponent as EthereumIcon } from "../../asset/svg/chain/ethereum.svg";
import { ReactComponent as KlaytnIcon } from "../../asset/svg/chain/klaytn.svg";
import { ReactComponent as PolygonIcon } from "../../asset/svg/chain/polygon.svg";
import { ReactComponent as SolanaIcon } from "../../asset/svg/chain/solana.svg";
import { ReactComponent as AptosIcon } from "../../asset/svg/chain/aptos.svg";
import { ReactComponent as AuroraIcon } from "../../asset/svg/chain/aurora.svg";
import { ReactComponent as OasysIcon } from "../../asset/svg/chain/oasys.svg";
import { ReactComponent as XplaIcon } from "../../asset/svg/chain/xpla.svg";

import ethereumCard from "../../asset/image/integratedNft/card/ethereum.png";
import polygonCard from "../../asset/image/integratedNft/card/polygon.png";
import klaytnCard from "../../asset/image/integratedNft/card/klaytn.png";
import solanaCard from "../../asset/image/integratedNft/card/solana.png";
import aptosCard from "../../asset/image/integratedNft/card/aptos.png";
import auroraCard from "../../asset/image/integratedNft/card/aurora.png";
import oasysCard from "../../asset/image/integratedNft/card/oasys.png";
import xplaCard from "../../asset/image/integratedNft/card/xpla.png";

import ethereumCardPreview from "../../asset/image/integratedNft/preview/card/ethereum.png";
import polygonCardPreview from "../../asset/image/integratedNft/preview/card/polygon.png";
import klaytnCardPreview from "../../asset/image/integratedNft/preview/card/klaytn.png";
import solanaCardPreview from "../../asset/image/integratedNft/preview/card/solana.png";
import aptosCardPreview from "../../asset/image/integratedNft/preview/card/aptos.png";
import auroraCardPreview from "../../asset/image/integratedNft/preview/card/aurora.png";
import oasysCardPreview from "../../asset/image/integratedNft/preview/card/oasys.png";
import xplaCardPreview from "../../asset/image/integratedNft/preview/card/xpla.png";

import ethereumCardBlank from "../../asset/image/integratedNft/card-blank/ethereum.png";
import polygonCardBlank from "../../asset/image/integratedNft/card-blank/polygon.png";
import klaytnCardBlank from "../../asset/image/integratedNft/card-blank/klaytn.png";
import solanaCardBlank from "../../asset/image/integratedNft/card-blank/solana.png";
import aptosCardBlank from "../../asset/image/integratedNft/card-blank/aptos.png";
import auroraCardBlank from "../../asset/image/integratedNft/card-blank/aurora.png";
import oasysCardBlank from "../../asset/image/integratedNft/card-blank/oasys.png";
import xplaCardBlank from "../../asset/image/integratedNft/card-blank/xpla.png";

import ethereumCardBlankPreview from "../../asset/image/integratedNft/preview/card-blank/ethereum.png";
import polygonCardBlankPreview from "../../asset/image/integratedNft/preview/card-blank/polygon.png";
import klaytnCardBlankPreview from "../../asset/image/integratedNft/preview/card-blank/klaytn.png";
import solanaCardBlankPreview from "../../asset/image/integratedNft/preview/card-blank/solana.png";
import aptosCardBlankPreview from "../../asset/image/integratedNft/preview/card-blank/aptos.png";
import auroraCardBlankPreview from "../../asset/image/integratedNft/preview/card-blank/aurora.png";
import oasysCardBlankPreview from "../../asset/image/integratedNft/preview/card-blank/oasys.png";
import xplaCardBlankPreview from "../../asset/image/integratedNft/preview/card-blank/xpla.png";

const chainList: ChainElem[] = [
  {
    chainType: "Ethereum",
    logo: EthereumLogo,
    icon: <EthereumIcon />,
    explorerUrl: "https://sepolia.etherscan.io/tx",
    color: "linear-gradient(227.26deg, #0D041A 25.99%, #2F1060 231.45%)",
    card: { image: ethereumCard, preview: ethereumCardPreview },
    cardBlank: { image: ethereumCardBlank, preview: ethereumCardBlankPreview },
    wallet: {
      nameKor: "메타마스크",
      nameEng: "Metamask",
      placeholder: "Ex. 0xB5D... (ENS 주소는 지원되지 않습니다)",
      placeholderEng: "Ex. 0xB5D... (ENS address is not supported)",
    },
  },
  {
    chainType: "Klaytn",
    logo: KlaytnLogo,
    icon: <KlaytnIcon />,
    explorerUrl: "https://scope.klaytn.com/tx",
    color: "linear-gradient(227.26deg, #C62E34 25.99%, #863466 231.45%)",
    card: { image: klaytnCard, preview: klaytnCardPreview },
    cardBlank: { image: klaytnCardBlank, preview: klaytnCardBlankPreview },
    wallet: {
      nameKor: "메타마스크 또는 카이카스",
      nameEng: "Metamask or Kaikas",
      placeholder: "Ex. 0xB5D... (ENS 주소는 지원되지 않습니다)",
      placeholderEng: "Ex. 0xB5D... (ENS address is not supported)",
    },
  },
  {
    chainType: "Polygon",
    logo: PolygonLogo,
    icon: <PolygonIcon />,
    explorerUrl: "https://polygonscan.com/tx",
    color: "linear-gradient(227.26deg, #7D61EE 25.99%, #3A2FC1 231.45%)",
    card: { image: polygonCard, preview: polygonCardPreview },
    cardBlank: { image: polygonCardBlank, preview: polygonCardBlankPreview },
    wallet: {
      nameKor: "메타마스크",
      nameEng: "Metamask",
      placeholder: "Ex. 0xB5D... (ENS 주소는 지원되지 않습니다)",
      placeholderEng: "Ex. 0xB5D... (ENS address is not supported)",
    },
  },
  // {
  //   chainType: "Solana",
  //   logo: SolanaLogo,
  //   icon: <SolanaIcon />,
  //   explorerUrl: "https://solscan.io/tx",
  //   color: "linear-gradient(227.26deg, #6185EE 25.99%, #584FC7 231.45%)",
  //   card: { image: solanaCard, preview: solanaCardPreview },
  //   cardBlank: { image: solanaCardBlank, preview: solanaCardBlankPreview },
  //   wallet: {
  //     nameKor: "팬텀",
  //     nameEng: "Phantom",
  //     placeholder: "Ex. 9noqY...",
  //     placeholderEng: "Ex. 0xbd4...",
  //   },
  // },
  {
    chainType: "Aptos",
    logo: AptosLogo,
    icon: <AptosIcon />,
    explorerUrl: "https://explorer.aptoslabs.com/txn",
    color: "#000000",
    card: { image: aptosCard, preview: aptosCardPreview },
    cardBlank: { image: aptosCardBlank, preview: aptosCardBlankPreview },
    wallet: {
      nameKor: "페트라 또는 마션",
      nameEng: "Petra or Martion",
      placeholder: "Ex. 0xbd4...",
      placeholderEng: "Ex. 0xbd4...",
    },
  },
  {
    chainType: "Aurora",
    logo: AuroraLogo,
    icon: <AuroraIcon />,
    explorerUrl: "https://explorer.testnet.aurora.dev/tx",
    color: "linear-gradient(214.98deg, #9CE4DF 20.58%, #0B3B7B 111.42%)",
    card: { image: auroraCard, preview: auroraCardPreview },
    cardBlank: { image: auroraCardBlank, preview: auroraCardBlankPreview },
    wallet: {
      nameKor: "메타마스크",
      nameEng: "Metamask",
      placeholder: "Ex. 0xB5D... (ENS 주소는 지원되지 않습니다)",
      placeholderEng: "Ex. 0xB5D... (ENS address is not supported)",
    },
  },
  {
    chainType: "Oasys",
    logo: OasysLogo,
    icon: <OasysIcon />,
    explorerUrl: "https://explorer.testnet.oasys.games/tx",
    color:
      "linear-gradient(33deg, #006573 -9.6%, rgba(0, 221, 125, 0.00) 131.36%), #00D966",
    card: { image: oasysCard, preview: oasysCardPreview },
    cardBlank: { image: oasysCardBlank, preview: oasysCardBlankPreview },
    wallet: {
      nameKor: "메타마스크",
      nameEng: "Metamask",
      placeholder: "Ex. 0xB5D... (ENS 주소는 지원되지 않습니다)",
      placeholderEng: "Ex. 0xB5D... (ENS address is not supported)",
    },
  },
  {
    chainType: "XPLA",
    logo: XplaLogo,
    icon: <XplaIcon />,
    explorerUrl: "https://explorer.xpla.io/mainnet/tx",
    color:
      "linear-gradient(26deg, #4DBCD4 -6.17%, rgba(255, 255, 255, 0.00) 143.34%), #00B1FF",
    card: { image: xplaCard, preview: xplaCardPreview },
    cardBlank: { image: xplaCardBlank, preview: xplaCardBlankPreview },
    wallet: {
      nameKor: "메타마스크",
      nameEng: "Metamask",
      placeholder: "Ex. 0xB5D... (ENS 주소는 지원되지 않습니다)",
      placeholderEng: "Ex. 0xB5D... (ENS address is not supported)",
    },
  },
];
export default chainList;
