import { ReactComponent as Caret } from "../../../asset/svg/caret-down.svg";
import { useLanguage } from "../../../hook/useLanguage";
import { useTransition } from "../../../hook/useTransition";
import "./accordian.scss";

interface Props {
  title: string;
  children: React.ReactNode;
}

const Accordian = ({ title, children }: Props) => {
  const { isOpen, handleOpen, rotate, startTransition, transition } =
    useTransition(200, true);
  const { classLang } = useLanguage();

  return (
    <section className={`yrp-accordian-container`}>
      <div className="header-line" />
      <div className={`yrp-accordian-header`}>
        <h3 className={classLang}>{title}</h3>
        <Caret
          onClick={() => (isOpen ? startTransition() : handleOpen())}
          style={{ transform: rotate ? "rotate(-180deg)" : "rotate(0deg)" }}
        />
      </div>
      {isOpen && (
        <div className={`yrp-accordian-children ${transition && "close"}`}>
          {children}
        </div>
      )}
    </section>
  );
};

export default Accordian;
