import { AdminPhoto, ApproveNftAdminPhotoResponse } from "AdminResponseType";
import { NftReward } from "NftType";
import { get, put } from "./AxiosCreate";

class AdminApi {
    getNftAdminPhotoList = async (nftId:number):Promise<AdminPhoto[]> => {
        const data = await get<AdminPhoto[]>(`admin/${nftId}`);
        return data;
    }
    
    approveNftAdminPhoto = async (applicationId:number, approve:boolean, reason=""):Promise<ApproveNftAdminPhotoResponse> => {
        const data = await put<ApproveNftAdminPhotoResponse>(`admin`, {
            tableId: applicationId,
            type: approve,
            reason: reason
        });
        return data;
    }

    getNftAdminRewardList = async (nftId:number):Promise<NftReward[]> => {
        const data = await get<NftReward[]>(`admin/${nftId}/reward`);
        return data;
    }

    getNftAdminRewardDetail = async (rewardId:number):Promise<NftReward> => {
        const data = await get<NftReward>(`admin/${rewardId}/reward/detail`);
        return data;
    }
}
export default AdminApi;