import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./index.scss";
import RewardElemLoading from "./RewardElemLoading";
import { useTranslation } from "react-i18next";
import { useLanguage } from "../../hook/useLanguage";
import { Category } from "NftType";
import { useReward } from "../../hook/useReward";
import BottomSheet from "../bottomSheet/BottomSheet";
import Letter from "../../asset/image/letter.png";
import Fearful from "../../asset/image/fearful.png";

interface RewardElemProp {
  /**
   * NFT name related to reward
   */
  nftName: string;
  /**
   * NFT id related to reward
   */
  nftId: number;
  /**
   * Corresponding reward information
   */
  reward: RewardType;
  /**
   * User has this token
   */
  isMine?: boolean;
}

interface RewardType {
  rewardName: string;
  id: number;
  category?: Category | null;
  option?: string;
}

/**
 * NFT Reward UI component
 */
function RewardElem({
  nftName,
  nftId,
  reward,
  isMine = false,
}: RewardElemProp) {
  const { t } = useTranslation();
  const { classLang } = useLanguage();
  const { tokenGatingButtonContent } = useReward();
  const navigation = useNavigate();

  const [loading, setLoading] = useState(true);
  const [isSheetOpen, setIsSheetOpen] = useState(false);

  const openSheet = () => {
    setIsSheetOpen(true);
  };

  const closeSheet = () => {
    setIsSheetOpen(false);
  };

  const openBenefit = () => {
    window.open(reward.option);
    setIsSheetOpen(false);
  };

  const sheetConfig = {
    positive: {
      icon: Letter,
      positive: true,
      title: nftName,
      content: reward.rewardName,
      confirm: t("mypage:welcome:positive:confirm"),
      closeHandler: closeSheet,
      confirmHandler: openBenefit,
      ratio: 0.33,
    },
    negative: {
      icon: Fearful,
      positive: false,
      title: t("mypage:bottom:negative:title"),
      content: t("mypage:bottom:negative:content"),
      confirm: t("mypage:welcome:negative:confirm"),
      closeHandler: closeSheet,
      confirmHandler: closeSheet,
      ratio: 0.33,
      onlyCloseButton: true,
    },
  };

  const getConfig = (mine: boolean) => {
    if (mine) return sheetConfig.positive;
    else return sheetConfig.negative;
  };

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1500);
  }, []);

  return (
    <>
      {loading ? (
        <RewardElemLoading />
      ) : (
        <div className="nft-reward-elem-wrapper">
          <div className="nft-reward-info-wrapper">
            <div
              className={`nft-name ${classLang}`}
              onClick={() => {
                navigation(`/nft/${nftId}/detail`);
              }}
            >
              {nftName}
            </div>
            <div className={`reward-name ${classLang}`}>
              {reward.rewardName}
            </div>
          </div>
          <button
            onClick={openSheet}
            disabled={!(reward.category && reward.option)}
            className={`nft-reward-action-button ${classLang}`}
          >
            {reward.category && reward.option
              ? tokenGatingButtonContent(reward.category)
              : t("mypage:card:ready")}
          </button>
        </div>
      )}
      {isSheetOpen && <BottomSheet {...getConfig(isMine)} />}
    </>
  );
}
export default RewardElem;
