import { useEffect } from "react";
import "./BottomSheet.scss";

interface Props {
  children: React.ReactNode;
}

const BlurBackground = ({ children }: Props) => {
  const bgName = "blur-background";
  const updateVH = () => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--blurvh", `${vh}px`);
  };

  useEffect(() => {
    updateVH();
    window.addEventListener("resize", updateVH);
    return () => window.removeEventListener("resize", updateVH);
  }, []);

  return (
    <div className={`${bgName}-wrapper`}>
      <div className={bgName} />
      {children}
    </div>
  );
};

export default BlurBackground;
