import { ChangeEvent, useState } from "react";
import { set } from "lodash";
import { Category } from "NftType";
import { useTranslation } from "react-i18next";
import communityIcon from "../asset/image/benefit/community.png";

interface Reward {
  name: string;
  desc: string;
  category: Category;
  link: string | null;
}

type BenefitType = {
  type: Category;
  icon: string;
  title: string;
};

export const useReward = () => {
  const { t } = useTranslation();

  const [rewardInfoInput, setRewardInfoInput] = useState<Reward>({
    name: "",
    desc: "",
    category: null,
    link: "",
  });

  const BENEFITS: BenefitType[] = [
    {
      type: "community",
      icon: communityIcon,
      title: t("nft:benefits:add:gating:types:community"),
    },
  ];

  const handleRewardInfo = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    switch (e.target.name) {
      case "name":
        setRewardInfoInput((prev) => set({ ...prev }, "name", e.target.value));
        break;
      case "desc":
        setRewardInfoInput((prev) => set({ ...prev }, "desc", e.target.value));
        break;
      case "link":
        setRewardInfoInput((prev) => set({ ...prev }, "link", e.target.value));
        break;
      default:
        break;
    }
  };

  const selectCategory = (category: Category) => {
    if (category !== rewardInfoInput.category)
      setRewardInfoInput((prev) => set({ ...prev }, "category", category));
    else setRewardInfoInput((prev) => set({ ...prev }, "category", null));
  };

  const initializeInfoInput = (key: string, value: string) => {
    switch (key) {
      case "name":
        setRewardInfoInput((prev) => set({ ...prev }, "name", value));
        break;
      case "desc":
        setRewardInfoInput((prev) => set({ ...prev }, "desc", value));
        break;
      case "category":
        setRewardInfoInput((prev) => set({ ...prev }, "category", value));
        break;
      case "link":
        setRewardInfoInput((prev) => set({ ...prev }, "link", value));
        break;
      default:
        break;
    }
  };

  const getBenefitInfo = (type: Category) => {
    return BENEFITS.find((benefit) => benefit.type === type) || null;
  };

  const tokenGatingButtonContent = (category: Category) => {
    return t(`nft:benefits:add:gating:buttons:${category}`);
  };

  return {
    rewardInfoInput,
    handleRewardInfo,
    selectCategory,
    initializeInfoInput,
    BENEFITS,
    getBenefitInfo,
    tokenGatingButtonContent,
  };
};
