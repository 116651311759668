import { ComponentProps } from "react";
import { useLanguage } from "../../../hook/useLanguage";
import "./input.scss";
import Loading from "../../spinner/Loading";

interface Props extends ComponentProps<"input"> {
  caption: string;
  option?: React.ReactNode;
  overegg?: string;
  warning?: string;
  isLoading?: boolean;
  isSufficient?: boolean;
}

const Input = ({
  caption,
  option,
  overegg,
  warning,
  isLoading = false,
  isSufficient = false,
  ...props
}: Props) => {
  const { classLang } = useLanguage();

  return (
    <div className="charge-input-wrapper" {...props}>
      <h3 className={classLang}>{caption}</h3>
      <div className="charge-input-box">
        <input {...props} />
        {option}
      </div>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          {overegg && (
            <h4
              className={`over-egg ${classLang} ${
                !isSufficient && "insufficient"
              }`}
            >
              {overegg}
            </h4>
          )}
          {warning && <h6 className={`warning ${classLang}`}>{warning}</h6>}
        </>
      )}
    </div>
  );
};

export default Input;
