import { YrpHistoryInterface } from "YrpResponseType";
import { ACTION_TYPES, HavingToken } from "./yrpTypes";

export const setAmount = (amount: number) => {
  return { type: ACTION_TYPES.SET_TOTAL_AMOUNT, data: amount };
};

export const setInputAmount = (amount: number | string) => {
  return { type: ACTION_TYPES.SET_INPUT_AMOUNT, data: amount };
};

export const setSelectChain = (chain: string) => {
  return { type: ACTION_TYPES.SET_SELECT_CHAIN, data: chain };
};

export const setHavingToken = (havingTokens: HavingToken) => {
  return { type: ACTION_TYPES.SET_HAVING_TOKEN, data: havingTokens };
};

export const setTxFee = (fee: number) => {
  return { type: ACTION_TYPES.SET_TX_FEE, data: fee };
};

export const setHistories = (histories: YrpHistoryInterface[]) => {
  return { type: ACTION_TYPES.SET_HISTORIES, data: histories };
};
