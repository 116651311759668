import Modal from "../../../components/modal/Modal";
import walletImage from "../../../asset/image/wallet.png";

import Confetti from "react-confetti";
import { ReactComponent as CloseIcon } from "../../../asset/svg/close-circle.svg";
import { useRef } from "react";
import "./index.scss";
import { useNavigate } from "react-router-dom";
import Button from "../../../components/button/Button";
import { useTranslation } from "react-i18next";
import { useLanguage } from "../../../hook/useLanguage";

type newUserModalType = {
  closeModal: Function;
};

function WelcomeModal({ closeModal }: newUserModalType) {
  const { t } = useTranslation();
  const { classLang } = useLanguage();

  const modalRef = useRef<any>();
  const navigation = useNavigate();

  return (
    <Modal closeModal={closeModal} align={"BOTTOM"}>
      <div className="welcome-modal modal-wrapper" ref={modalRef}>
        <Confetti
          width={modalRef.current?.clientWidth}
          height={modalRef.current?.clientHeight}
          numberOfPieces={30}
          className="modal-confetti"
        />
        <div className="modal-header">
          <CloseIcon className="modal-close" onClick={() => closeModal()} />
        </div>
        <div className={`welcome-title ${classLang}`}>
          {t("mypage:newbie:title")}
        </div>

        <div className="welcome-wallet-wrapper">
          <img className="wallet-icon" src={walletImage} />
        </div>

        <div className={`welcome-text ${classLang}`}>
          {t("mypage:newbie:subtitle1")}
          <span className="gn">{t("mypage:newbie:highlight")}</span>
          {t("mypage:newbie:subtitle2")}
        </div>

        <Button
          theme="white"
          onClick={() => {
            navigation("/wallet/tutorial");
          }}
          text={t("mypage:newbie:button")}
        />
      </div>
    </Modal>
  );
}
export default WelcomeModal;
