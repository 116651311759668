import { ComponentProps, useCallback, useRef } from "react";
import "./BottomSheet.scss";
import BlurBackground from "./BlurBackground";
import { useOutsideClose } from "../../hook/useOutsideClose";
import ReactPortal from "../portal";
import { useTranslation } from "react-i18next";
import { useLanguage } from "../../hook/useLanguage";

interface Props extends ComponentProps<"div"> {
  /**
   * 중앙 상단 아이콘 이미지
   */
  icon: string;
  /**
   * 타이틀 색이 GN-T인가 RE인가
   */
  positive: boolean;
  /**
   * 중앙 타이틀
   */
  title: string;
  /**
   * 중앙 타이틀 props
   */
  titleProps?: ComponentProps<"h3">;
  /**
   * 타이틀 아래 콘텐츠
   */
  content: string;
  /**
   * 콘텐츠 props
   */
  contentProps?: ComponentProps<"h5">;
  /**
   * 닫기 우측 버튼 이름
   */
  confirm: string;
  /**
   * 닫기 버튼 핸들러
   */
  closeHandler?: () => void;
  /**
   * 컨펌 버튼 핸들러
   */
  confirmHandler: () => void;
  /**
   * 올라오는 비율. 0 ~ 1
   */
  ratio: number;
  /**
   * 취소 버튼만 있는가
   */
  onlyCloseButton?: boolean;
}

/**
 * 공용 바텀 시트 컴포넌트
 */
const BottomSheet = ({
  icon,
  positive,
  title,
  titleProps,
  content,
  contentProps,
  confirm,
  closeHandler,
  confirmHandler,
  ratio,
  onlyCloseButton,
  ...props
}: Props) => {
  const { t } = useTranslation();
  const { classLang } = useLanguage();

  const titleName = positive ? "positive" : "negative";
  const ref = useRef<HTMLDivElement>(null);

  const onCloseSheet = useCallback(() => {
    setTimeout(() => {
      closeHandler!();
    }, 200);
  }, []);

  useOutsideClose(ref, onCloseSheet);

  return (
    <ReactPortal wrapperId="bottom-sheet">
      <BlurBackground>
        <div ref={ref} className="bottom-sheet-component" {...props}>
          <img src={icon} />
          <h3 className={`${titleName} title ${classLang}`} {...titleProps}>
            {title}
          </h3>
          <h5 className={classLang} {...contentProps}>
            {content}
          </h5>
          <div className={`button-wrapper`}>
            <button className={`close`} onClick={closeHandler}>
              {t("mypage:welcome:close")}
            </button>
            {!onlyCloseButton && (
              <button onClick={confirmHandler} className="confirm">
                {confirm}
              </button>
            )}
          </div>
        </div>
      </BlurBackground>
    </ReactPortal>
  );
};

export default BottomSheet;
