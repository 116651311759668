import FloatingButton from "./FloatingButtont";
import { ReactComponent as WalletIcon } from "../../../asset/svg/wallet-even.svg";
import { ReactComponent as NotiIcon } from "../../../asset/svg/bubble.svg";
import { ReactComponent as CartIcon } from "../../../asset/svg/cart.svg";
import "./floating.scss";
import { useTransition } from "../../../hook/useTransition";
import { useNavigate } from "react-router-dom";
import UserApi from "../../../apis/UserApi";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const Floating = () => {
  const userApi = new UserApi();
  const [completedQuest, setCompletedQuest] = useState(false);

  const { t } = useTranslation();

  const { isOpen, transition, ...props } = useTransition(300, false);
  const navigation = useNavigate();

  const handleOpenNewTab = (url: string) => {
    window.open(url, "_blank", "noopener, noreferrer");
  };

  useEffect(() => {
    const getUserCompletedQuest = async () => {
      const _completed = await userApi.getUserCompletedQuest();
      setCompletedQuest(_completed);
    };
    getUserCompletedQuest();
  }, []);

  const contents = [
    {
      Icon: CartIcon,
      caption: t("mypage:floating:marketplace"),
      handler: () => handleOpenNewTab("https://marketplace.yoursnft.me/"),
    },
    {
      Icon: NotiIcon,
      caption: t("mypage:floating:more"),
      handler: () => handleOpenNewTab("https://yours.framer.website/"),
    },
    {
      Icon: WalletIcon,
      caption: "Yours Wallet",
      isEng: true,
      handler: () =>
        completedQuest ? navigation("/wallet") : navigation("/wallet/tutorial"),
      object: !completedQuest && <div className="wallet-alert">1</div>,
    },
  ];

  return (
    <>
      {isOpen && (
        <div className={`floating-container ${transition && "close"}`} />
      )}
      <FloatingButton
        isOpen={isOpen}
        contents={contents}
        transition={transition}
        {...props}
        style={{ position: "fixed", bottom: 25, right: 25 }}
      />
    </>
  );
};

export default Floating;
