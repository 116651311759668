import { useTranslation } from "react-i18next";
import warningImg from "../../asset/image/warning.png";
import "./PassCodeInputWarning.scss";
import { useLanguage } from "../../hook/useLanguage";

type passCodeInputProp = {
  warningText?: string;
  warningDesc: string;
  passCode: string;
  setPassCode: Function;
  validPassCode: boolean;
};

function PassCodeInputWarning({
  warningText = "비밀구문",
  warningDesc,
  passCode,
  setPassCode,
  validPassCode,
}: passCodeInputProp) {
  const { t } = useTranslation();
  const { classLang } = useLanguage();

  return (
    <div className="passcode-input-warning">
      <div className="passcode-warning-wrapper">
        <img className="passcode-warning-image" src={warningImg} />
        <h2 className="pass-code-warning-eng">Warning!</h2>
        <div className={`warning-title`}>{warningText}</div>
        <h6 className={`warning-description gr-4 ${classLang}`}>
          {warningDesc}
        </h6>
      </div>
      <div className="passcode-input-wrapper">
        <input
          className={`passcode-input ${classLang}`}
          type="password"
          value={passCode}
          onChange={(e) => {
            setPassCode(e.target.value);
          }}
          placeholder={t("wallet:quest2:placeHolder")}
        />
        {!validPassCode && (
          <div className={`passcode-error ${classLang}`}>
            {t("wallet:quest2:inputCheck")}
          </div>
        )}
      </div>
    </div>
  );
}
export default PassCodeInputWarning;
