import { useState } from "react";
import NFTApi from "../../../apis/NftApi";
import gmail from "../../../asset/image/gmail.png";
import Button from "../../../components/button/Button";
import Loading from "../../../components/loading/OverlayLoading";
import { useTranslation } from "react-i18next";

type checkEmailProps = {
  nftEmail: string;
  nftId: number;
};

function CheckEmail({ nftEmail, nftId }: checkEmailProps) {
  const { t, i18n } = useTranslation();

  const nftApi = new NFTApi();
  const [email, setEmail] = useState("");
  const [emailSent, setEmailSent] = useState(false);
  const [emailSentSuccess, setEmailSentSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  const sendEmail = async (e: any) => {
    e.preventDefault();
    setLoading(true);
    try {
      // 이메일 인증 보내기
      await nftApi.sendVerifyEmail(nftId, `${email}@${nftEmail}`);
      setEmailSent(true);
      setEmailSentSuccess(true);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setEmailSentSuccess(false);
    }
  };

  const koTitle = `@${nftEmail}${t("nft:auth:domain:title")}`;
  const enTitle = `${t("nft:auth:domain:title")} @${nftEmail}`;
  const title = i18n.language === "ko" ? koTitle : enTitle;

  return (
    <form
      className="get-badge-content"
      onSubmit={(e) => {
        sendEmail(e);
      }}
    >
      {loading && <Loading />}
      <div className="check-email-content-wrapper">
        <div className="title-wrapper">
          <h4 className="title--gray">{t("nft:auth:domain:caption")}</h4>
          <h3 className="title">{title}</h3>
        </div>
        <div className="email-input">
          <input
            className="user-email"
            type="text"
            value={email}
            onChange={(e) => {
              setEmail(e.currentTarget.value);
            }}
            placeholder={`ex) yours`}
            required={true}
          />
          <span className="nft-email">{`@${nftEmail}`}</span>
        </div>

        {!!emailSent &&
          (emailSentSuccess ? (
            <>
              <div className="input-status" id="success">
                {t("nft:auth:domain:complete")}
              </div>
              <a
                className="mail-button"
                href={`https://mail.google.com`}
                target="_blank"
              >
                <img src={gmail} />
              </a>
            </>
          ) : (
            <>
              <div className="input-status" id="warning">
                {t("nft:auth:domain:fail")}
              </div>
            </>
          ))}
      </div>
      <Button
        theme="purple"
        type="submit"
        text={t("nft:auth:buttons:send")}
        sticky={true}
        disabled={!email.length}
      />
    </form>
  );
}
export default CheckEmail;
