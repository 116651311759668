import { useTranslation } from "react-i18next";
import ConfirmMnemonic from "../../components/confirmMnemonic";
import MiniHeader from "../../components/miniHeader/MiniHeader";

function WalletPrivacyCheckMnemonic() {
  const { t } = useTranslation();

  return (
    <div>
      <MiniHeader title={t("wallet:yours:privacy:password:header")} />
      <ConfirmMnemonic />
    </div>
  );
}
export default WalletPrivacyCheckMnemonic;
