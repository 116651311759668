import { Trans, useTranslation } from "react-i18next";
import Button from "../button/Button";
import PassCodeInputWarning from "../passCodeInputWarning/PassCodeInputWarning";
import { useSelector } from "react-redux";
import { useLanguage } from "../../hook/useLanguage";

type passCodeInputProps = {
  passCode: string;
  setPassCode: Function;
  decryptMnemonic: Function;
  validPassCode: boolean;
};

function PassCodeInput({
  passCode,
  setPassCode,
  decryptMnemonic,
  validPassCode,
}: passCodeInputProps) {
  const { t } = useTranslation();
  const { classLang } = useLanguage();

  const userName = useSelector((state: any) => state.userData.name);

  return (
    <form
      className="passcode-page"
      onSubmit={(e) => {
        e.preventDefault();
        decryptMnemonic();
      }}
    >
      <div className={`passcode-description ${classLang}`}>
        <Trans
          i18nKey="wallet:quest2:desc"
          components={{ br: <br />, span: <span /> }}
        />
      </div>

      <PassCodeInputWarning
        warningText={t("wallet:quest2:warning1")}
        warningDesc={`${t("wallet:quest2:warningDesc1")}${userName}${t(
          "wallet:quest2:warningDesc2"
        )}`}
        passCode={passCode}
        setPassCode={setPassCode}
        validPassCode={validPassCode}
      />
      <Button
        theme="purple"
        textColor="white"
        text={t("wallet:buttons:confirm")}
        disabled={!passCode.length}
        type="submit"
      />
    </form>
  );
}
export default PassCodeInput;
