import { useAsync } from "react-async";
import { Navigate, Outlet } from "react-router-dom";
import { setRedirectUrl } from "../utils/function/redirectUrl";
import { setShowAlertInfo } from "../utils/function/showAlert";
import UserApi from "../apis/UserApi";
import Loading from "../components/loading/OverlayLoading";
import { useTranslation } from "react-i18next";

const userApi = new UserApi();

const authCheck = async () => {
  await userApi.getUserInfo();
};

export default function PrivateOutlet() {
  const { data, error, isPending } = useAsync({
    promiseFn: authCheck,
  });
  const { t } = useTranslation();

  if (isPending) {
    return <Loading />;
  }

  if (error) {
    // 로그인이 되어있지 않은 경우
    // redirect url 저장 후 landing으로 이동
    setShowAlertInfo(t("landing:popup:login"), true);
    setRedirectUrl(window.location.pathname);
    return <Navigate to={"/landing"} />;
  }

  return <Outlet />;
}
