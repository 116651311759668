import { useEffect } from "react";
import { useExternalWallet } from "../../../hook/useExternalWallet";
import NftElem from "./NftElem";
import "./nftlist.scss";
import { Transition } from "react-transition-group";
import { ExternalWalletNftRequest } from "UserResquestType";
import { chainType } from "ChainType";
import { useTranslation } from "react-i18next";

interface Props {
  isOpen: boolean;
  handleSelect: (item: ExternalWalletNftRequest) => void;
  selectedItem: ExternalWalletNftRequest | null;
  handleButtonDisabled: (length: boolean) => void;
}

const NftList = ({
  isOpen,
  handleSelect,
  handleButtonDisabled,
  selectedItem,
}: Props) => {
  const { t } = useTranslation();
  const { getNftList, nfts, externalWalletInfo } = useExternalWallet();

  const getEmptyMessage = (chain: chainType) => {
    switch (chain) {
      case "Aptos":
      case "Ethereum":
      case "Polygon":
        return t("external:empty:abled");
      default:
        return t("external:empty:disabled");
    }
  };

  useEffect(() => {
    handleButtonDisabled(!!!nfts.length);
  }, [nfts]);

  useEffect(() => {
    if (externalWalletInfo.address)
      getNftList(externalWalletInfo.innerChainInfo.key);
  }, [externalWalletInfo.address, externalWalletInfo.innerChainInfo.key]);

  return (
    <Transition in={true} timeout={300}>
      {(state) => (
        <div className={`nft-list-container ${state}`}>
          {nfts.map((item) => (
            <NftElem
              item={item}
              key={item.nftAddress}
              isOpen={isOpen}
              checked={item.nftAddress === selectedItem?.nftAddress}
              onClick={() => handleSelect(item)}
            />
          ))}
          {(!nfts || nfts.length === 0) && (
            <div className="empty-wrapper">
              <h3 className="eng empty-message">
                {getEmptyMessage(externalWalletInfo.innerChainInfo.name)}
              </h3>
            </div>
          )}
        </div>
      )}
    </Transition>
  );
};

export default NftList;
