import Usdc from "../../asset/image/chain/Usdc.png";
import Usdt from "../../asset/image/chain/Usdt.png";
import Apt from "../../asset/image/chain/Aptos.png";
import Dai from "../../asset/image/chain/Dai.png";
import Busd from "../../asset/image/chain/Busd.png";
import Tusd from "../../asset/image/chain/Tusd.png";
import { YrpHistoryInterface } from "YrpResponseType";

export const ableChargeChain = {
  USDC: {
    logo: Usdc,
    name: "USDC",
    ticker: "USDC",
    full: "USD Coin",
  },
  USDC_T: {
    logo: Usdc,
    name: "USDC - Testnet",
    ticker: "USDC_T",
    full: "USD Coin",
  },
  USDT: {
    logo: Usdt,
    name: "USDT",
    ticker: "USDT",
    full: "Tether",
  },
  APT: {
    logo: Apt,
    name: "APT",
    ticker: "APT",
    full: "Aptos",
  },
  // DAI: {
  //   logo: Dai,
  //   name: "DAI",
  //   full: "Dai",
  // },
  // BUSD: {
  //   logo: Busd,
  //   name: "BUSD",
  //   full: "Binance USD",
  // },
  // TUSD: {
  //   logo: Tusd,
  //   name: "TUSD",
  //   full: "TrueUSD",
  // },
};

export type HavingToken = {
  USDC: number;
  USDC_T: number;
  USDT: number;
  APT: number;
};

export type YrpStateType = {
  totalAmount: number;
  inputAmount: number;
  selectedChain: keyof typeof ableChargeChain;
  histories: YrpHistoryInterface[];
  havingToken: HavingToken;
  fee: number;
};

export const ACTION_TYPES = {
  SET_TOTAL_AMOUNT: "SET_TOTAL_AMOUNT",
  SET_TOTAL_HISTORY: "SET_TOTAL_HISTORY",
  SET_INPUT_AMOUNT: "SET_INPUT_AMOUNT",
  SET_SELECT_CHAIN: "SET_SELECT_CHAIN",
  SET_HAVING_TOKEN: "SET_HAVING_TOKEN",
  SET_TX_FEE: "SET_TX_FEE",
  SET_HISTORIES: "SET_HISTORIES",
};
