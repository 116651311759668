import { ExternalWalletNftRequest } from "UserResquestType";
import { useTranslation } from "react-i18next";
import { useLanguage } from "../../../hook/useLanguage";
import { ComponentProps, useEffect, useState } from "react";
import NftElemLoading from "../../nft/NftElemLoading";
import { Icon } from "@iconify/react";
import { ReactComponent as CircleIcon } from "../../../asset/svg/circle.svg";

interface Props extends ComponentProps<"div"> {
  item: ExternalWalletNftRequest;
  isOpen: boolean;
  checked: boolean;
}

const NftElem = ({ item, isOpen, checked, ...props }: Props) => {
  const { t } = useTranslation();
  const { classLang } = useLanguage();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1500);
  }, []);

  return (
    <>
      {loading ? (
        <NftElemLoading />
      ) : (
        <div
          className={`nft-elem-wrapper external-nft-wrapper ${
            isOpen && "available"
          } ${checked && "checked"}`}
          {...props}
        >
          <div>
            <img className="nft-image" src={item.image} alt="nftimage" />
            <div className="nft-elem-body">
              <h4 className="nft-name eng">{item.nftName}</h4>
              <div className={`nft-reward-length ${classLang}`}>
                {t("mypage:card:benefit")} : 0
                {/** TODO _ 외부 혜택 개수 받아와야함 */}
              </div>
            </div>
            {isOpen &&
              (checked ? (
                <Icon
                  className="check-icon"
                  icon="line-md:circle-to-confirm-circle-transition"
                  color="#D2F586"
                />
              ) : (
                <CircleIcon className="check-icon unchecked" />
              ))}
          </div>
        </div>
      )}
    </>
  );
};

export default NftElem;
