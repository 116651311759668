import { useNavigate } from "react-router-dom";
import { setShowAlertInfo } from "../../utils/function/showAlert";
import UserApi from "../../apis/UserApi";
import MiniHeader from "../../components/miniHeader/MiniHeader";
import ConfirmMnemonic from "../../components/confirmMnemonic";
import { useTranslation } from "react-i18next";

function WalletTutorialSecret() {
  const { t } = useTranslation();

  const navigation = useNavigate();
  const userApi = new UserApi();

  const completeQuest = async () => {
    try {
      await userApi.completeQuest();
      navigation("/mypage", { state: { completeQuest: true } });
    } catch (err) {
      setShowAlertInfo(t("wallet:modal:fail"), false);
    }
  };

  return (
    <div className="wallet-tutorial-secret">
      <MiniHeader title={t("wallet:headers:quest2")} />
      <ConfirmMnemonic
        completeButtonText={t("wallet:buttons:question2")}
        setCompleteAction={completeQuest}
      />
    </div>
  );
}
export default WalletTutorialSecret;
