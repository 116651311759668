import { useState } from "react";
import Button from "../../components/button/Button";
import DiscardModal from "./DiscardModal";
import { useTranslation } from "react-i18next";
import { useLanguage } from "../../hook/useLanguage";

type adminDetailProp = {
  detailInfo: any;
  photoDescription: string | undefined;
  approveAction: Function;
  discardAction: Function;
};

function AdminDetail({
  detailInfo,
  photoDescription,
  approveAction,
  discardAction,
}: adminDetailProp) {
  const { t } = useTranslation();
  const { classLang } = useLanguage();

  const [showDiscardModal, setShowDiscardModal] = useState(false);

  return (
    <>
      {showDiscardModal && (
        <DiscardModal
          closeModal={() => {
            setShowDiscardModal(false);
          }}
          discardAction={(reason: string) => {
            discardAction(detailInfo.id, reason);
          }}
        />
      )}
      <div className="nftadmin-detail">
        <div className="user-profile-wrapper">
          <img className="profile-image" src={detailInfo?.profileImage} />
          <h3 className={classLang}>
            {detailInfo?.name} {t("nft:auth:caption")}
          </h3>
        </div>

        <h3 className={`content-subtitle ${classLang}`}>
          {t("nft:auth:desc")} :
        </h3>
        <h5 className={`nftadmin-description ${classLang}`}>
          {photoDescription}
        </h5>

        <img className="nftadmin-detail-image" src={detailInfo?.image} />

        <div className="button-wrapper">
          <Button
            theme="black"
            text={t("nft:auth:buttons:reject")}
            onClick={() => {
              setShowDiscardModal(true);
            }}
          />
          <Button
            theme="purple"
            text={t("nft:auth:buttons:approve")}
            onClick={() => {
              approveAction(detailInfo.id);
            }}
          />
        </div>
      </div>
    </>
  );
}
export default AdminDetail;
