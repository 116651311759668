export const ellipsisMiddle = (str: string) => {
  const res = str.slice(0, 5) + "..." + str.slice(-5);
  return res;
};

export const truncateString = (input: string, length: number = 30): string => {
  if (input.length <= length) {
    return input;
  }
  return input.substr(0, length) + "...";
};
