import { useState } from "react";
import usePhoneAuthentication from "../../../hook/usePhoneAuthentication";
import Modal from "../../../components/modal/Modal";
import { timeToString } from "../../../utils/function/time";
import { ReactComponent as CheckIcon } from "../../../asset/svg/check-circle.svg";
import { ReactComponent as CloseIcon } from "../../../asset/svg/close-circle.svg";
import UserApi from "../../../apis/UserApi";
import { setShowAlertInfo } from "../../../utils/function/showAlert";
import { useTranslation } from "react-i18next";

type phoneEditModalProps = {
  closeModal: () => void;
};

function PhoneEditModal({ closeModal }: phoneEditModalProps) {
  const { t } = useTranslation();

  const userApi = new UserApi();
  const {
    phoneNumber,
    getPhoneNumber,
    sendVerificationNumber,
    isVerificationNumberSent,
    checkVerifyNumber,
    authentic,
    validationTime,
  } = usePhoneAuthentication();
  const [userVerifyNumber, setUserVerifyNumber] = useState("");

  const sendSms = async () => {
    await sendVerificationNumber();
    setTimeout(() => {
      document.getElementById("verifycode-input")?.focus();
    }, 100);
  };

  const changePhoneNumber = async () => {
    try {
      await userApi.editUserPhoneNumber(phoneNumber.split("-").join(""));
      closeModal();
      setShowAlertInfo(t("setting:profile:toast:success:number"), true);
    } catch (err) {
      setShowAlertInfo(t("setting:profile:toast:fail:number"), false);
    }
  };

  return (
    <Modal closeModal={closeModal}>
      <div className="modal-wrapper profile-edit-modal">
        <div className="modal-header">
          <div className="modal-title">{t("setting:profile:popup:number")}</div>
          <CloseIcon className="modal-close" onClick={closeModal} />
        </div>
        <div className="modal-content">
          <div className="input-textbox">
            <input
              type="text"
              placeholder={t("setting:profile:placeholder:number")}
              value={phoneNumber}
              maxLength={13}
              autoFocus={true}
              onChange={(e) => {
                getPhoneNumber(e.target as HTMLInputElement);
              }}
            />
            <button
              disabled={!phoneNumber.length}
              onClick={() => {
                sendSms();
              }}
            >
              {!isVerificationNumberSent
                ? t("setting:profile:popup:verify")
                : t("setting:profile:popup:retake")}
            </button>
          </div>
          <div className="input-textbox">
            <input
              id="verifycode-input"
              type="text"
              onChange={(e) => {
                setUserVerifyNumber(e.currentTarget.value);
                checkVerifyNumber(e.currentTarget.value);
              }}
              placeholder={t("setting:profile:placeholder:verify")}
              disabled={!isVerificationNumberSent}
            />
            {isVerificationNumberSent &&
              (!!authentic ? (
                <CheckIcon className="check-icon" />
              ) : (
                <div className="valid-time">{timeToString(validationTime)}</div>
              ))}
            {!!userVerifyNumber.length &&
              isVerificationNumberSent &&
              !authentic && (
                <div className="invalid-message">
                  {t("setting:profile:popup:warning")}
                </div>
              )}
          </div>
        </div>
        <div className="modal-footer">
          <button
            className="cancle"
            onClick={() => {
              closeModal();
            }}
          >
            {t("setting:profile:popup:cancel")}
          </button>
          <button
            disabled={!authentic}
            className="approve"
            onClick={() => {
              changePhoneNumber();
            }}
          >
            {t("setting:profile:popup:edit")}
          </button>
        </div>
      </div>
    </Modal>
  );
}
export default PhoneEditModal;
