import { useRef } from "react";
import { copyToClipboard } from "../../utils/function/linkShare";
import { ReactComponent as CopyIcon } from "../../asset/svg/copy.svg";
import "./ShareButton.scss";
import { useTranslation } from "react-i18next";
import { useLanguage } from "../../hook/useLanguage";

function ShareButton({
  shareLink = window.location.href,
  shareText = "",
  color = "white",
}) {
  const { t } = useTranslation();
  const { classLang } = useLanguage();

  const buttonRef = useRef<HTMLButtonElement>(null);

  const share = () => {
    copyToClipboard(
      shareLink,
      shareText,
      t("nft:detail:link"),
      t("nft:detail:linkFail")
    );
    // buttonRef.current?.blur();
  };

  return (
    <button
      className="share-button"
      ref={buttonRef}
      onTouchStart={(e) => e.currentTarget.focus()}
      style={{ color: color }}
    >
      <CopyIcon style={{ fill: color }} />
      <h5 className="eng">share</h5>
      <div className="share-linkcopy-wrapper">
        <div className={`share-linkcopy ${classLang}`} onClick={() => share()}>
          copy link
        </div>
      </div>
    </button>
  );
}
export default ShareButton;
