import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { decrypt } from "../../../utils/function/crypto";
import { toLocaleTime } from "../../../utils/function/time";
import { useBackground } from "../../../hook/useBackground";
import chainList from "../../../utils/data/chainList";
import Glitter from "../../../components/glitter/Glitter";
import BlurryLoadingImage from "../../../components/blurryLoadingImage/BlurryLoadingImage";
import Button from "../../../components/button/Button";
import "./index.scss";
import { useTranslation } from "react-i18next";
import { useLanguage } from "../../../hook/useLanguage";

function Success() {
  const { i18n, t } = useTranslation();
  const { classLang } = useLanguage();

  const navigation = useNavigate();
  const { integratedNftInfo } = useParams();
  const [nftInfo, setNftInfo] = useState<any>();
  const [nftCard, setNftCard] = useState<any>();
  useBackground({ backgroundStyle: "GRADIENT" });

  const loading = () => {
    switch (i18n.language) {
      case "ko":
        return (
          <>
            통합 NFT를
            <br />
            <b>{nftInfo.chainType}</b> 체인에서
            <br />
            발행했어요
          </>
        );
      case "en":
        return (
          <>
            Intergrated NFT is successfully created
            <br />
            in <b>{nftInfo.chainType}</b> chain
          </>
        );
    }
  };

  useEffect(() => {
    nftInfo &&
      setNftCard(
        chainList.find((el: any) => el.chainType === nftInfo.chainType)
      );
  }, [nftInfo]);

  useEffect(() => {
    setNftInfo(decrypt(integratedNftInfo));
  }, [integratedNftInfo]);

  return (
    <div className="integrated-nft-create-success">
      <Glitter />
      {nftInfo && (
        <div>
          {nftCard && (
            <BlurryLoadingImage
              preview={nftCard.card.preview}
              image={nftCard.card.image}
              alt="integrated nft"
              imageStyleClass="integrated-card-image"
            />
          )}
          <div className="congratulations">Congratulations!</div>
          <div className={`integrated-nft-text ${classLang}`}>{loading()}</div>
          <h2 className="eng integrated-nft-info-title">Info</h2>
          <div className="integrated-nft-info-wrapper">
            <div className="integrated-nft-info">
              <div className="integrated-nft-tx">TX</div>
              <div className="integrated-nft-info-content">
                <h6
                  className="content underline"
                  onClick={() => {
                    window.open(
                      `${
                        chainList.find(
                          (el) => el.chainType === nftInfo.chainType
                        )?.explorerUrl
                      }/${nftInfo.transactionHash}`
                    );
                  }}
                >
                  View TX
                </h6>
              </div>
            </div>
            <div className="integrated-nft-info">
              <div>Status</div>
              <div className="integrated-nft-info-content">Success</div>
            </div>
            <div className="integrated-nft-info">
              <div>Date</div>
              <div className="integrated-nft-info-content">
                {toLocaleTime(nftInfo.createdAt)}
              </div>
            </div>
          </div>
        </div>
      )}
      <Button
        theme="purple"
        text={t("intergrated:create:mypage")}
        onClick={() => {
          navigation("/mypage");
        }}
      />
    </div>
  );
}
export default Success;
