import { useState, ChangeEvent } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as EyeIcon } from "../../../asset/svg/eye.svg";
import { ReactComponent as EyeOffIcon } from "../../../asset/svg/eye-off.svg";
import "./charge.scss";
import { useLanguage } from "../../../hook/useLanguage";

interface Props {
  inputPasscode: string;
  handleInputPasscode: (e: ChangeEvent<HTMLInputElement>) => void;
  isValid: boolean | undefined;
}

const Passcode = ({ inputPasscode, handleInputPasscode, isValid }: Props) => {
  const { t } = useTranslation();
  const { classLang } = useLanguage();
  const [isPrivate, setIsPrivate] = useState(true);

  const handlePrivate = () => {
    setIsPrivate((prev) => !prev);
  };

  return (
    <div className="yrp-passcode-wrapper">
      <h5 className={`${classLang}`}>{t("yrp:charge:passcode")}</h5>
      <div className="passcode-input-wrapper">
        <input
          type={isPrivate ? "password" : "text"}
          onChange={handleInputPasscode}
          value={inputPasscode}
        />
        <span onClick={handlePrivate}>
          {isPrivate ? <EyeOffIcon /> : <EyeIcon />}
        </span>
      </div>
      {typeof isValid === "boolean" && !isValid && (
        <div className="fail-wrapper">
          <div className="circle" />
          <h6 className={classLang}>{t("yrp:charge:passcodeFail")}</h6>
        </div>
      )}
    </div>
  );
};

export default Passcode;
