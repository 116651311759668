import { useTranslation } from "react-i18next";
import { useYrp } from "../../../hook/useYrp";
import { ReactComponent as Noti } from "../../../asset/svg/noti.svg";
import { useLanguage } from "../../../hook/useLanguage";
import { useTransition } from "../../../hook/useTransition";
import { useEffect } from "react";

const YOURS_WALLET = process.env.REACT_APP_YOURS_MAIN_WALLET;

const PayResult = () => {
  const {
    selectedChain,
    valueOfCoinByInputYrp,
    inputAmount,
    guessTransactionFee,
    isLoading,
    fee,
    EVM_WALLET_ADDR,
  } = useYrp();
  const { t } = useTranslation();
  const { classLang } = useLanguage();
  const { isOpen, startTransition, transition, handleOpen } =
    useTransition(300);

  useEffect(() => {
    console.log(selectedChain);
    guessTransactionFee(
      EVM_WALLET_ADDR,
      YOURS_WALLET!,
      inputAmount,
      selectedChain
    );
  }, []);

  return (
    <div className="pay-result-wrapper">
      <div className="content-line">
        <h4 className={classLang}>{t("yrp:charge:accordian:result")}</h4>
        <h2 className="eng">
          {valueOfCoinByInputYrp(inputAmount, selectedChain).toLocaleString()}{" "}
          {selectedChain}
        </h2>
      </div>
      <div className="fee-box">
        <span>
          <h5>{t("yrp:charge:accordian:fee")}</h5>
          <div
            className="noti-wrapper"
            onMouseEnter={handleOpen}
            onMouseLeave={startTransition}
          >
            <Noti />
          </div>
          {isOpen && <Bubble transition={transition} />}
        </span>
        <span>
          {isLoading ? (
            <></>
          ) : (
            <>
              <h4 className="charge-fee eng">{Number(fee).toFixed(6)}</h4>
              <h4 className="eng">ETH</h4>
            </>
          )}
        </span>
      </div>
    </div>
  );
};

interface BubbleProps {
  transition: boolean;
}

const Bubble = ({ transition }: BubbleProps) => {
  const { t } = useTranslation();
  const { classLang } = useLanguage();

  return (
    <div className={`bubble-wrapper ${transition && "close"} ${classLang}`}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="21"
        height="25"
        viewBox="0 0 21 25"
        fill="none"
      >
        <path
          d="M7.72446 2.76594C8.74258 0.284056 12.2574 0.284052 13.2755 2.76593L20.4936 20.3614C21.3034 22.3354 19.8517 24.5 17.7181 24.5H3.28194C1.14828 24.5 -0.303388 22.3354 0.506399 20.3614L7.72446 2.76594Z"
          fill="#298BFF"
        />
      </svg>
      <div>{t("yrp:charge:accordian:feeDesc")}</div>
    </div>
  );
};

export default PayResult;
