import { useState, useEffect } from "react";

type ViewportType = "MOBILE" | "TABLET" | "PC";

const useDevice = (): ViewportType => {
  const [viewportType, setViewportType] = useState<ViewportType>("PC");

  useEffect(() => {
    setViewportType(getViewportType(window.innerWidth));

    const handleResize = () => {
      setViewportType(getViewportType(window.innerWidth));
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return viewportType;
};

const getViewportType = (width: number): ViewportType => {
  if (width <= 500) {
    return "MOBILE";
  } else if (width <= 1024) {
    return "TABLET";
  } else {
    return "PC";
  }
};

export default useDevice;
