import { useNavigate, useParams } from "react-router-dom";
import { useNft } from "../../hook/useNft";
import MiniHeader from "../../components/miniHeader/MiniHeader";
import "./index.scss";
import { useTranslation } from "react-i18next";
import { useLanguage } from "../../hook/useLanguage";

function NftSetting() {
  const { t } = useTranslation();
  const { classLang } = useLanguage();

  const navigation = useNavigate();
  const { nftId } = useParams();
  const { nftInfo } = useNft({ nftId: Number(nftId) });
  const settingInfoList = [
    { name: t("nft:menu:1"), location: "reward" },
    { name: t("nft:menu:2"), location: "admin" },
  ];

  return (
    <div className="nft-setting">
      <MiniHeader title={"Setting"} />
      <div className="nft-setting-content-wrapper show-content-smoothly">
        <img className="nft-image" src={nftInfo?.image} />
        <h2 className="nft-name eng">{nftInfo?.nftName}</h2>
        <div className="setting-button-wrapper">
          {settingInfoList.map((settingInfo, idx) => (
            <button
              key={idx}
              onClick={() => {
                navigation(settingInfo.location);
              }}
            >
              <h3 className={classLang}>{settingInfo.name}</h3>
            </button>
          ))}
        </div>
      </div>
    </div>
  );
}
export default NftSetting;
