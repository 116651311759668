import { NftInfo } from "NftType";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useLanguage } from "../../hook/useLanguage";

interface AdminAllProps {
  nftInfo: NftInfo;
  nftAdminPhotoList: any;
}

interface AdminElemProps {
  application: any;
  goToAdminPhotoDetail: Function;
}

function AdminElem({ application, goToAdminPhotoDetail }: AdminElemProps) {
  const { t } = useTranslation();

  return (
    <div className="nft-application-wrapper">
      <img className="profile-image" src={application.profileImage} />
      <h3 className="user-name">{application.name}</h3>

      <button
        className="goto-admin-detail"
        onClick={() => {
          goToAdminPhotoDetail(application.id);
        }}
      >
        {t("nft:auth:view")}
      </button>
    </div>
  );
}

function AdminAll({ nftInfo, nftAdminPhotoList }: AdminAllProps) {
  const { t } = useTranslation();
  const { classLang } = useLanguage();

  const navigation = useNavigate();

  const goToAdminPhotoDetail = (applicationId: number) => {
    navigation(`/nft/${nftInfo.id}/setting/admin?id=${applicationId}`);
  };

  return (
    <div className="nftadmin-all">
      <h2 className={`content-title ${classLang}`}>{t("nft:auth:method")}</h2>
      <h3 className={`content-subtitle ${classLang}`}>{t("nft:auth:email")}</h3>
      <h5 className={`nftadmin-description ${classLang}`}>
        {nftInfo?.options}
      </h5>

      <h2 className={`content-title ${classLang}`}>{t("nft:auth:request")}</h2>
      <div className="nft-application-container">
        {nftAdminPhotoList.length ? (
          nftAdminPhotoList.map((application: any, idx: number) => (
            <AdminElem
              application={application}
              goToAdminPhotoDetail={goToAdminPhotoDetail}
            />
          ))
        ) : (
          <h3 className="nft-application-empty">{t("nft:auth:empty")}</h3>
        )}
      </div>
    </div>
  );
}
export default AdminAll;
