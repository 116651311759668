import { ChangeEvent, useEffect, useState } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import {
  clearRedirectUrl,
  getRedirectUrl,
} from "../../utils/function/redirectUrl";
import {
  generateRandomMnemonic,
  generateWalletsFromMnemonic,
} from "../../utils/function/wallet";
import { SignupRequest } from "UserResquestType";
import { encryptMnemonic } from "../../utils/function/crypto";
import { usePassCode } from "../../hook/usePassCodeInput";
import { useGetSocialInfo } from "./useGetSocialInfo";
import { setShowAlertInfo } from "../../utils/function/showAlert";
import UserApi from "../../apis/UserApi";
import InputForm from "./InputForm";
import PassCodeInput from "../../components/passCode/PassCodeInput";
import PassCodeConfirm from "../../components/passCode/PassCodeConfirm";
import Loading from "../../components/loading/OverlayLoading";
import { ReactComponent as ArrowLeft } from "../../asset/svg/arrow-left.svg";
import "./index.scss";
import { ChainWallets } from "WalletType";
import { chainType } from "ChainType";
import { useTranslation } from "react-i18next";
import LangForm from "./LangForm";
import { Language } from "LanguageType";
import { LanguageList } from "../../utils/data/language";
import { useLanguage } from "../../hook/useLanguage";

function Signup() {
  const { t, i18n } = useTranslation();
  const { classLang } = useLanguage();

  const [searchParams, setSearchParams] = useSearchParams();
  const userApi = new UserApi();
  const navigation = useNavigate();
  const { social, snsId, profileImage, email } = useGetSocialInfo({
    failAction: () => getSocialInfoFailAction(),
  });
  const { passCode, setPassCode, isValid } = usePassCode();
  const [phoneNumber, setPhoneNumber] = useState("");
  const [nickname, setNickname] = useState("");
  const [agreeToTermList, setAgreeToTermList] = useState<String[]>([]);
  const [language, setLanguage] = useState<Language>();

  const checkLang = (lang: string): boolean => {
    return language?.langName === lang;
  };

  const changeLang = (lang: string) => {
    const newLang = LanguageList.find((language) => language.langName === lang);
    i18n.changeLanguage(newLang?.langType);
    setLanguage(newLang);
  };

  const [loading, setLoading] = useState(false);
  const [pageMode, setPageMode] = useState("PASSCODE");

  const getSocialInfoFailAction = () => {
    navigation("/signup");
    setShowAlertInfo(t("login:after:failure"), false);
  };

  useEffect(() => {
    // page mode 설정하기
    setPageMode(searchParams.get("pageMode") || "LANGUAGE");
  }, [searchParams]);

  const goToInput = () => {
    setSearchParams({ ...searchParams, pageMode: "INPUT" });
  };
  const goToPassCode = () => {
    setSearchParams({ ...searchParams, pageMode: "PASSCODE" });
  };
  const goToConfirmPassCode = () => {
    setSearchParams({ ...searchParams, pageMode: "CONFIRM_PASSCODE" });
  };

  const generateWallets = () => {
    const mnemonic = generateRandomMnemonic();
    const wallets: any = generateWalletsFromMnemonic(mnemonic);
    const addressList: ChainWallets = [];

    for (let chain in wallets) {
      addressList.push({
        chainType: chain as chainType,
        address: wallets[chain].address,
      });
    }
    const secret = encryptMnemonic(mnemonic, passCode);

    return { addressList, secret };
  };

  const signup = async () => {
    try {
      setLoading(true);
      const wallets = generateWallets();

      const newUserData: SignupRequest = {
        snsId: snsId,
        nickname: nickname,
        profileImage: profileImage,
        email: email,
        phone: phoneNumber.split("-").join(""),
        social: social,
        isMarketing: agreeToTermList.includes("MARKETING"),
        walletAddress: wallets.addressList,
        secret: wallets.secret,
        language: language?.nation!,
      };

      await userApi.signup(newUserData);

      // 회원가입 성공 후 redirect url 또는 마이페이지로 이동
      let redirectUrl = getRedirectUrl();
      localStorage.setItem("newUser", "Hello newbie 😎");
      window.location.href = redirectUrl ? redirectUrl : `/mypage`;
      clearRedirectUrl();
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setShowAlertInfo(t("login:after:failure"), false);
      console.log(err);
    }
  };

  const pageRenderer = () => {
    switch (pageMode) {
      case "LANGUAGE":
        return (
          <LangForm
            check={checkLang}
            isCheck={language ? true : false}
            change={changeLang}
            goToNextPage={goToInput}
          />
        );
      case "INPUT":
        return (
          <InputForm
            setPhoneNumber={setPhoneNumber}
            nickname={nickname}
            setNickname={setNickname}
            agreeToTermList={agreeToTermList}
            setAgreeToTermList={setAgreeToTermList}
            goToNextPage={goToPassCode}
          />
        );
      case "PASSCODE":
        return (
          <PassCodeInput
            title={t("login:second:passcode")}
            description={t("login:second:desc")}
            passCode={passCode}
            setPassCode={setPassCode}
            isValid={isValid}
            buttonText={t("login:button:next")}
            buttonAction={goToConfirmPassCode}
          />
        );
      case "CONFIRM_PASSCODE":
        return (
          <PassCodeConfirm
            title={t("login:third:passcode")}
            description={t("login:third:desc")}
            passCode={passCode}
            confirmText={t("login:button:start")}
            confirmAction={signup}
          />
        );
      default:
    }
  };

  return (
    <div className="signup">
      {loading && <Loading />}
      {pageMode !== "LANGUAGE" && (
        <div className="signup-header">
          <ArrowLeft
            className="go-back"
            onClick={() => navigation("/landing")}
          />
          <h3 className={classLang}>{t("login:header")}</h3>
        </div>
      )}
      {pageRenderer()}
    </div>
  );
}
export default Signup;
