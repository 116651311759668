import { useGoogleLogin } from "@react-oauth/google";
import UserApi from "../../apis/UserApi";
import { setShowAlertInfo } from "../../utils/function/showAlert";
import Button from "../button/Button";
import { ReactComponent as GoogleLogo } from "../../asset/svg/google-logo.svg";
import { useTranslation } from "react-i18next";

function GoogleLoginButton() {
  const { t } = useTranslation();
  const userApi = new UserApi();
  const login = useGoogleLogin({
    onSuccess: (codeResponse) => {
      userApi.loginHandler("GOOGLE", codeResponse.access_token);
    },
    onError: (error) => {
      setShowAlertInfo(t("landing:popup:failLogin"), false);
    },
  });

  return (
    <Button
      text={t("landing:button:google")}
      theme="white"
      onClick={() => login()}
      icon={<GoogleLogo className="google-logo" />}
    />
  );
}
export default GoogleLoginButton;
