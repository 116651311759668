import { useSelector } from "react-redux";
import MenuList from "../../components/menuList/MenuList";
import MiniHeader from "../../components/miniHeader/MiniHeader";
import WalletElem from "./WalletElem";
import { ReactComponent as PlantIcon } from "../../asset/svg/plant.svg";
import { ReactComponent as LockIcon } from "../../asset/svg/lock.svg";
import "./index.scss";
import { useTranslation } from "react-i18next";
import { useLanguage } from "../../hook/useLanguage";
import { useExternalWallet } from "../../hook/useExternalWallet";
import wallet from "../../asset/svg/wallet-line.svg";
import arrow from "../../asset/svg/arrow-right-black.svg";
import { useState } from "react";
import { WalletModal } from "../../components/ExternalWallet";
import { useNavigate } from "react-router-dom";
import { Popup } from "../../components/popup";

function YoursWallet() {
  const { t } = useTranslation();
  const { classLang } = useLanguage();
  const { externalWalletInfo, disconnectWallet } = useExternalWallet();
  const [modalOpen, setModalOpen] = useState(false);
  const [disconnectModalOpen, setDisconnectModalOpen] = useState(false);
  const navigation = useNavigate();

  const wallets = useSelector((state: any) => state.wallet);

  const handleWalletButton = () => {
    if (!externalWalletInfo.isConnected) setModalOpen(true);
    else navigation("/external");
  };

  const handleWalletDisconnect = () => {
    disconnectWallet(externalWalletInfo.chain);
    setDisconnectModalOpen(false);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const menuList = [
    {
      name: t("wallet:yours:menus:1"),
      link: "/wallet/tutorial",
      icon: <PlantIcon />,
    },
    {
      name: t("wallet:yours:menus:2"),
      link: "/wallet/privacy",
      icon: <LockIcon />,
    },
  ];

  const chainList = [
    { name: "Ethereum /Polygon /Klaytn /Aurora", chainType: "Ethereum" },
    // { name: "Solana", chainType: "Solana" },
    { name: "Aptos", chainType: "Aptos" },
  ];

  const modalConfig = {
    title: t("external:disconnect:popup"),
    closeModal: () => setDisconnectModalOpen(false),
    approve: handleWalletDisconnect,
    deny: () => setDisconnectModalOpen(false),
    approveText: t("external:disconnect:confirm"),
    denyText: t("external:disconnect:deny"),
  };

  return (
    <div className="yours-wallet-page">
      <MiniHeader title="Yours Wallet" />
      <h3 className={`yours-wallet-title ${classLang}`}>
        {t("wallet:yours:address")}
      </h3>
      <div className="yours-wallet-container">
        {chainList.map((chainInfo: any, idx: number) => (
          <WalletElem
            title={chainInfo.name}
            chainType={chainInfo.chainType}
            address={wallets[chainInfo.chainType]}
            key={idx}
          />
        ))}
        <button
          onClick={handleWalletButton}
          className="external-wallet-button connect"
        >
          {!externalWalletInfo.isConnected && (
            <img src={wallet} alt="wallet-icon" />
          )}
          <h5 className={classLang}>
            {externalWalletInfo.isConnected
              ? t("external:buttons:view")
              : t("external:buttons:connect")}
          </h5>
          {externalWalletInfo.isConnected && (
            <img src={arrow} alt="wallet-icon" className="wallet-icon" />
          )}
        </button>
        {externalWalletInfo.isConnected && (
          <button
            onClick={() => setDisconnectModalOpen(true)}
            className="external-wallet-button disconnect"
          >
            <h5 className={classLang}>{t("external:buttons:disconnect")}</h5>
          </button>
        )}
      </div>
      <MenuList menuList={menuList} />
      {modalOpen && <WalletModal onClose={closeModal} />}
      {disconnectModalOpen ? <Popup {...modalConfig} /> : <></>}
    </div>
  );
}
export default YoursWallet;
