import { useTranslation } from "react-i18next";
import { Input } from "../../../components/yrp";
import "./charge.scss";
import { ChainOption, YrpOption } from "../../../components/yrp/Input/Option";
import { MINIMUM, useYrp } from "../../../hook/useYrp";
import { useEffect } from "react";

const AmountInputSection = () => {
  const { t } = useTranslation();
  const {
    isSufficient,
    handleInputAmount,
    inputAmount,
    initializeValues,
    valueOfCoinByInputYrp,
    selectedChain,
    restCoinByInputYrp,
    checkBalances,
    isLoading,
  } = useYrp();

  useEffect(() => {
    initializeValues();
    checkBalances();
  }, []);

  return (
    <div className="charge-page" style={{ minHeight: "70vh" }}>
      <Input
        caption={t("yrp:charge:point")}
        option={YrpOption()}
        onChange={handleInputAmount}
        disabled={isLoading}
        warning={inputAmount < MINIMUM ? t("yrp:charge:minimum") : undefined}
        value={inputAmount.toLocaleString()}
      />
      <Input
        caption={t("yrp:charge:pay")}
        option={ChainOption()}
        overegg={restCoinByInputYrp(inputAmount, selectedChain)}
        isSufficient={isSufficient(inputAmount, selectedChain)}
        disabled
        isLoading={isLoading}
        value={valueOfCoinByInputYrp(inputAmount, selectedChain)}
      />
    </div>
  );
};

export default AmountInputSection;
