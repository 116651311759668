import { useExternalWallet } from "../../../hook/useExternalWallet";
import { ReactComponent as CopyIcon } from "../../../asset/svg/copy-text.svg";
import "./walletHeader.scss";
import { ellipsisMiddle } from "../../../utils/function/ellipsisMiddle";
import { copyText } from "../../../utils/function/linkShare";
import { useTranslation } from "react-i18next";

const WalletHeader = () => {
  const { getWalletImage, externalWalletInfo } = useExternalWallet();
  const { t } = useTranslation();

  return (
    <div className="external-wallet-header">
      <h2 className="eng">Address</h2>
      <div
        className="address-wrapper"
        onClick={() =>
          copyText(externalWalletInfo.address, t("wallet:yours:toasts:copied"))
        }
      >
        <img src={getWalletImage()} alt="wallet-logo" className="wallet-logo" />
        <div className="address-inner">
          <h4 className="eng">{ellipsisMiddle(externalWalletInfo.address)}</h4>
          <CopyIcon className="copy-logo" />
        </div>
      </div>
    </div>
  );
};

export default WalletHeader;
