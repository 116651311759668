export const DATA_TYPES = {
  auth: "auth",
  myId: "myId",
  name: "name",
  introduction: "introduction",
  email: "email",
  picture: "picture",
  phoneNumber: "phoneNumber",
  admin: "admin",
  secret: "secret",
  language: "language",
};

export const ACTION_TYPES = {
  SET_AUTH: "SET_AUTH",
  SET_ID: "SET_ID",
  SET_NAME: "SET_NAME",
  SET_EMAIL: "SET_EMAIL",
  SET_PROFILE_IMAGE: "SET_PROFILE_IMAGE",
  SET_PHONE_NUMBER: "SET_PHONE_NUMBER",
  SET_ADMIN: "SET_ADMIN",
  SET_INTRODUCTION: "SET_INTRODUCTION",
  SET_SECRET: "SET_SECRET",
  SET_LANGUAGE: "SET_LANGUAGE",
};
