import { useNavigate } from "react-router-dom";
import { ReactComponent as Logo } from "../../asset/svg/logo.svg";
import { ReactComponent as PlusIcon } from "../../asset/svg/plus-circle.svg";
import { ReactComponent as CoinIcon } from "../../asset/svg/coin.svg";
import userDefaultProfile from "../../asset/image/user-default-profile.png";
import "./index.scss";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { LanguageList } from "../../utils/data/language";
import { useEffect } from "react";
import { useYrp } from "../../hook/useYrp";

interface NavbarProps {
  /**
   * Whether user is authenticated
   */
  auth: boolean;
  /**
   * User profile image URL
   */
  profileImage: string;
}

/**
 * Navbar UI component
 */
function Navbar({ auth, profileImage }: NavbarProps) {
  const { i18n } = useTranslation();
  const { totalAmount, goYrpPage, getTotalAmount } = useYrp();

  const userData = useSelector((state: any) => state.userData);
  const navigation = useNavigate();

  const settingLang = () => {
    const newLang = LanguageList.find(
      (language) => language.nation === userData.language
    );

    i18n.changeLanguage(newLang?.langType);
    document.documentElement.lang = newLang?.langType!;
  };

  const restYrp = (amount: number): string => {
    if (amount < 100000) return amount.toLocaleString();

    if (amount < 1000000) {
      return Math.round(amount / 1000) + "K";
    } else {
      return Math.round(amount / 1000000) + "M";
    }
  };

  useEffect(() => {
    settingLang();
  }, [userData]);

  useEffect(() => {
    if (auth) getTotalAmount();
  }, [auth]);

  return (
    <div className="navbar" id="navbar">
      <Logo className="navbar-logo" onClick={() => navigation("/mypage")} />
      {auth && (
        <div className="navbar-right-wrapper">
          <button onClick={goYrpPage} className="navbar-coin-badge">
            <span className="amount">{restYrp(totalAmount)}</span>
            <span className="unit">YRP</span>
            <CoinIcon style={{ marginTop: 3 }} />
          </button>
          <button
            className="navbar-create-badge"
            onClick={() => {
              navigation("/nft/create");
            }}
          >
            Create NFT <PlusIcon />
          </button>
          <img
            className="navbar-profile-image"
            alt="profile"
            src={profileImage}
            onClick={() => navigation("/profile/edit")}
            onError={(e) => {
              e.currentTarget.src = userDefaultProfile;
            }}
          />
        </div>
      )}
    </div>
  );
}
export default Navbar;
