import { ComponentProps, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useOutsideClose } from "../../../hook/useOutsideClose";
import "./Bubble.scss";

interface Props {
  handleClose: () => void;
}

const Bubble = ({ handleClose }: Props) => {
  const { t } = useTranslation();
  const ref = useRef<HTMLDivElement>(null);

  useOutsideClose(ref, handleClose);

  return (
    <div ref={ref} className="benefit-bubble-position">
      <div className="benefit-bubble-wrapper">
        <Xmark onClick={handleClose} />
        <h6>{t("nft:benefits:add:gating:bubble")}</h6>
        <Polygon />
      </div>
    </div>
  );
};

const Polygon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="20"
      viewBox="0 0 22 20"
      fill="none"
      className="benefit-bubble-polygon"
    >
      <path
        d="M7.36115 18.3928L20.6246 4.36014C21.83 3.08487 20.9259 0.986328 19.1711 0.986328H2.17731C0.863897 0.986328 -0.0929871 2.23081 0.244444 3.50015L3.97479 17.5328C4.37724 19.0467 6.2851 19.5313 7.36115 18.3928Z"
        fill="url(#paint0_linear_5501_20783)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_5501_20783"
          x1="11.6948"
          y1="8.59164"
          x2="24.8216"
          y2="-14.7985"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="1" stop-opacity="0" />
        </linearGradient>
      </defs>
    </svg>
  );
};

const Xmark = (props: ComponentProps<"svg">) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      className="benefit-bubble-xmark"
      {...props}
    >
      <path
        d="M10.192 0.34375L5.94903 4.58575L1.70703 0.34375L0.29303 1.75775L4.53503 5.99975L0.29303 10.2418L1.70703 11.6558L5.94903 7.41375L10.192 11.6558L11.606 10.2418L7.36403 5.99975L11.606 1.75775L10.192 0.34375Z"
        fill="#828282"
      />
    </svg>
  );
};

export default Bubble;
