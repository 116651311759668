import { ComponentProps } from "react";
import { useTranslation } from "react-i18next";
import "./formboard.scss";

const FormBoard = (props: ComponentProps<"div">) => {
  const { i18n } = useTranslation();

  const openForm = () => {
    window.open(
      "https://forms.gle/ZbLn6T8AwgZZ4qMHA",
      "_blank",
      "noopener, noreferrer"
    );
  };

  if (i18n.language === "en") {
    return (
      <div className="google-form-wrapper" {...props}>
        <span className="form-info-text">
          {`If you have completed the transfer, please `}
          <span onClick={openForm} className="form-info-text link">
            click here.
          </span>
        </span>
      </div>
    );
  }
  if (i18n.language === "ko") {
    return (
      <div className="google-form-wrapper" {...props}>
        <span className="form-info-text">
          {`Transfer를 완료했다면 `}
          <span onClick={openForm} className="form-info-text link">
            이 곳
          </span>
          을 눌러서 알려주세요.
        </span>
      </div>
    );
  }

  return <></>;
};

export default FormBoard;
