import { useState } from "react";
import Button from "../../components/button/Button";
import InputLength from "../../components/inputLength/InputLength";
import Modal from "../../components/modal/Modal";
import "./DiscardModal.scss";
import { useTranslation } from "react-i18next";

type discardModalProp = {
  closeModal: Function;
  discardAction: Function;
};

function DiscardModal({ closeModal, discardAction }: discardModalProp) {
  const { t } = useTranslation();

  const [reason, setReason] = useState("");
  const maxLength = 200;
  const placeholder = `${t("nft:auth:reject:placeholder1")} ${maxLength} ${t(
    "nft:auth:reject:placeholder2"
  )}`;

  return (
    <Modal closeModal={closeModal}>
      <div className="modal-wrapper nftphoto-discard-modal">
        <h4 className="modal-title">{t("nft:auth:reject:detail")}</h4>
        <div className="modal-body">
          <div className="reject-reason-input-wrapper">
            <textarea
              placeholder={placeholder}
              rows={4}
              maxLength={maxLength}
              value={reason}
              onChange={(e) => {
                setReason(e.target.value);
              }}
            />
          </div>
          <InputLength currLength={reason.length} maxLength={maxLength} />
        </div>
        <div className="modal-footer">
          <Button
            theme="black"
            text={t("nft:auth:reject:cancel")}
            onClick={() => {
              closeModal();
            }}
          />
          <Button
            theme="green"
            text={t("nft:auth:reject:confirm")}
            onClick={() => {
              discardAction(reason);
            }}
          />
        </div>
      </div>
    </Modal>
  );
}
export default DiscardModal;
