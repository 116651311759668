import { NftInfo } from "NftType";
import { useNavigate } from "react-router-dom";
import { toLocaleTime } from "../../../utils/function/time";
import chainList from "../../../utils/data/chainList";
import Success from "../../../components/success/Success";
import { useTranslation } from "react-i18next";

type deploySuccessProp = {
  nftInfo: NftInfo;
  deployInfo: any;
};

function DeployRewardSuccess({ nftInfo, deployInfo }: deploySuccessProp) {
  const { t } = useTranslation();
  const navigation = useNavigate();

  return (
    <Success
      image={nftInfo.image}
      title={
        <>
          {t("nft:benefits:update:0")}
          <b>{nftInfo.nftName}</b>
          {t("nft:benefits:update:1")}
          <br />
          {t("nft:benefits:update:2")}
        </>
      }
      buttonText={t("nft:benefits:buttons:detail")}
      buttonAction={() => {
        navigation(`/nft/${nftInfo.id}/detail`);
      }}
    >
      <div className="deploy-info-container flex-column-20">
        <h2 className="deploy-info-title eng">Info</h2>
        <div className="deploy-info-row">
          <h6 className="title gr-4">TX</h6>
          <h6
            className="content underline"
            onClick={() => {
              window.open(
                `${
                  chainList.find((el) => el.chainType === nftInfo.chainType)
                    ?.explorerUrl
                }/${deployInfo.transactionHash}`
              );
            }}
          >
            View TX
          </h6>
        </div>
        <div className="deploy-info-row">
          <h6 className="title">Status</h6>
          <h6 className="content">Success</h6>
        </div>
        <div className="deploy-info-row">
          <h6 className="title">Date</h6>
          <h6 className="content">{toLocaleTime(deployInfo?.date)}</h6>
        </div>
      </div>
    </Success>
  );
}
export default DeployRewardSuccess;
