import { useCallback, useLayoutEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useNft } from "../../hook/useNft";
import { copyToClipboard } from "../../utils/function/linkShare";
import { setMetaTags } from "../../utils/function/setMetatags";
import { setShowAlertInfo } from "../../utils/function/showAlert";
import { ellipsisMiddle } from "../../utils/function/ellipsisMiddle";

import Button from "../../components/button/Button";
import StatusModal from "../../components/statusModal/StatusModal";
import MiniHeader from "../../components/miniHeader/MiniHeader";
import ProfileListModal from "../../components/profileListModal";
import RewardElem from "../../components/reward";

import mailImg from "../../asset/image/mail.png";
import cameraImg from "../../asset/image/camera.png";
import { ReactComponent as UserIcon } from "../../asset/svg/user.svg";
import { ReactComponent as CopyIcon } from "../../asset/svg/copy.svg";
import { ReactComponent as SettingIcon } from "../../asset/svg/setting.svg";
import { ReactComponent as SendIcon } from "../../asset/svg/send.svg";
import "./index.scss";
import { Benefit } from "../../components/welcome";
import AlertHandler from "../../components/alert/AlertHandler";
import { Trans, useTranslation } from "react-i18next";
import { useLanguage } from "../../hook/useLanguage";
import { useLock } from "../../hook/useLock";
import ReactPortal from "../../components/portal";
import BlurBackground from "../../components/bottomSheet/BlurBackground";
import { Popup } from "../../components/popup";
import { formatLocaleString } from "../../utils/function/time";

function NftDetail() {
  const { t } = useTranslation();
  const { classLang } = useLanguage();

  const navigation = useNavigate();
  const { nftId } = useParams();
  const {
    nftInfo,
    nftOwnerList,
    userOwnThisNft,
    userCreateThisNft,
    userTransferThisNft,
    userPhotoPending,
  } = useNft({ nftId: Number(nftId) });
  const [showOwnerModal, setShowOwnerModal] = useState<boolean>(false);
  const [showNftGetModal, setShowNftGetModal] = useState<boolean>(false);
  const [nftAuthInfo, setNftAuthInfo] = useState<any>();
  const [isWelcome, setIsWelcome] = useState<boolean>(false);
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const { isLocked } = useLock(nftId!, userOwnThisNft);

  const nftAuthInfoList = [
    {
      authType: 1,
      modalImage: mailImg,
      modalText: (
        <>
          <Trans
            i18nKey="mypage:detail:email:content"
            components={{ br: <br />, b: <b /> }}
          />
        </>
      ),
      buttonText: t("mypage:detail:email:button"),
      buttonAction: () => {
        navigation(`/nft/${nftId}/get`);
      },
    },
    {
      authType: 2,
      modalImage: cameraImg,
      modalText: (
        <>
          <Trans
            i18nKey="mypage:detail:image:content"
            components={{ br: <br />, b: <b /> }}
          />
        </>
      ),
      buttonText: t("mypage:detail:image:button"),
      buttonAction: () => {
        navigation(`/nft/${nftId}/get`);
      },
    },
  ];

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const gettingWelcomeNft = () => {
    closeModal();
    navigation(`/nft/0/welcome`);
  };

  const transferHandler = () => {
    if (userTransferThisNft) {
      // transfer 한 NFT인 경우
      setShowAlertInfo(t("landing:popup:failTransfer"), false);
    } else {
      // transfer 하지 않은 NFT인 경우
      navigation(`/nft/${nftId}/transfer`);
    }
  };

  const getNftModalHandler = () => {
    if (isWelcome) {
      //welcome nft인 경우 deploy 페이지로 이동하고 함수 종료
      openModal();
      return;
    }

    setNftAuthInfo(
      nftAuthInfoList.find((el: any) => el.authType === nftInfo.authType)
    );
    setShowNftGetModal(true);
  };

  const settingIsWelcome = () => {
    if (nftId === "0") setIsWelcome(true);
    else setIsWelcome(false);
  };

  const clipboardLink = () => {
    copyToClipboard(
      window.location.href,
      nftInfo?.nftName,
      t("nft:detail:link"),
      t("nft:detail:linkFail")
    );
  };

  const modalConfig = {
    title: t("mypage:welcome:modal:title"),
    closeModal: closeModal,
    approve: gettingWelcomeNft,
    deny: closeModal,
    approveText: t("mypage:welcome:modal:confirm"),
    denyText: t("mypage:welcome:modal:close"),
  };

  const SendButton = useCallback(() => {
    if (isWelcome) return <></>;
    else
      return (
        <>
          {
            /* 유저가 받은 NFT인 경우 -> transfer 페이지로 갈 수 있도록 */
            !isLocked && (
              <SendIcon
                className="nft-detail-transfer"
                onClick={() => {
                  transferHandler();
                }}
              />
            )
          }
          {
            /* 자신이 만든 사진 인증 NFT인 경우에 -> admin 페이지로 갈 수 있도록 */
            !!userCreateThisNft && (
              <SettingIcon
                className="nft-detail-admin"
                onClick={() => {
                  navigation(`/nft/${nftId}/setting`);
                }}
              />
            )
          }
        </>
      );
  }, [isWelcome, userOwnThisNft, userCreateThisNft, nftId, isLocked]);

  useLayoutEffect(() => {
    setMetaTags({
      title: "Yours NFT",
      description: "Yours의 NFT를 확인하세요!",
    });
    settingIsWelcome();
  }, []);

  return (
    <>
      {showOwnerModal && (
        <ProfileListModal
          closeModal={() => {
            setShowOwnerModal(false);
          }}
          modalTitle="Owner"
          profileList={nftOwnerList}
        />
      )}
      {showNftGetModal && (
        <StatusModal
          modalImage={nftAuthInfo.modalImage}
          modalText={nftAuthInfo.modalText}
          buttonText={nftAuthInfo.buttonText}
          buttonAction={nftAuthInfo.buttonAction}
          closeModal={() => setShowNftGetModal(false)}
        />
      )}
      <div className="nft-detail-page">
        <MiniHeader title={nftInfo?.nftName}>
          <div className="header-icon-wrapper">
            <SendButton />
          </div>
        </MiniHeader>
        <div className="nft-detail-content-wrapper">
          <img className="nft-image" src={nftInfo?.image} />
          <div className="nft-detail-button-wrapper">
            <button
              className="nft-owner"
              onClick={() => {
                setShowOwnerModal(true);
              }}
            >
              <UserIcon />
              <span>
                <b>{nftInfo?.numberOfOwners}</b> owners
              </span>
            </button>
            <button
              className="nft-share"
              // onTouchStart={(e)=>e.currentTarget.focus()}
              onClick={clipboardLink}
              onTouchStart={clipboardLink}
            >
              <CopyIcon />
              <span>share</span>
              {/* <div className="nft-linkcopy-wrapper">
                            <div 
                                className="nft-linkcopy"
                                onClick={()=>{copyToClipboard(window.location.href, nftInfo?.nftName)}}
                                onTouchStart={()=>{copyToClipboard(window.location.href, nftInfo?.nftName)}}
                            >
                                copy link
                            </div>
                        </div> */}
            </button>
          </div>
          <div className="nft-detail-description">{nftInfo?.description}</div>

          {nftInfo?.isDeployed && nftId !== "0" && (
            <div className="nft-reward-wrapper">
              <div className="nft-reward-title-wrapper">
                <div className="nft-reward-title">Info</div>
              </div>
              <div className="nft-info-wrapper flex-column-20">
                {nftInfo?.chainType !== "Aptos" && (
                  <div className="nft-info-row">
                    <div className="title">Contract Address</div>
                    <div className="content">
                      {ellipsisMiddle(nftInfo.nftAddress as string)}
                    </div>
                  </div>
                )}
                {nftInfo?.chainType !== "Solana" &&
                  nftInfo?.chainType !== "Aptos" && (
                    <div className="nft-info-row">
                      <div className="title">Token Standard</div>
                      <div className="content">ERC 721</div>
                    </div>
                  )}
                <div className="nft-info-row">
                  <div className="title">Date</div>
                  <div className="content">
                    {nftInfo &&
                      nftInfo.createdAt &&
                      formatLocaleString(nftInfo?.createdAt as string)}
                  </div>
                </div>
                <div className="nft-info-row">
                  <div className="title">Chain</div>
                  <div className="content">{nftInfo?.chainType}</div>
                </div>
              </div>
            </div>
          )}
          <div className="nft-reward-wrapper">
            <div className="nft-reward-title-wrapper">
              <div className="nft-reward-title">Benefit</div>
              <span className="nft-reward-length">
                {nftInfo?.numberOfRewards}
              </span>
            </div>
            <div className="nft-reward-container">
              {isWelcome ? (
                <Benefit nftId={nftId!} />
              ) : nftInfo.rewards?.length ? (
                nftInfo.rewards.map((reward: any, idx: number) => (
                  <RewardElem
                    nftId={nftInfo.id}
                    nftName={nftInfo.nftName}
                    reward={reward}
                    isMine={userOwnThisNft}
                    key={idx}
                  />
                ))
              ) : (
                <div className={`nft-reward-empty ${classLang}`}>
                  {t("mypage:empty:benefitCaption")}
                </div>
              )}
            </div>
          </div>
        </div>

        {!!(userCreateThisNft && !nftInfo?.isDeployed) ? (
          <Button
            className="get-nft-button"
            theme="purple"
            onClick={() => {
              navigation(`/nft/${nftId}/setting/reward`);
            }}
            text={t("mypage:buttons:benefits")}
          />
        ) : nftInfo?.isDeployed || isWelcome ? (
          <Button
            className="get-nft-button"
            theme="purple"
            onClick={() => getNftModalHandler()}
            disabled={userOwnThisNft || userPhotoPending}
            text={
              userOwnThisNft
                ? t("mypage:buttons:end")
                : userPhotoPending
                ? t("mypage:buttons:waiting")
                : t("mypage:buttons:go")
            }
          />
        ) : (
          <></>
        )}
      </div>
      <AlertHandler />
      {modalOpen && (
        <ReactPortal wrapperId="bottom-sheet">
          <BlurBackground>
            <Popup {...modalConfig} />
          </BlurBackground>
        </ReactPortal>
      )}
    </>
  );
}
export default NftDetail;
