export const timeToString = (sec: number) => {
  const min = Math.floor(sec / 60);
  const second = sec % 60;
  return `${min < 10 ? "0" + min : min}:${second < 10 ? "0" + second : second}`;
};

export const toLocaleTime = (utcDateTime: string) => {
  return new Date(utcDateTime).toLocaleString();
};

export const formatDateToUST = (isoString: string) => {
  const date = new Date(isoString);

  const year = date.getUTCFullYear();
  const month = String(date.getUTCMonth() + 1).padStart(2, "0"); // Month is 0-indexed, hence +1
  const day = String(date.getUTCDate()).padStart(2, "0");
  const hours = String(date.getUTCHours()).padStart(2, "0");
  const minutes = String(date.getUTCMinutes()).padStart(2, "0");
  const seconds = String(date.getUTCSeconds()).padStart(2, "0");

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds} UST`;
};

export const formatLocaleString = (date: string) => {
  const currentLocale = document.documentElement.lang || "ko";
  const currentDate = new Date(date).toLocaleString(currentLocale);
  return currentDate;
};
