import { useLanguage } from "../../../hook/useLanguage";
import Button from "../../button/Button";
import Modal from "../../modal/Modal";
import "./index.scss";
import { ReactComponent as Xmark } from "../../../asset/svg/x-mark-circle.svg";
import { useTranslation } from "react-i18next";
import sign from "../../../asset/image/notentrysign.png";
import { copyText } from "../../../utils/function/linkShare";
import { chainType } from "ChainType";

export type DisabledPopupProps = {
  closeModal: () => void;
  chainType: chainType;
};

/**
 * disabled-popup UI component
 */
export const DisabledPopup = ({
  closeModal,
  chainType,
}: DisabledPopupProps) => {
  const { classLang } = useLanguage();
  const { t } = useTranslation();

  const handleButton = () => {
    switch (chainType) {
      case "Aptos":
        copyText(
          process.env.REACT_APP_YOURS_MAIN_WALLET_APTOS!,
          t("wallet:yours:toasts:copied")
        );
        break;
      default:
        copyText(
          process.env.REACT_APP_YOURS_MAIN_WALLET!,
          t("wallet:yours:toasts:copied")
        );
        break;
    }
    closeModal();
  };

  return (
    <Modal closeModal={closeModal}>
      <div className="modal-wrapper disabled-popup">
        <img src={sign} alt="not-entry-sign" className="not-entry-sign" />
        <div
          className={`disabled-popup-title disabled-popup-title-${classLang}`}
        >
          {t("external:disabled:ready")}
        </div>
        <Button
          className="disabled-popup-button"
          theme="purple"
          text={t("external:disabled:copy")}
          onClick={handleButton}
        />
        <Xmark onClick={closeModal} className="modal-x-mark" />
      </div>
    </Modal>
  );
};
