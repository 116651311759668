import { useTranslation } from "react-i18next";
import Button from "../../../components/button/Button";
import MiniHeader from "../../../components/miniHeader/MiniHeader";
import DeployRewardElem from "../../../components/reward/DeployRewardElem";
import { useLanguage } from "../../../hook/useLanguage";

type confirmNftInfoProp = {
  deployNft: Function;
  rewardList: any;
};

function ConfirmRewardInfo({ deployNft, rewardList }: confirmNftInfoProp) {
  const { t } = useTranslation();
  const { classLang } = useLanguage();

  return (
    <div className="confirm-nft-info">
      <MiniHeader title={t("nft:benefits:confirm:header2")} />
      <div className="flex-column-20 show-content-smoothly">
        <div className="nft-info-title">
          <h3 className={`title ${classLang}`}>
            {t("nft:benefits:confirm:benefitCaption")}
          </h3>
          <h6 className="gn">{t("nft:benefits:confirm:benefitWarning")}</h6>
        </div>
        <div className="reward-container flex-column-20">
          {rewardList?.length ? (
            rewardList.map((reward: any, idx: number) => (
              <DeployRewardElem
                key={idx}
                nftId={reward.nftId}
                rewardId={reward.id}
                overflowHidden={false}
              />
            ))
          ) : (
            <div className="reward-empty">
              <h3 className="reward-empty-text gr-4">
                {t("nft:benefits:empty:warning")}
              </h3>
            </div>
          )}
        </div>
      </div>
      <Button
        theme="purple"
        sticky={true}
        onClick={() => deployNft()}
        text={t("nft:benefits:buttons:update")}
      />
    </div>
  );
}
export default ConfirmRewardInfo;
