import Modal from "../../../components/modal/Modal";
import fireworkImg from "../../../asset/image/firework.png";
import { ReactComponent as CloseIcon } from "../../../asset/svg/close-circle.svg";
import "./index.scss";
import Button from "../../../components/button/Button";
import { useTranslation } from "react-i18next";

type completeQuestModalProp = {
  closeModal: Function;
};

function CompleteQuestModal({ closeModal }: completeQuestModalProp) {
  const { t } = useTranslation();

  return (
    <Modal closeModal={closeModal}>
      <div className="modal-wrapper complete-quest-modal">
        <CloseIcon
          className="close-modal"
          onClick={() => {
            closeModal();
          }}
        />
        <img src={fireworkImg} />
        <h2 className="eng">
          Congratulations on
          <br />
          completing the Quest!
        </h2>
        <h4 className="modal-text">{t("wallet:modal:content")}</h4>
        <Button
          theme="green"
          text={t("wallet:buttons:modal")}
          onClick={() => {
            closeModal();
          }}
        />
      </div>
    </Modal>
  );
}
export default CompleteQuestModal;
