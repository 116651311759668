import { useTranslation } from "react-i18next";
import { ReactComponent as ArrowRight } from "../../asset/svg/arrow-right2.svg";
import { useEffect, useRef, useState } from "react";
import { Language } from "LanguageType";
import { LanguageList } from "../../utils/data/language";
import { useOutsideClose } from "../../hook/useOutsideClose";
import UserApi from "../../apis/UserApi";
import { useLanguage } from "../../hook/useLanguage";

const LanguageSelector = () => {
  const { i18n } = useTranslation();
  const { classLang } = useLanguage();

  const userApi = new UserApi();

  const [curLang, setCurLang] = useState<Language>();
  const [open, setOpen] = useState(false);

  const getLang = () => {
    const newLang = LanguageList.find(
      (language) => language.langType === i18n.language
    );
    setCurLang(newLang);
  };

  const modalHandler = () => {
    setOpen((prev) => !prev);
  };

  const closeModal = () => {
    setOpen(false);
  };

  const selectLang = async (lang: string) => {
    const newLang = LanguageList.find((language) => language.langName === lang);
    try {
      await userApi.changeLanguage(newLang?.nation!);
      setCurLang(newLang);
      i18n.changeLanguage(newLang?.langType);
      closeModal();
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getLang();
  }, []);

  return (
    <span className="lang-select">
      <div className="lang-select-box" onClick={modalHandler}>
        <span className={classLang}>{curLang?.langName}</span>
        <ArrowRight className={`select-chevron ${open ? "open" : ""}`} />
      </div>
      {open ? (
        <SelectorModal closeModal={closeModal} selectLang={selectLang} />
      ) : (
        <></>
      )}
    </span>
  );
};

interface modalProps {
  closeModal: () => void;
  selectLang: (lang: string) => void;
}

const SelectorModal = ({ closeModal, selectLang }: modalProps) => {
  const ref = useRef<HTMLDivElement>(null);

  useOutsideClose(ref, closeModal);

  return (
    <div className="lang-select-modal" ref={ref}>
      {LanguageList.map((lang) => (
        <span onClick={() => selectLang(lang.langName)}>{lang.langName}</span>
      ))}
    </div>
  );
};

export default LanguageSelector;
