import Modal from "../../modal/Modal";
import mema from "../../../asset/wallets/metamask.svg";
import { wallets as aptosWallets } from "../../../AppContext";
import caret from "../../../asset/svg/arrow-right2.svg";
import { ReactComponent as Xmark } from "../../../asset/svg/close.svg";
import "./walletModal.scss";
import { useLanguage } from "../../../hook/useLanguage";
import { useExternalWallet } from "../../../hook/useExternalWallet";
import { useNavigate } from "react-router-dom";
import { WalletName } from "@aptos-labs/wallet-adapter-react";
import { useEffect } from "react";

interface Props {
  onClose: () => void;
}

const WalletModal = ({ onClose }: Props) => {
  const { classLang } = useLanguage();
  const { connectWallet, externalWalletInfo } = useExternalWallet();
  const navigation = useNavigate();

  const handleConnect = async (chain: "evm" | "aptos", wallet?: WalletName) => {
    try {
      await connectWallet(chain, wallet);
    } catch (err) {
      console.log(err);
    }
  };

  const evmWallets = [
    {
      title: "Metamask",
      logo: mema,
      handler: () => handleConnect("evm"),
    },
  ];

  useEffect(() => {
    if (externalWalletInfo.isConnected) navigation("/external");
  }, [externalWalletInfo.isConnected]);

  return (
    <Modal closeModal={onClose}>
      <section className="wallet-modal-container">
        <div className="modal-header">
          <h2 className={classLang}>Select Wallet</h2>
          <Xmark
            onClick={onClose}
            fill="#BDBDBD"
            style={{
              position: "absolute",
              top: "50%",
              right: "5%",
              transform: "translate(0, -50%)",
            }}
          />
        </div>
        <div className="modal-content-wrapper">
          <ul>
            <li className="chain-wrapper">
              <h4 className="eng chain-name">EVM</h4>
            </li>
            {evmWallets.map((item) => (
              <li onClick={item.handler} key={item.title}>
                <div className="chain-info-wrapper">
                  <span>
                    <img src={item.logo} alt={item.title} />
                  </span>
                  <h3 className="eng">{item.title}</h3>
                </div>
                <img src={caret} alt="caret" />
              </li>
            ))}
          </ul>
          <ul>
            <li className="chain-wrapper">
              <h4 className="eng chain-name">APTOS</h4>
            </li>
            {aptosWallets.map((item) => (
              <li onClick={() => handleConnect("aptos", item.name)}>
                <div className="chain-info-wrapper">
                  <span>
                    <img
                      src={item.icon}
                      alt={item.name}
                      className={item.name}
                    />
                  </span>
                  <h3 className="eng">{item.name}</h3>
                </div>
                <img src={caret} alt="caret" />
              </li>
            ))}
          </ul>
        </div>
      </section>
    </Modal>
  );
};

export default WalletModal;
