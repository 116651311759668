import { useTranslation } from "react-i18next";
import MenuList from "../../components/menuList/MenuList";
import MiniHeader from "../../components/miniHeader/MiniHeader";

function WalletPrivacy() {
  const { t } = useTranslation();

  const menuList = [
    {
      name: t("wallet:yours:privacy:password:title"),
      link: "/wallet/privacy/mnemonic",
    },
    {
      name: t("wallet:yours:privacy:passcode:title"),
      link: "/wallet/privacy/passcode",
    },
  ];

  return (
    <div style={{ minHeight: "70vh" }}>
      <MiniHeader title={t("wallet:yours:menus:2")} />
      <div className="show-content-smoothly">
        <MenuList menuList={menuList} />
      </div>
    </div>
  );
}
export default WalletPrivacy;
