import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useNft } from "../../../hook/useNft";
import { useNftAdmin } from "../../../hook/useNftAdmin";
import MiniHeader from "../../../components/miniHeader/MiniHeader";
import MessageBox from "../../../components/messageBox/MessageBox";
import { ReactComponent as PlusIcon } from "../../../asset/svg/plus.svg";
import "./index.scss";
import Button from "../../../components/button/Button";
import { useTranslation } from "react-i18next";
import { useLanguage } from "../../../hook/useLanguage";

function NftRewardSetting() {
  const { t } = useTranslation();
  const { classLang } = useLanguage();

  const navigation = useNavigate();
  const { nftId } = useParams();
  const { nftInfo } = useNft({ nftId: Number(nftId) });
  const { rewardList } = useNftAdmin({ nftId: Number(nftId) });
  const [showMessage, setShowMessage] = useState(true);

  return (
    <div className="nft-setting-reward">
      <MiniHeader title={t("nft:benefits:header")} />
      <h2 className={`title ${classLang}`}>
        {t("nft:benefits:empty:caption")}{" "}
        <span className="reward-length">{rewardList?.length}</span>
      </h2>
      <div className="nft-setting-reward-wrapper show-content-smoothly">
        {!!rewardList?.length ? (
          <>
            {rewardList.map((reward: any, idx: number) => (
              <div
                className="nft-reward-elem"
                key={idx}
                onClick={() => {
                  navigation(`${reward.id}`);
                }}
              >
                <h3 className={`nft-reward-name ${classLang}`}>
                  {reward.rewardName}
                </h3>
              </div>
            ))}
            <div className="reward-add-button-wrapper">
              <button
                className="reward-add-button"
                onClick={() => {
                  navigation("add");
                }}
              >
                <PlusIcon />
                <h5 className={classLang}>{t("nft:benefits:buttons:add")}</h5>
              </button>
            </div>
          </>
        ) : (
          <div className="reward-empty">
            <h3 className={`reward-empty-text ${classLang}`}>
              {t("nft:benefits:empty:content")}
            </h3>
            <button
              className="reward-add-button"
              onClick={() => {
                navigation("add");
              }}
            >
              <PlusIcon />
              <h5 className={classLang}>{t("nft:benefits:buttons:add")}</h5>
            </button>
          </div>
        )}
      </div>
      {!nftInfo?.isDeployed ? (
        // nft가 배포된 적이 없는 경우
        <div className="deploy-button-wrapper">
          {showMessage && (
            <div className="deploy-button-message">
              <MessageBox
                message={t("nft:benefits:empty:popup")}
                closeMessage={() => setShowMessage(false)}
              />
            </div>
          )}
          <Button
            theme="purple"
            onClick={() => {
              navigation(`/nft/${nftId}/setting/deploy`);
            }}
            text={t("nft:benefits:buttons:next")}
          />
        </div>
      ) : (
        // nft가 배포된 적이 있는 경우 - reward 수정
        <div className="deploy-button-wrapper">
          {showMessage && (
            <div className="deploy-button-message">
              <MessageBox
                message={t("nft:benefits:empty:popup")}
                closeMessage={() => setShowMessage(false)}
              />
            </div>
          )}
          <Button
            theme="purple"
            onClick={() => {
              navigation(`/nft/${nftId}/setting/deploy/reward`);
            }}
            disabled={!nftInfo?.isEdited}
            text={t("nft:benefits:buttons:apply")}
          />
        </div>
      )}
    </div>
  );
}
export default NftRewardSetting;
