import { ComponentProps } from "react";
import success from "../../../asset/image/yrp/success.png";
import failure from "../../../asset/image/yrp/failure.png";
import purchase from "../../../asset/image/yrp/purchase.png";
import { useTranslation } from "react-i18next";
import { ReactComponent as Caret } from "../../../asset/svg/caret-right.svg";
import { ReactComponent as Plane } from "../../../asset/svg/fail-plane.svg";
import "./historyItem.scss";
import { useLanguage } from "../../../hook/useLanguage";
import HistoryModal from "../HistoryModal";
import { useTransition } from "../../../hook/useTransition";
import { YrpHistoryInterface, YrpType } from "YrpResponseType";
import {
  formatDateToUST,
  formatLocaleString,
} from "../../../utils/function/time";

interface Props extends ComponentProps<"div"> {
  item: YrpHistoryInterface;
}

const HistoryItem = ({ item, ...props }: Props) => {
  const { t } = useTranslation();
  const { classLang } = useLanguage();
  const { isOpen, startTransition, handleOpen, transition } =
    useTransition(500);

  const getItemIcon = (item: YrpHistoryInterface) => {
    if (!item.isCompleted) return failure;
    if (item.type === "BUY") return purchase;
    return success;
  };

  const getTitle = (type: YrpType) => {
    switch (type) {
      case "INPUT":
        return t("yrp:history:buttons:charge");
      case "OUTPUT":
        return t("yrp:history:buttons:exchange");
      case "BUY":
        return t("yrp:history:buttons:buy");
      case "SELL":
        return t("yrp:history:buttons:sell");
    }
  };

  return (
    <>
      <div
        className={`history-item-wrapper ${!item.isCompleted && "failure"}`}
        onClick={handleOpen}
        {...props}
      >
        <img src={getItemIcon(item)} alt="icon" className="icon" />
        <div className="content-line">
          <span>
            <h3 className={classLang}>{getTitle(item.type)}</h3>
            {!item.isCompleted && <Plane />}
          </span>
          <h6 className="timestamp">{formatLocaleString(item.chargedAt)}</h6>
        </div>
        <div className="amount-wrapper">
          <h2 className="eng">
            {(item.type === "INPUT" || item.type === "SELL") && "+"}
          </h2>
          <h2 className="eng">
            {item.isCompleted && item.yrpAmount
              ? item.yrpAmount.toLocaleString()
              : 0}
          </h2>
          <h2 className="eng" style={{ marginRight: 5 }}>
            YRP
          </h2>
          <Caret />
        </div>
      </div>
      {isOpen && (
        <HistoryModal
          item={item}
          isOpen={isOpen}
          startTransition={startTransition}
          transition={transition}
        />
      )}
    </>
  );
};

export default HistoryItem;
