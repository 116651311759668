import { useCreateIntegratedNft } from "../../hook/useCreateIntegratedNft";
import ContentsBox from "../../components/contentsBox/ContentsBox";
import AvailableNft from "../../components/integratedNft/AvailableNft";
import IncludedNft from "../../components/integratedNft/IncludedNft";
import MiniHeader from "../../components/miniHeader/MiniHeader";
import Select from "../../components/select/Select";
import chainList from "../../utils/data/chainList";
import Button from "../../components/button/Button";
import { useTranslation } from "react-i18next";
import { useLanguage } from "../../hook/useLanguage";

type adminMenuProp = {
  integratedNftInfo: any;
  updateIntegratedNft: Function;
  deleteIntegratedNft: Function;
};

function AdminMenu({
  integratedNftInfo,
  updateIntegratedNft,
  deleteIntegratedNft,
}: adminMenuProp) {
  const { t } = useTranslation();
  const { classLang } = useLanguage();

  const { availableNftList, checkedNftList, addCheckedNft, removeCheckedNft } =
    useCreateIntegratedNft({ chain: integratedNftInfo?.chainType });

  return (
    <>
      <MiniHeader title="Setting" />
      {integratedNftInfo && (
        <div className="flex-column-20">
          <ContentsBox
            title={t("intergrated:create:menu:1")}
            disabled={true}
            initialOpened={true}
          >
            <Select
              value={integratedNftInfo.chainType}
              setValue={() => {}}
              optionValueKey={"name"}
              optionIconKey={"logo"}
              optionList={chainList}
              disabled={true}
            />
          </ContentsBox>
          <ContentsBox
            title={t("intergrated:create:menu:2")}
            disabled={true}
            initialOpened={true}
          >
            <div className="included-nft-container flex-column-10">
              {integratedNftInfo.nftArray.map((nft: any, idx: number) => (
                <IncludedNft nftInfo={nft} disabled={true} key={idx} />
              ))}
            </div>
          </ContentsBox>
          <ContentsBox
            title={t("intergrated:update:title")}
            disabled={false}
            initialOpened={!!availableNftList?.length}
          >
            <>
              <h5 className={`nft-description ${classLang}`}>
                {t("intergrated:create:caption")}
              </h5>
              <div
                className="available-nft-container flex-row-18"
                style={{ flexWrap: "wrap" }}
              >
                {availableNftList.map((nft: any, idx: number) => (
                  <AvailableNft
                    nftInfo={nft}
                    checked={checkedNftList.includes(nft.id)}
                    checkAction={() => addCheckedNft(nft.id)}
                    uncheckAction={() => removeCheckedNft(nft.id)}
                  />
                ))}
              </div>
              <Button
                theme="purple"
                disabled={!checkedNftList.length}
                onClick={() => {
                  updateIntegratedNft(checkedNftList);
                }}
                text={t("intergrated:update:button")}
              />
            </>
          </ContentsBox>

          <ContentsBox
            title={t("intergrated:delete:title")}
            disabled={false}
            initialOpened={false}
          >
            <>
              <h5 className={`nft-description ${classLang}`}>
                {t("intergrated:delete:content")}
              </h5>
              <Button
                theme="purple"
                disabled={false}
                onClick={deleteIntegratedNft}
                text={t("intergrated:delete:button")}
              />
            </>
          </ContentsBox>
        </div>
      )}
    </>
  );
}
export default AdminMenu;
