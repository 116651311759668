import { useState } from "react";
import { fileToUrlAndFormData } from "../../../utils/function/imgInputHandler";
import { ReactComponent as PhotoIcon } from "../../../asset/svg/photo.svg";
import Button from "../../../components/button/Button";
import { useTranslation } from "react-i18next";
import { useLanguage } from "../../../hook/useLanguage";

type photoUploadProp = {
  photoText: string;
  submitUserAuthenticationPhoto: Function;
};

function PhotoUpload({
  photoText,
  submitUserAuthenticationPhoto,
}: photoUploadProp) {
  const { t } = useTranslation();
  const { classLang } = useLanguage();

  const [userPhotoUrl, setUserPhotoUrl] = useState("");
  const [userPhotoFormData, setUserPhotoFormData] = useState(new FormData());

  return (
    <form
      className="get-badge-content"
      onSubmit={(e) => {
        submitUserAuthenticationPhoto(e, userPhotoFormData);
      }}
    >
      <div className="title-wrapper">
        <h3 className={`title--gray ${classLang}`}>
          {t("nft:auth:image:caption")}
        </h3>
        <h3 className={`title-option ${classLang}`}>{photoText}</h3>
      </div>
      <div className={`warning-text ${classLang}`}>
        {t("nft:auth:image:warning")}
      </div>
      <input
        type="file"
        accept="image/*"
        id="nft-user-photo-input"
        onChange={(e) => {
          fileToUrlAndFormData(
            e,
            setUserPhotoUrl,
            setUserPhotoFormData,
            "image"
          );
        }}
      />
      {userPhotoUrl ? (
        <>
          <img className="nft-user-photo" src={userPhotoUrl} />
          <div className="button-wrapper">
            <label htmlFor="nft-user-photo-input" className="button" id="black">
              {t("nft:auth:buttons:retake")}
            </label>
            <Button
              theme="purple"
              text={t("nft:auth:buttons:submit")}
              type="submit"
            />
          </div>
        </>
      ) : (
        <label htmlFor="nft-user-photo-input" className="before-upload">
          <PhotoIcon />
          <div>Photo Upload</div>
        </label>
      )}
    </form>
  );
}
export default PhotoUpload;
