import { useEffect, useState } from "react";
import { timeToString } from "../../utils/function/time";
import usePhoneAuthentication from "../../hook/usePhoneAuthentication";
import { ReactComponent as CheckIcon } from "../../asset/svg/check-circle.svg";
import { ReactComponent as ArrowRight } from "../../asset/svg/arrow-right.svg";
import Button from "../../components/button/Button";
import { useTranslation } from "react-i18next";
import { useLanguage } from "../../hook/useLanguage";

type inputFormProps = {
  setPhoneNumber: Function;
  nickname: string;
  setNickname: Function;
  agreeToTermList: String[];
  setAgreeToTermList: Function;
  goToNextPage: Function;
};

function InputForm({
  setPhoneNumber,
  nickname,
  setNickname,
  agreeToTermList,
  setAgreeToTermList,
  goToNextPage,
}: inputFormProps) {
  const { t, i18n } = useTranslation();
  const { classLang } = useLanguage();

  const {
    phoneNumber,
    getPhoneNumber,
    sendVerificationNumber,
    isVerificationNumberSent,
    checkVerifyNumber,
    authentic,
    validationTime,
    nationalCode,
    changeNationalCode,
  } = usePhoneAuthentication();
  const [userVerifyNumber, setUserVerifyNumber] = useState("");

  const termsOfUseList = [
    { id: "AGE", required: true, text: t("login:first:agreeCap2"), link: null },
    {
      id: "SERVICE",
      required: true,
      text: t("login:first:agreeCap3"),
      link: "https://blockwavelabs.notion.site/Yours-14cdd37b08e6496184d0d463f1af6746",
    },
    {
      id: "PRIVACY",
      required: true,
      text: t("login:first:agreeCap4"),
      link: "https://blockwavelabs.notion.site/Yours-a10e4ec9568c4e19b178306158370623",
    },
    {
      id: "MARKETING",
      required: false,
      text: t("login:first:agreeCap5"),
      link: "https://blockwavelabs.notion.site/Yours-a10e4ec9568c4e19b178306158370623",
    },
  ];

  const agreeToTermHandler = (e: any, termId: String) => {
    if (e.target.checked) {
      setAgreeToTermList([...agreeToTermList, termId]);
    } else {
      setAgreeToTermList(agreeToTermList.filter((term) => term !== termId));
    }
  };

  const agreeToTermAllHandler = () => {
    if (agreeToTermList.length === termsOfUseList.length) {
      setAgreeToTermList([]);
    } else {
      setAgreeToTermList(termsOfUseList.map((term) => term.id));
    }
  };

  const checkFormValidation = (): boolean => {
    for (let term of termsOfUseList) {
      if (term.required) {
        if (!agreeToTermList.includes(term.id)) {
          return false;
        }
      }
    }
    return !!(nickname && authentic);
  };

  useEffect(() => {
    setPhoneNumber(phoneNumber);
  }, [phoneNumber]);

  return (
    <div className="signup-input-wrapper">
      <div className="input-box">
        <label className={`input-label input-label-${classLang}`}>
          {t("login:first:nickname")}
        </label>
        <input
          className="input-text"
          value={nickname}
          onChange={(e) => setNickname(e.target.value)}
        />
      </div>
      <div className="input-box">
        <label className={`input-label input-label-${classLang}`}>
          {t("login:first:number")}
        </label>
        <div className="input-phone-number-wrapper">
          <input
            className="input-national-code"
            value={`+${nationalCode}`}
            onChange={changeNationalCode}
            maxLength={5}
          />
          <input
            className="input-text"
            onInput={(e) => getPhoneNumber(e.target as HTMLInputElement)}
            maxLength={13}
          />
          {!isVerificationNumberSent ? (
            <Button
              theme="purple"
              disabled={
                i18n.language === "ko"
                  ? phoneNumber.length !== 13
                    ? true
                    : false
                  : false
              }
              onClick={() => {
                sendVerificationNumber();
              }}
              text={t("login:first:numberAsk1")}
            />
          ) : (
            <button
              className="button"
              id="black"
              onClick={() => {
                sendVerificationNumber();
              }}
            >
              {t("login:first:numberAsk2")}
              {!authentic && <div>{timeToString(validationTime)}</div>}
            </button>
          )}
        </div>
        {isVerificationNumberSent && (
          <div className="phone-verfication-wrapper">
            <input
              type="number"
              placeholder={t("login:first:numberPlaceholder")}
              onChange={(e) => {
                setUserVerifyNumber(e.target.value);
                checkVerifyNumber(e.target.value);
              }}
            />
            {!!userVerifyNumber.length &&
              (authentic ? (
                <span className="verify-status" id="success">
                  {t("login:first:numberAuthResultPositive")}
                </span>
              ) : (
                <span className="verify-status" id="fail">
                  {t("login:first:numberAuthResultNegative")}
                </span>
              ))}
          </div>
        )}
      </div>
      <div className="input-box">
        <label className={`input-label input-label-${classLang}`}>
          {t("login:first:agree")}
        </label>
        <div className="term-of-use-wrapper">
          <div
            className="term-of-use"
            id="select-all"
            onClick={() => agreeToTermAllHandler()}
          >
            <CheckIcon
              className="check-icon"
              id={
                agreeToTermList.length === termsOfUseList.length
                  ? "checked"
                  : ""
              }
            />
            <span className={`term-of-use-text ${classLang}`}>
              {t("login:first:agreeCap1")}
            </span>
          </div>
          {termsOfUseList.map((term, idx) => (
            <>
              <input
                type="checkbox"
                id={"term-of-use-" + term.id}
                checked={agreeToTermList.includes(term.id)}
                onChange={(e) => agreeToTermHandler(e, term.id)}
              />
              <label
                className="term-of-use"
                key={idx}
                htmlFor={"term-of-use-" + term.id}
              >
                <CheckIcon className="check-icon" />
                <span className={`term-of-use-text ${classLang}`}>
                  {term.text}
                </span>
                {term.link && (
                  <a href={term.link} target="_blank">
                    <ArrowRight className="term-of-use-link" />
                  </a>
                )}
              </label>
            </>
          ))}
        </div>
      </div>

      <Button
        className="signup-input-submit-button"
        theme="purple"
        onClick={() => {
          goToNextPage();
        }}
        disabled={!checkFormValidation()}
        text={t("login:button:next")}
        type="submit"
      />
    </div>
  );
}
export default InputForm;
