import { ComponentProps, ReactNode } from "react";
import { ReactComponent as Plus } from "../../../asset/svg/floating-plus.svg";
import "./floating.scss";
import { useLanguage } from "../../../hook/useLanguage";

interface Props extends ComponentProps<"div"> {
  contents: {
    Icon: React.FC<React.SVGProps<SVGSVGElement>>;
    caption: string;
    isEng?: boolean;
    handler: () => void;
    objet?: ReactNode;
  }[];
  handleOpen: () => void;
  isOpen: boolean;
  startTransition: () => void;
  transition: boolean;
  rotate: boolean;
}

const FloatingButton = ({
  contents,
  handleOpen,
  isOpen,
  startTransition,
  transition,
  rotate,
  ...props
}: Props) => {
  const { classLang } = useLanguage();
  const handleButton = () => {
    if (isOpen) startTransition();
    else handleOpen();
  };

  const handleChildren = (callback: () => void) => {
    callback();
    startTransition();
  };

  return (
    <div className="floating-button-wrapper" {...props}>
      {isOpen &&
        contents.map((content) => (
          <div
            onClick={() => handleChildren(content.handler)}
            className={`floating-children-wrapper ${transition && "close"}`}
          >
            <span
              className={`floating-children-caption ${
                content.isEng && "eng"
              } ${classLang}`}
            >
              {content.caption}
            </span>
            <button className="floating-children-button">
              <content.Icon className="floating-children-icon" />
            </button>
            {content.objet}
          </div>
        ))}
      <button onClick={handleButton} className="plus-button">
        <Plus className={`plus-icon ${rotate && "open"}`} />
      </button>
    </div>
  );
};

export default FloatingButton;
