import { ExternalChainInfo } from "ChainType";
import { Signer } from "ethers";

export type ExternalWalletInfo = {
  chain: "evm" | "aptos";
  address: string;
  isConnected: boolean;
  signer?: Signer | null;
  innerChainInfo: ExternalChainInfo;
};

export const ACTION_TYPES = {
  SET_EXTERNAL_CHAIN: "SET_EXTERNAL_CHAIN",
  SET_EXTENRAL_ADDRESS: "SET_EXTENRAL_ADDRESS",
  SET_EXTERNAL_CONNECTED: "SET_EXTERNAL_CONNECTED",
  SET_EXTERNAL_SIGNER: "SET_EXTERNAL_SIGNER",
  SET_EXTERNAL_INNER_CHAIN_INFO: "SET_EXTERNAL_INNER_CHAIN_INFO",
};
