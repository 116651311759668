import { Signer } from "ethers";
import { ACTION_TYPES } from "./externalTypes";
import { ExternalChainInfo } from "ChainType";

export const setExternalChain = (chain: "evm" | "aptos") => {
  return { type: ACTION_TYPES.SET_EXTERNAL_CHAIN, data: chain };
};

export const setExternalAddress = (address: string) => {
  return { type: ACTION_TYPES.SET_EXTENRAL_ADDRESS, data: address };
};

export const setExternalConnected = (isConnected: boolean) => {
  return { type: ACTION_TYPES.SET_EXTERNAL_CONNECTED, data: isConnected };
};

export const setExternalSigner = (signer: Signer | null) => {
  return { type: ACTION_TYPES.SET_EXTERNAL_SIGNER, data: signer };
};

export const setExternalInnerChainInfo = (chain: ExternalChainInfo | null) => {
  return { type: ACTION_TYPES.SET_EXTERNAL_INNER_CHAIN_INFO, data: chain };
};
