import { NftInfo } from "NftType";
import { Transaction } from "TransactionType";
import { useNavigate } from "react-router-dom";
import { toLocaleTime } from "../../../utils/function/time";
import chainList from "../../../utils/data/chainList";
import Success from "../../../components/success/Success";
import { useTranslation } from "react-i18next";

interface DeoploySuccessProps {
  nftInfo: NftInfo;
  deployInfo: Transaction | undefined;
}

function DeoploySuccess({ nftInfo, deployInfo }: DeoploySuccessProps) {
  const { i18n, t } = useTranslation();

  const navigation = useNavigate();

  const caption = () => {
    switch (i18n.language) {
      case "ko":
        return (
          <>
            <b>{nftInfo.nftName}</b> NFT를
            <br /> <b>{nftInfo.chainType}</b> 체인에서
            <br /> 발행했어요
          </>
        );
      case "en":
        return (
          <>
            The <b>{nftInfo.nftName}</b> NFT
            <br /> is deployed on <b>{nftInfo.chainType}</b> chain
          </>
        );
    }
  };

  return (
    <Success
      image={nftInfo.image}
      title={caption()}
      buttonText={t("setting:congraturation:create")}
      buttonAction={() => {
        navigation(`/nft/${nftInfo.id}/detail`, { replace: true });
      }}
    >
      <div className="deploy-info-container flex-column-20">
        <h2 className="deploy-info-title eng">Info</h2>
        <div className="deploy-info-row">
          <h6 className="title gr-4">TX</h6>
          <h6
            className="content underline"
            onClick={() => {
              window.open(
                `${
                  chainList.find((el) => el.chainType === nftInfo.chainType)
                    ?.explorerUrl
                }/${deployInfo?.transactionHash}`
              );
            }}
          >
            View TX
          </h6>
        </div>
        <div className="deploy-info-row">
          <h6 className="title">Status</h6>
          <h6 className="content">Success</h6>
        </div>
        <div className="deploy-info-row">
          <h6 className="title">Date</h6>
          <h6 className="content">
            {toLocaleTime(deployInfo?.date as string)}
          </h6>
        </div>
      </div>
    </Success>
  );
}
export default DeoploySuccess;
