import { useMemo, useRef, useState } from "react";
import { useExternalWallet } from "../../../hook/useExternalWallet";
import { INNER_CHAINS } from "../../../redux/external/externalReducer";
import { ReactComponent as Caret } from "../../../asset/svg/caret-down.svg";
import { useTransition } from "../../../hook/useTransition";
import "./chainSelectBox.scss";
import { useOutsideClose } from "../../../hook/useOutsideClose";
import { ExternalChainInfo } from "ChainType";
import { DisabledPopup } from "../DisableModal";

const ChainSelectBox = () => {
  const ref = useRef<HTMLDivElement>(null);
  const {
    externalWalletInfo,
    handleSelectInnerChain,
    switchToDesiredNetwork,
    isDisabled,
  } = useExternalWallet();
  const { isOpen, handleOpen, rotate, startTransition, transition } =
    useTransition(300);
  const [disabledModalOpen, setDisableModalOpen] = useState(
    isDisabled(externalWalletInfo.innerChainInfo.name)
  );

  const CHAIN_LIST = useMemo(() => {
    return Object.values(INNER_CHAINS[externalWalletInfo.chain]);
  }, [externalWalletInfo.chain]);

  useOutsideClose(ref, startTransition);

  const handleSelect = async (chain: ExternalChainInfo) => {
    try {
      if (externalWalletInfo.chain === "evm")
        await switchToDesiredNetwork(chain.key);
      handleSelectInnerChain(chain);
      startTransition();
      if (isDisabled(chain.name)) {
        setDisableModalOpen(true);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const closeModal = () => {
    setDisableModalOpen(false);
  };

  return (
    <div ref={ref} className="external-chain-select-container">
      <div
        onClick={() => (isOpen ? startTransition() : handleOpen())}
        className="selected-chain-box"
      >
        <div className="selected-chain-info">
          <img
            src={externalWalletInfo.innerChainInfo.icon}
            alt="chain"
            className="chain-icon"
          />
          <h4 className="eng">{externalWalletInfo.innerChainInfo.name}</h4>
        </div>
        <Caret
          className="chain-select-caret"
          style={{ transform: rotate ? `rotate(-180deg)` : `rotate(0deg)` }}
        />
      </div>
      {isOpen && (
        <div className={`chain-select-list ${transition && "close"}`}>
          {CHAIN_LIST.map((chain) => (
            <div
              onClick={() => handleSelect(chain)}
              key={chain.name}
              className="chain-line"
            >
              <img src={chain.icon} alt="chain" className="chain-icon" />
              <h4 className="eng">{chain.name}</h4>
            </div>
          ))}
        </div>
      )}
      {disabledModalOpen && (
        <DisabledPopup
          chainType={externalWalletInfo.innerChainInfo.name}
          closeModal={closeModal}
        />
      )}
    </div>
  );
};

export default ChainSelectBox;
