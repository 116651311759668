import { useTranslation } from "react-i18next";
import Button from "../../../components/button/Button";
import "./RewardInfoForm.scss";
import { useLanguage } from "../../../hook/useLanguage";
import { Category } from "NftType";
import { ReactComponent as NotificationIcon } from "../../../asset/svg/noti-line.svg";
import BenefitCategory from "./BenefitCategory";
import { useState } from "react";
import Bubble from "./Bubble";
import { useReward } from "../../../hook/useReward";

type rewradInfoFormProp = {
  name: string;
  description: string;
  category: string | null;
  link: string;
  buttonText: string;
  submitAction: Function;
  handleInfoInput: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  selectCategory: (category: Category) => void;
};

function RewardInfoForm({
  name,
  description,
  category,
  link,
  buttonText,
  submitAction,
  handleInfoInput,
  selectCategory,
}: rewradInfoFormProp) {
  const { t } = useTranslation();
  const { classLang } = useLanguage();
  const { BENEFITS } = useReward();

  const [isOpen, setIsOpen] = useState(false);

  const nameMaxLength = 35;
  const descriptionMaxLength = 200;

  return (
    <form
      className="reward-info-form"
      autoComplete="off"
      onSubmit={(e) => {
        e.preventDefault();
        submitAction();
      }}
    >
      <div className="reward-info-content-wrapper">
        {/* Benefit Name */}
        <div className="input-box-wrapper">
          <label
            className={`input-label input-label-${classLang}`}
            htmlFor="reward-name"
          >
            {t("nft:benefits:add:nameCaption")}
          </label>
          <input
            className="input-textbox"
            type="text"
            id="reward-name"
            value={name}
            placeholder={t("nft:benefits:add:namePlaceholder")}
            maxLength={nameMaxLength}
            name="name"
            onChange={handleInfoInput}
          />
          <div className="input-content-length">
            <span
              id={
                name.length
                  ? name.length >= nameMaxLength
                    ? "max"
                    : "active"
                  : ""
              }
            >
              {name.length}
            </span>
            /{nameMaxLength}
          </div>
        </div>

        {/* Benefit Description */}
        <div className="input-box-wrapper">
          <label
            className={`input-label input-label-${classLang}`}
            htmlFor="reward-description"
          >
            {t("nft:benefits:add:descCaption")}
          </label>
          <div className="input-textarea">
            <textarea
              rows={4}
              id="reward-description"
              value={description}
              placeholder={t("nft:benefits:add:descPlaceholder")}
              maxLength={descriptionMaxLength}
              name="desc"
              onChange={handleInfoInput}
            />
          </div>
          <div className="input-content-length">
            <span
              id={
                description.length
                  ? description.length >= descriptionMaxLength
                    ? "max"
                    : "active"
                  : ""
              }
            >
              {description.length}
            </span>
            /{descriptionMaxLength}
          </div>
        </div>

        {/* Benefit Type */}
        <div className="input-box-wrapper">
          <label
            className={`input-label input-label-${classLang}`}
            htmlFor="reward-description"
          >
            {t("nft:benefits:add:gating:type")}
          </label>
          <h6 className={`${classLang} input-caption`}>
            {t("nft:benefits:add:gating:caption")}
          </h6>
          <div className="benefit-type-container">
            {BENEFITS.map((item) => (
              <BenefitCategory
                {...item}
                checked={category === item.type}
                onClick={() => selectCategory(item.type)}
                key={item.type}
              />
            ))}
          </div>
        </div>

        {/* Benefit Link */}
        <div
          className="input-box-wrapper"
          style={{ marginTop: 16, marginBottom: 60 }}
        >
          <label
            className={`input-label input-label-${classLang} ${
              !category && "disabled"
            }`}
            htmlFor="reward-description"
          >
            <span>{t("nft:benefits:add:gating:link")}</span>
            <NotificationIcon onClick={() => setIsOpen(true)} />
            {isOpen && <Bubble handleClose={() => setIsOpen(false)} />}
          </label>
          <input
            className="input-textbox"
            type="text"
            id="reward-name"
            value={link}
            disabled={!category}
            placeholder="ex) https://t.me/+FXBmchiZ4ShlZjU9"
            name="link"
            onChange={handleInfoInput}
          />
        </div>
      </div>

      <Button
        theme="purple"
        type="submit"
        disabled={!(name.length && description.length)}
        text={buttonText}
        sticky={true}
      />
    </form>
  );
}
export default RewardInfoForm;
