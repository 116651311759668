import { useTranslation } from "react-i18next";
import Button from "../button/Button";
import PassCodeInputBox from "./PassCodeInputBox";
import { useLanguage } from "../../hook/useLanguage";

type passCodeType = {
  title: string;
  description: string;
  passCode: string;
  setPassCode: Function;
  isValid: boolean;
  buttonText: string;
  buttonAction: Function;
};

function PassCodeInput({
  title,
  description,
  passCode,
  setPassCode,
  isValid,
  buttonText,
  buttonAction,
}: passCodeType) {
  const { t } = useTranslation();
  const { classLang } = useLanguage();

  const passCodeCheckList = [
    {
      check: () => {
        return passCode.length >= 8;
      },
      text: t("login:second:check4"),
    },
    {
      check: () => {
        return /[\{\}\[\]\/?.,;:|\)*~`!^\-_+<>@\#$%&\\\=\(\'\"]/.test(passCode);
      },
      text: t("login:second:check2"),
    },
    {
      check: () => {
        return /[0-9]/.test(passCode);
      },
      text: t("login:second:check3"),
    },
    {
      check: () => {
        return /[a-z]/.test(passCode) && /[A-Z]/.test(passCode);
      },
      text: t("login:second:check1"),
    },
  ];

  return (
    <form
      className="passcode-input flex-column-31"
      onSubmit={(e) => {
        e.preventDefault();
        buttonAction();
      }}
    >
      <div
        className="flex-column-22"
        style={{ width: "-webkit-fill-available" }}
      >
        <h3 className={classLang}>{title}</h3>
        <h5
          className={`passcode-input-description ${classLang}`}
          style={{ lineHeight: "160%" }}
        >
          {description}
        </h5>

        <PassCodeInputBox passCode={passCode} setPassCode={setPassCode} />
        <div className="passcode-check-wrapper flex-column-left-14">
          {passCodeCheckList.map((check, idx) => (
            <div
              className="passcode-check-elem"
              id={!!check.check() ? "checked" : "unchecked"}
              key={idx}
            >
              <div className="passcode-check-marker" />
              <h6 className={classLang}>{check.text}</h6>
            </div>
          ))}
        </div>
      </div>

      {!!(buttonText && buttonAction) && (
        <Button
          theme="purple"
          text={t("login:button:next")}
          type="submit"
          disabled={!isValid}
          sticky={true}
          onClick={buttonAction}
        />
      )}
    </form>
  );
}
export default PassCodeInput;
