import { ComponentProps } from "react";
import "./loading.scss";

const Loading = (props: ComponentProps<"svg">) => {
  return (
    <svg className="spinner" viewBox="0 0 50 50" {...props}>
      <circle
        className="path"
        cx="25"
        cy="25"
        r="20"
        fill="none"
        stroke-width="5"
      ></circle>
    </svg>
  );
};

export default Loading;
