import { Icon } from "@iconify/react";
import { useTranslation } from "react-i18next";
import { useLanguage } from "../../../hook/useLanguage";
import "../external.scss";
import { useEffect } from "react";
import { useExternalWallet } from "../../../hook/useExternalWallet";
import { ExternalWalletNftRequest } from "UserResquestType";
import { setShowAlertInfo } from "../../../utils/function/showAlert";

interface Props {
  selectedItem: ExternalWalletNftRequest | null;
  handleNextStage: () => void;
  resetStage: () => void;
}

const LoadingSection = ({
  selectedItem,
  handleNextStage,
  resetStage,
}: Props) => {
  const { t } = useTranslation();
  const { classLang } = useLanguage();
  const { handleNftToYours, onSignAndSubmitTransactionAptos } =
    useExternalWallet();

  const transfering = async () => {
    if (selectedItem) {
      switch (selectedItem.chainType) {
        case "Ethereum":
          try {
            await handleNftToYours(selectedItem);
            handleNextStage();
          } catch (err) {
            console.log(err);
            setShowAlertInfo(t("external:transfer:complete:error"), false);
            resetStage();
          }
          break;
        case "Aptos":
          try {
            const { response, network } = await onSignAndSubmitTransactionAptos(
              selectedItem
            );
            console.log(response, network);
            handleNextStage();
          } catch (err) {
            console.log(err);
            setShowAlertInfo(t("external:transfer:complete:error"), false);
            resetStage();
          }
          break;
      }
    }
  };

  useEffect(() => {
    transfering();
  }, []);

  return (
    <div className="transfer-status-container">
      <Icon icon="line-md:loading-alt-loop" width="60" color="#ed5f8a" />
      <div className="transfer-status-wrapper">
        <h2 className={classLang}>
          {selectedItem?.nftName} {t("external:transfer:loading:1")}
        </h2>
        <h3 className={classLang} style={{ fontWeight: 400 }}>
          {t("external:transfer:loading:2")}
        </h3>
      </div>
      <h6 className={`transfer-warning ${classLang}`}>
        {t("external:transfer:loading:3")}
      </h6>
    </div>
  );
};

export default LoadingSection;
