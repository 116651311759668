import { useTranslation } from "react-i18next";
import Button from "../../components/button/Button";
import PassCodeInputWarning from "../../components/passCodeInputWarning/PassCodeInputWarning";

type passCodeInputProps = {
  passCode: string;
  setPassCode: Function;
  validPassCode: boolean;
  submitAction: Function;
};

function PassCodeInput({
  passCode,
  setPassCode,
  validPassCode,
  submitAction,
}: passCodeInputProps) {
  const { t } = useTranslation();

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        submitAction();
      }}
    >
      <PassCodeInputWarning
        warningDesc={t("wallet:yours:key:subtitle")}
        passCode={passCode}
        setPassCode={setPassCode}
        validPassCode={validPassCode}
        warningText={t("wallet:yours:key:title")}
      />
      <Button
        theme="purple"
        disabled={!passCode.length}
        onClick={submitAction}
        text={t("wallet:yours:key:buttons:next")}
      />
    </form>
  );
}
export default PassCodeInput;
