import { useTranslation } from "react-i18next";
import CopyBox from "../../components/copyBox/CopyBox";

type CheckPrivateKeyProps = {
  privateKey: string;
};

function CheckPrivateKey({ privateKey }: CheckPrivateKeyProps) {
  const { t } = useTranslation();

  return (
    <div className="check-privatekey-page">
      <CopyBox
        text={privateKey}
        copyNotificationText={t("wallet:yours:toasts:private")}
      />
      <div className="privatekey-warning-title">
        {t("wallet:yours:key:title")}
      </div>
      <h6 className="privatekey-warning-text">
        {t("wallet:yours:key:subtitle")}
      </h6>
    </div>
  );
}
export default CheckPrivateKey;
