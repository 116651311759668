import { useNavigate, useParams } from "react-router-dom";
import Success from "../../../components/success/Success";
import WelcomeImage from "../../../asset/image/welcome.png";
import { useNft } from "../../../hook/useNft";
import NFTApi from "../../../apis/NftApi";
import { useTranslation } from "react-i18next";

function WelcomeSuccess() {
  const { t } = useTranslation();

  const nftApi = new NFTApi();

  const navigation = useNavigate();
  const { nftId } = useParams();
  const { nftInfo } = useNft({ nftId: Number(nftId) });

  const updateMyAssets = async () => {
    await nftApi.getUserOwnNftIdList();
    navigation(`/mypage`, { replace: true });
  };

  return (
    <Success
      image={WelcomeImage}
      title={
        <>
          <b>{nftInfo?.nftName}</b>
          <br />
          {t("minting:success:title")}
        </>
      }
      buttonText={t("minting:success:mypage")}
      buttonAction={updateMyAssets}
    >
      <></>
    </Success>
  );
}
export default WelcomeSuccess;
