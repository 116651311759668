import { useState } from "react";
import { timeToString } from "../../../utils/function/time";
import { setShowAlertInfo } from "../../../utils/function/showAlert";
import useEmailAuthentication from "../../../hook/useEmailAuthentication";
import Modal from "../../../components/modal/Modal";
import UserApi from "../../../apis/UserApi";
import { ReactComponent as CheckIcon } from "../../../asset/svg/check-circle.svg";
import { ReactComponent as CloseIcon } from "../../../asset/svg/close-circle.svg";
import { useTranslation } from "react-i18next";
import { useLanguage } from "../../../hook/useLanguage";

type emailEditModalProps = {
  closeModal: () => void;
};

function EmailEditModal({ closeModal }: emailEditModalProps) {
  const { t } = useTranslation();
  const { classLang } = useLanguage();

  const userApi = new UserApi();
  const {
    email,
    setEmail,
    sendVerificationNumber,
    isVerificationNumberSent,
    checkVerifyNumber,
    authentic,
    validationTime,
  } = useEmailAuthentication();
  const [userVerifyNumber, setUserVerifyNumber] = useState("");

  const sendEmail = async () => {
    await sendVerificationNumber();
    setTimeout(() => {
      document.getElementById("verifycode-input")?.focus();
    }, 100);
  };

  const changeEmail = async () => {
    try {
      await userApi.editUserEmail(email);
      closeModal();
      setShowAlertInfo(t("setting:profile:toast:success:mail"), true);
    } catch (err) {
      setShowAlertInfo(t("setting:profile:toast:fail:mail"), false);
    }
  };

  return (
    <Modal closeModal={closeModal}>
      <div className="modal-wrapper profile-edit-modal">
        <div className="modal-header">
          <div className={`modal-title ${classLang}`}>
            {t("setting:profile:popup:mail")}
          </div>
          <CloseIcon className="modal-close" onClick={closeModal} />
        </div>
        <div className="modal-content">
          <div className="input-textbox">
            <input
              type="email"
              placeholder={t("setting:profile:placeholder:mail")}
              value={email}
              autoFocus={true}
              onChange={(e) => {
                setEmail(e.currentTarget.value);
              }}
            />
            <button
              disabled={!email.length}
              onClick={() => {
                sendEmail();
              }}
            >
              {!isVerificationNumberSent
                ? t("setting:profile:popup:verify")
                : t("setting:profile:popup:retake")}
            </button>
          </div>
          <div className="input-textbox">
            <input
              id="verifycode-input"
              type="text"
              onChange={(e) => {
                setUserVerifyNumber(e.currentTarget.value);
                checkVerifyNumber(e.currentTarget.value);
              }}
              placeholder={t("setting:profile:placeholder:verify")}
              disabled={!isVerificationNumberSent}
            />
            {isVerificationNumberSent &&
              (!!authentic ? (
                <CheckIcon className="check-icon" />
              ) : (
                <div className="valid-time">{timeToString(validationTime)}</div>
              ))}
            {!!userVerifyNumber.length &&
              isVerificationNumberSent &&
              !authentic && (
                <div className="invalid-message">
                  {t("setting:profile:popup:warning")}
                </div>
              )}
          </div>
        </div>
        <div className="modal-footer">
          <button
            className="cancle"
            onClick={() => {
              closeModal();
            }}
          >
            {t("setting:profile:popup:cancel")}
          </button>
          <button
            disabled={!authentic}
            className="approve"
            onClick={() => {
              changeEmail();
            }}
          >
            {t("setting:profile:popup:edit")}
          </button>
        </div>
      </div>
    </Modal>
  );
}
export default EmailEditModal;
