import { Dispatch, SetStateAction, useState } from "react";
import {
  ChainSelectBox,
  Formboard,
  NftList,
  WalletHeader,
} from "../../../components/ExternalWallet";
import Button from "../../../components/button/Button";
import MiniHeader from "../../../components/miniHeader/MiniHeader";
import "../external.scss";
import Buttons from "../../../components/buttons";
import { useTranslation } from "react-i18next";
import { ExternalWalletNftRequest } from "UserResquestType";
import { Popup } from "../../../components/popup";
import { useExternalWallet } from "../../../hook/useExternalWallet";
interface Props {
  selectedItem: null | ExternalWalletNftRequest;
  setSelectedItem: Dispatch<SetStateAction<null | ExternalWalletNftRequest>>;
  handleNextStage: () => void;
}

const ListSection = ({
  selectedItem,
  setSelectedItem,
  handleNextStage,
}: Props) => {
  const { t } = useTranslation();
  const { externalWalletInfo, isDisabled } = useExternalWallet();

  const [isOpen, setIsOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const handleButtonAbled = () => {
    setIsOpen(true);
  };

  const handleButtonDisabled = () => {
    setIsOpen(false);
  };

  const handleSelect = (item: ExternalWalletNftRequest) => {
    setSelectedItem(item);
  };

  const handleModalOpen = () => {
    setIsModalOpen(true);
  };

  const handleLength = (length: boolean) => {
    setButtonDisabled(length);
  };

  const buttonTexts = [
    t("external:transfer:cancel"),
    t("external:transfer:confirm"),
    t("external:transfer:disabled"),
  ];

  const modalConfig = {
    title: t("external:transfer:popup"),
    closeModal: () => setIsModalOpen(false),
    approve: handleNextStage,
    deny: () => setIsModalOpen(false),
    approveText: t("external:disconnect:confirm"),
    denyText: t("external:disconnect:deny"),
  };

  return (
    <div className="external-nft-container">
      <MiniHeader title="My NFTs" />
      <WalletHeader />
      <ChainSelectBox />
      {isDisabled(externalWalletInfo.innerChainInfo.name) && <Formboard />}
      <NftList
        isOpen={isOpen}
        selectedItem={selectedItem}
        handleSelect={handleSelect}
        handleButtonDisabled={handleLength}
      />
      {isOpen ? (
        <Buttons
          texts={buttonTexts}
          handleClose={handleButtonDisabled}
          handleConfirm={handleModalOpen}
          disabled={!!!selectedItem}
        />
      ) : (
        <Button
          text={
            isDisabled(externalWalletInfo.innerChainInfo.name)
              ? "This chain is being prepared."
              : "Transfer NFT to Yours"
          }
          sticky
          theme="purple"
          onClick={handleButtonAbled}
          disabled={
            isDisabled(externalWalletInfo.innerChainInfo.name) || buttonDisabled
          }
        />
      )}
      {isModalOpen && <Popup {...modalConfig} />}
    </div>
  );
};

export default ListSection;
