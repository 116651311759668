import { useEffect, useState } from "react";
import { useBackground } from "../../hook/useBackground";
import KakaoLoginButton from "../../components/loginButton/KakaoLoginButton";
import GoogleLoginButton from "../../components/loginButton/GoogleLoginButton";
import BlurryLoadingBackground from "../../components/blurryLoadingImage/BlurryLoadingBackground";
import logo from "../../asset/svg/icon.svg";
import background01 from "../../asset/image/landing/background01.png";
import background02 from "../../asset/image/landing/background02.png";
import background03 from "../../asset/image/landing/background03.png";
import background04 from "../../asset/image/landing/background04.png";
import smallBackground01 from "../../asset/image/landing/preview/background01.png";
import smallBackground02 from "../../asset/image/landing/preview/background02.png";
import smallBackground03 from "../../asset/image/landing/preview/background03.png";
import smallBackground04 from "../../asset/image/landing/preview/background04.png";
import block01 from "../../asset/image/landing/block01.png";
import block02 from "../../asset/image/landing/block02.png";
import block03 from "../../asset/image/landing/block03.png";
import block04 from "../../asset/image/landing/block04.png";
import "./index.scss";
import { useTranslation } from "react-i18next";
import { useLanguage } from "../../hook/useLanguage";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

function Landing() {
  const { t } = useTranslation();
  const { classLang } = useLanguage();
  const auth = useSelector((state: any) => state.userData.auth);
  const navigate = useNavigate();

  const [isOpen, setIsOpen] = useState(true);

  const blockImgList = [block01, block02, block03, block04];
  const pageInfoList = [
    {
      background: background01,
      smallBackground: smallBackground01,
      img: undefined,
      title: t("landing:first:title"),
      description: t("landing:first:subtitle"),
      lang: "kor",
    },
    {
      background: background02,
      smallBackground: smallBackground02,
      img: undefined,
      title: t("landing:second:title"),
      description: t("landing:second:subtitle"),
      lang: "kor",
    },
    {
      background: background03,
      smallBackground: smallBackground03,
      img: undefined,
      title: t("landing:third:title"),
      description: t("landing:third:subtitle"),
      lang: "kor",
    },
    {
      background: background04,
      smallBackground: smallBackground04,
      img: logo,
      title: "It's Sincerely\nYours",
      description: "Start your NFT experience now",
      lang: "eng",
    },
  ];
  const [currPage, setCurrPage] = useState(0);
  useBackground({ backgroundStyle: "LANDING" });

  const touchStartHandler = (e: any) => {
    const touch = e.touches[0];
    const startX = touch.clientX;
    const startY = touch.clientY;
    const landingPage = document.getElementById("landing-page");

    landingPage?.addEventListener("touchend", (e: any) => {
      const touch = e.changedTouches[0];
      const endX = touch.clientX;
      const endY = touch.clientY;
      const diffX = endX - startX;
      const diffY = endY - startY;
      if (Math.abs(diffX) > Math.abs(diffY)) {
        if (diffX > 0) {
          if (currPage === 0) {
            setCurrPage(0);
          } else {
            setCurrPage(currPage - 1);
          }
        } else {
          if (currPage === pageInfoList.length - 1) {
            setCurrPage(pageInfoList.length - 1);
          } else {
            setCurrPage(currPage + 1);
          }
        }
      }
    });
  };

  const mouseDownHandler = (e: any) => {
    const startX = e.clientX;
    const startY = e.clientY;
    const landingPage = document.getElementById("landing-page");

    landingPage?.addEventListener("mouseup", (e: any) => {
      const endX = e.clientX;
      const endY = e.clientY;
      const diffX = endX - startX;
      const diffY = endY - startY;
      if (Math.abs(diffX) > Math.abs(diffY)) {
        if (diffX > 0) {
          if (currPage === 0) {
            setCurrPage(0);
          } else {
            setCurrPage(currPage - 1);
          }
        } else {
          if (currPage === pageInfoList.length - 1) {
            setCurrPage(pageInfoList.length - 1);
          } else {
            setCurrPage(currPage + 1);
          }
        }
      }
    });
  };

  const keyDownHandler = (e: any) => {
    if (e.key === "ArrowRight") {
      if (currPage === pageInfoList.length - 1) {
        setCurrPage(0);
      } else {
        setCurrPage(currPage + 1);
      }
    } else if (e.key === "ArrowLeft") {
      if (currPage === 0) {
        setCurrPage(pageInfoList.length - 1);
      } else {
        setCurrPage(currPage - 1);
      }
    }
  };

  const handleCloseBubble = () => {
    setIsOpen(false);
  };

  const handleMoveLanding = () => {
    window.open("https://yours.framer.website/");
    handleCloseBubble();
  };

  const checkAuth = () => {
    if (auth) navigate("/mypage", { replace: true });
  };

  useEffect(() => {
    // page change event
    document.addEventListener("keydown", keyDownHandler);
    return () => document.removeEventListener("keydown", keyDownHandler);
  }, [currPage]);

  useEffect(() => {
    let navbar = document.getElementById("navbar");
    navbar?.classList.add("navbar--transparent");

    let appContent = document.getElementById("app-content");
    appContent?.classList.add("app-content--none");

    return () => {
      navbar?.classList.remove("navbar--transparent");
      appContent?.classList.remove("app-content--none");
    };
  }, []);

  useEffect(() => {
    checkAuth();
  }, [auth]);

  return (
    <div
      className="landing"
      id="landing-page"
      onTouchStart={(e: any) => {
        touchStartHandler(e);
      }}
      onMouseDown={(e: any) => {
        mouseDownHandler(e);
      }}
    >
      <div className="page-wrapper">
        {pageInfoList.map((page, idx) => (
          <div
            className="page"
            id={currPage === idx ? "curr-page" : ""}
            key={idx}
          />
        ))}
      </div>
      <BlurryLoadingBackground
        preview={pageInfoList[currPage].smallBackground}
        image={pageInfoList[currPage].background}
        backgroundStyleClass="landing-page-wrapper"
        backgroundStyleId={`landing-page--${currPage + 1}`}
      >
        <>
          {pageInfoList[currPage].img && (
            <img
              src={pageInfoList[currPage].img}
              className="landing-page-img"
            />
          )}
          <h1
            className={`${pageInfoList[currPage].lang} ${
              currPage !== 3 ? `landing-title-${classLang}` : ""
            }`}
          >
            {pageInfoList[currPage].title}
          </h1>
          <h4
            className={`${pageInfoList[currPage].lang} landing-content-${classLang}`}
          >
            {pageInfoList[currPage].description}
          </h4>
          <div className="block-wrapper">
            {blockImgList.map((block, idx) => (
              <img
                src={block}
                loading="lazy"
                className="block"
                id={`block0${idx + 1}`}
                key={idx}
              />
            ))}
          </div>
        </>
      </BlurryLoadingBackground>
      <div className="login-button-wrapper">
        <GoogleLoginButton />
        <KakaoLoginButton />
      </div>
    </div>
  );
}
export default Landing;
