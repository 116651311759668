import { useNavigate, useParams } from "react-router-dom";
import Success from "../../components/success/Success";
import { useNft } from "../../hook/useNft";
import { useTranslation } from "react-i18next";
import { useLanguage } from "../../hook/useLanguage";

function GetNftSuccess() {
  const { t } = useTranslation();
  const { classLang } = useLanguage();

  const navigation = useNavigate();
  const { nftId } = useParams();
  const { nftInfo } = useNft({ nftId: Number(nftId) });

  return (
    <Success
      image={nftInfo?.image}
      title={
        <>
          {classLang === "ko" ? "" : "The "}
          <b>{nftInfo?.nftName}</b>
          {classLang === "ko" ? "" : " NFT"}
          <br />
          {t("minting:success:title")}
        </>
      }
      buttonText={t("minting:success:mypage")}
      buttonAction={() => {
        navigation(`/mypage`);
      }}
    />
  );
}
export default GetNftSuccess;
