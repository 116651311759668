import { ComponentProps } from "react";
import { useLanguage } from "../../../hook/useLanguage";
import "./BenefitCategory.scss";

interface Props extends ComponentProps<"div"> {
  icon: string;
  title: string;
  checked: boolean;
}

const BenefitCategory = ({ icon, title, checked, ...props }: Props) => {
  const { classLang } = useLanguage();

  return (
    <div
      className={`category-item-wrapper ${!checked && "disabled"}`}
      {...props}
    >
      <img src={icon} alt="category_item" />
      <h4 className={classLang}>{title}</h4>
      <div className="border" />
    </div>
  );
};

export default BenefitCategory;
