import { AdminPhoto } from "AdminResponseType";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AdminApi from "../../apis/AdminApi";
import { addOwnNft } from "../../redux/nft/nftAction";

type useNftPhotoAdminProp = {
    nftId: number
}

export const useNftPhotoAdmin = ({ nftId }:useNftPhotoAdminProp) => {
    const dispatch = useDispatch();
    const myId = useSelector((state:any)=>state.userData.myId);
    const adminApi = new AdminApi();
    const [nftAdminPhotoList, setNftAdminPhotoList] = useState<AdminPhoto[]>([]);

    const approveApplication = async (applicationId:number) => {
        const { userId, nftId } = await adminApi.approveNftAdminPhoto(applicationId, true);
        setNftAdminPhotoList(nftAdminPhotoList.filter((el:any)=>el.id!==applicationId));

        if (myId === userId) dispatch(addOwnNft(nftId));
    }

    const discardApplication = async (applicationId:number, reason:string) => {
        await adminApi.approveNftAdminPhoto(applicationId, false, reason);
        setNftAdminPhotoList(nftAdminPhotoList.filter((el:any)=>el.id!==applicationId));
    }

    useEffect(()=>{
        const getAdminPhotoList = async () => {
            const data = await adminApi.getNftAdminPhotoList(nftId);
            setNftAdminPhotoList(data);
        }

        if (nftId) getAdminPhotoList();
    }, [nftId])

    return {nftAdminPhotoList, approveApplication, discardApplication};
}