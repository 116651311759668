import { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { fileToUrlAndFormData } from "../../utils/function/imgInputHandler";
import Select from "../../components/select/Select";
import chainList from "../../utils/data/chainList";
import { ReactComponent as Camera } from "../../asset/svg/camera.svg";
import Button from "../../components/button/Button";
import { useTranslation } from "react-i18next";
import { useLanguage } from "../../hook/useLanguage";

type setBadgeInfoProps = {
  nftName: string;
  setNftName: (nftName: string) => void;
  nftImgUrl: string;
  setNftImgUrl: (nftImgUrl: string) => void;
  setNftImgFormData: (nftImgFormData: FormData) => void;
  nftDescription: string;
  setNftDescription: (nftDescription: string) => void;
  nftChain: string;
  setNftChain: (nftChain: string) => void;
  next: () => void;
};

function SetBadgeInfo({
  nftName,
  setNftName,
  nftImgUrl,
  setNftImgUrl,
  setNftImgFormData,
  nftDescription,
  setNftDescription,
  nftChain,
  setNftChain,
  next,
}: setBadgeInfoProps) {
  const { t } = useTranslation();
  const { classLang } = useLanguage();

  const [searchParams, setSearchParams] = useSearchParams();
  const titleInfoList = [
    {
      page: 1,
      title: t("making:1:request"),
      description: t("making:1:description"),
    },
    {
      page: 2,
      title: t("making:2:request"),
      description: `${t("making:2:condition1")}\n${t("making:2:condition2")}`,
    },
    {
      page: 3,
      title: t("making:3:request"),
      description: t("making:3:description"),
    },
    {
      page: 4,
      title: t("making:4:request"),
      description: t("making:4:description"),
    },
  ];
  const [page, setPage] = useState(1);
  const [isValidInput, setIsValidInput] = useState(false);
  const nftDescriptionMaxLen = 200;

  useEffect(() => {
    let _pageMode = searchParams.get("pageMode");
    let _page = searchParams.get("page");

    if (_pageMode == "SET_BADGE_INFO") {
      if (
        _page &&
        !isNaN(Number(_page)) &&
        Number(_page) <= titleInfoList.length
      ) {
        setPage(Number(_page));
      } else {
        setSearchParams({ pageMode: "SET_BADGE_INFO", page: "1" });
      }
    }
  }, [searchParams]);

  const nextPageHandler = () => {
    if (page == titleInfoList.length) {
      next();
    } else {
      setSearchParams({ pageMode: "SET_BADGE_INFO", page: String(page + 1) });
    }
  };

  useEffect(() => {
    switch (page) {
      case 1:
        setIsValidInput(!!nftName.length);
        break;
      case 2:
        setIsValidInput(!!nftImgUrl.length);
        break;
      case 3:
        setIsValidInput(!!nftDescription.length);
        break;
      case 4:
        setIsValidInput(!!nftChain);
        break;
      default:
    }
  }, [page, nftName, nftImgUrl, nftDescription, nftChain]);

  return (
    <>
      <div className="title-wrapper">
        <h2 className={`title ${classLang}`}>
          {titleInfoList[page - 1].title} ({page}/{titleInfoList.length + 1})
        </h2>
        <h4 className={`subtitle ${classLang}`}>
          {titleInfoList[page - 1].description}
        </h4>
      </div>
      <div className="nft-create-info-form">
        {!!(page >= 4) && (
          <div className="chain-select-container">
            <div className="chain-select-wrapper">
              <Select
                placeholder={"Select Source Chain"}
                value={nftChain}
                setValue={setNftChain}
                optionList={chainList}
                optionIconKey="logo"
                optionNameKey="chainType"
                optionValueKey="chainType"
              />
            </div>
            <div className="nft-desc-wrapper">
              <span className={`nft-desc-title ${classLang}`}>
                {t("making:5:content3")}
              </span>
              <span className={`nft-desc-content ${classLang}`}>
                {nftDescription}
              </span>
            </div>
          </div>
        )}
        {!!(page === 3) && (
          <div className="input-box-wrapper" style={{ marginTop: -35 }}>
            <div className="input-textarea">
              <textarea
                id="badge-description-input"
                value={nftDescription}
                maxLength={nftDescriptionMaxLen}
                rows={4}
                placeholder={t("making:5:placeHolder")}
                onChange={(e) => {
                  setNftDescription(e.currentTarget.value);
                }}
              />
            </div>
            <div className="input-content-length">
              <span
                id={
                  nftDescription.length >= nftDescriptionMaxLen
                    ? "max"
                    : nftDescription.length
                    ? "active"
                    : ""
                }
              >
                {nftDescription.length}
              </span>
              /{nftDescriptionMaxLen}
            </div>
          </div>
        )}

        {!!(page >= 2) && (
          <div className="input-box-wrapper">
            {!!(page !== 2) && (
              <div className={`input-label input-label-${classLang}`}>
                {t("making:3:content1")}
              </div>
            )}
            <input
              id="badge-image-input"
              className="input-image"
              type="file"
              accept="image/*"
              disabled={page !== 2}
              onChange={(e) => {
                fileToUrlAndFormData(
                  e,
                  setNftImgUrl,
                  setNftImgFormData,
                  "image"
                );
              }}
            />
            <label htmlFor="badge-image-input" className="input-image">
              <div
                className="badge-image-input-button"
                id={nftImgUrl ? "image-uploaded" : ""}
              >
                <Camera />
              </div>
              {nftImgUrl && <img src={nftImgUrl} />}
            </label>
          </div>
        )}

        <div className="input-box-wrapper">
          {!!(page !== 1) && (
            <label
              className={`input-label input-label-${classLang}`}
              htmlFor="badge-name-input"
            >
              {t("making:1:innerBox")}
            </label>
          )}
          <input
            id="badge-name-input"
            className={`input-text input-text-${classLang}`}
            type="text"
            disabled={page !== 1}
            value={nftName}
            placeholder={t("making:1:placeHolder")}
            onChange={(e) => {
              setNftName(e.currentTarget.value);
            }}
          />
        </div>

        <div className="button-wrapper">
          {!!(page !== 1) && (
            <Button
              theme="black"
              onClick={() => {
                setSearchParams({
                  pageMode: "SET_BADGE_INFO",
                  page: String(page - 1),
                });
              }}
              text={t("making:2:bottom-button1")}
            />
          )}
          <Button
            theme="purple"
            onClick={() => {
              nextPageHandler();
            }}
            text={t("making:1:bottom-button1")}
            disabled={!isValidInput}
          />
        </div>
      </div>
    </>
  );
}
export default SetBadgeInfo;
