import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useNft } from "../../hook/useNft";
import { setShowAlertInfo } from "../../utils/function/showAlert";
import { chainType } from "ChainType";
import chainList from "../../utils/data/chainList";
import NFTApi from "../../apis/NftApi";
import MiniHeader from "../../components/miniHeader/MiniHeader";
import TransferInput from "./TrasferInput";
import TransferLoading from "./TransferLoading";
import TransferComplete from "./TransferComplete";
import "./index.scss";
import { useTranslation } from "react-i18next";

type transferStatusType = "INPUT" | "LOADING" | "COMPLETE";

function NftTransfer() {
  const { t } = useTranslation();

  const nftApi = new NFTApi();
  const { nftId } = useParams();
  const { nftInfo } = useNft({ nftId: Number(nftId) });
  const [transferStatus, setTransferStatus] =
    useState<transferStatusType>("INPUT");
  const [txId, setTxId] = useState<string | undefined>("");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [transferStatus]);

  /**
   * @description transferStatus에 따라 다른 컴포넌트를 렌더링합니다.
   * @returns JSX.Element
   */
  const renderTransferComponent = () => {
    switch (transferStatus) {
      case "INPUT":
        return (
          <TransferInput
            nftImage={nftInfo?.image}
            nftName={nftInfo?.nftName}
            nftChainType={nftInfo?.chainType as chainType}
            transferNft={transferNft}
          />
        );
      case "LOADING":
        return <TransferLoading nftName={nftInfo?.nftName} />;
      case "COMPLETE":
        return (
          <TransferComplete
            nftName={nftInfo?.nftName}
            transactionExplorerUrl={
              chainList.find((el) => el.chainType === nftInfo?.chainType)
                ?.explorerUrl
            }
            transactionId={txId}
          />
        );
    }
  };

  const transferNft = async (address: string) => {
    setTransferStatus("LOADING");
    try {
      const data = await nftApi.transferNft(Number(nftId), address);
      console.log(data);
      if (data.transactionHash) setTxId(data.transactionHash);
      setTransferStatus("COMPLETE");
    } catch (err) {
      console.log(err);
      setShowAlertInfo(t("nft:transfer:toast"), false);
      setTransferStatus("INPUT");
    }
  };

  return (
    <div className="nft-transfer">
      <MiniHeader title="Transfer" />
      {renderTransferComponent()}
    </div>
  );
}
export default NftTransfer;
